<script>
import HeadComponent from "@/components/Head"
import FootComponent from '@/components/Foot'
import store from '@/store'
import Swal from 'sweetalert2'
import $ from 'jquery'


export default {
    data() {
        return {
            country: null,
            imgproprieter: null,
            proprieterId: null,
            imgprofil: null,
            validuser: false,
            pub: null,
            profil: null,
            pays: null,
            prix: null,
            categorie: null,
            adresse: null,
            propriete: null,
            proprieters: [],
            userid: null,
            proprieterstop: null,
            userstop: null,
            articles: null,
            choice: null,
            categories: null,
            bannieres: null,
            v_piece: null,
            v_parcelle: null,
            v_hectare: null,
            v_superficie: null,
            nbr_hectare: null,
            nbr_piece: null,
            nbr_parcelle: null,
            superficie: null,
            localiter: '',
            max_prix: '',
            pub_lien: null,
            pub_id: null,
            cat_fil: null,
            currentPage: 1, // Page actuelle
            itemsPerPage: 6, // Nombre d'éléments par page

            suggestions: [],
        }
    },
    components: {
        HeadComponent,
        FootComponent
    },
    name: 'HomeComponent',
    computed: {
        totalPages() {
            return Math.ceil(this.proprieters.length / this.itemsPerPage);
        },
        paginatedProprieters() {
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;
            return this.proprieters.slice(startIndex, endIndex);
        },

        formattedMaxPrix: {
            get() {
                return this.formatMoney(this.max_prix);
            },
            set(value) {
                // Remove non-digit characters and parse as integer
                const newValue = parseInt(value.replace(/\D/g, ''), 10);
                if (!isNaN(newValue)) {
                    this.max_prix = newValue;
                }
            }
        }
    },
    methods: {
        formatMoney(value) {
            // Convert value to string and add commas for thousands separator
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        },
        formatInput(event) {
            // Get current input value
            let value = event.target.value;

            // Remove non-digit characters
            value = value.replace(/\D/g, '');

            // Format value
            value = this.formatMoney(value);

            // Update input value
            event.target.value = value;
        }
        ,
        searchPlaces() {
            /*  if (this.localiter.length > 2) {
              const apiKey = "8lRfqBA4TB2mtX2z28TjNIRHlJfj77dQCx-R0LmU91Q"; // Remplacez par votre clé API Here
               

                const url = `https://geocode.search.hereapi.com/v1/geocode?q=${this.localiter}&limit=4&apiKey=${apiKey}`;
                
                fetch(url)
                    .then(response => response.json())
                    .then(data => {
                        this.suggestions = data.items.map(item => ({
                            id: item.id,
                            title: item.title
                        }));
                    })
                    .catch(error => console.error('Error:', error));
            } else {
                this.suggestions = [];
            }*/
        },
        selectSuggestion(suggestion) {
            this.localiter = suggestion.title;
            this.suggestions = [];
        }
        ,
        // Méthode pour changer de page
        setPage(pageNumber) {
            this.currentPage = pageNumber;
            setTimeout(() => {
                this.trieproprieter('filtre')
            }, 300);

        },
        hasard(fin) {
            return Math.floor(Math.random() * fin)
        },
        formatMonetaryValue(value) {
            // Convertit le nombre en chaîne de caractères
            let formattedValue = String(value);

            // Vérifie si la valeur est supérieure à 3 chiffres
            if (formattedValue.length > 3) {
                // Détermine le nombre de groupes de trois chiffres
                const groups = Math.floor((formattedValue.length - 1) / 3);

                // Initialise l'index pour l'insertion de l'espace de mille
                let index = formattedValue.length - 3;

                // Boucle pour insérer les espaces de mille
                for (let i = 0; i < groups; i++) {
                    formattedValue = formattedValue.slice(0, index) + ' ' + formattedValue.slice(index);
                    index -= 3;
                }
            }

            // Retourne la valeur formatée
            return formattedValue;
        },
        autre_champs() {
            this.v_piece = false
            this.v_parcelle = false
            this.v_hectare = false
            this.v_superficie = false
            this.categories.forEach(cat => {

                if (cat.id == this.cat_fil) {
                    if (cat.nbr_piece) {
                        this.v_piece = true
                    }

                    if (cat.nbr_hectare) {
                        this.v_hectare = true
                    }

                    if (cat.nbr_parcelle) {
                        this.v_parcelle = true
                    }
                    if (cat.superficie) {
                        this.v_superficie
                    }

                }

            });


        },
        trieproprieter(trie) {
            if (trie === 'all') {
                // Si trie est 'all', afficher tous les éléments en réinitialisant les filtres
                this.proprie()
                // Réinitialiser les valeurs des filtres
                this.nbr_piece = null;
                this.pays = null
                this.nbr_parcelle = null;
                this.superficie = null;
                this.cat_fil = null;
                this.localiter = '';
                this.max_prix = '';
            } else if (trie === 'filtre') {


                const formData = new FormData()

                if (this.nbr_parcelle != null) {

                    formData.append('nbr_parcelle', this.nbr_parcelle)


                }
                if (this.nbr_hectare != null) {

                    formData.append('nbr_hectare', this.nbr_hectare)

                }

                if (this.nbr_piece != null) {

                    formData.append('nbr_piece', this.nbr_piece)

                }
                if (this.superficie != null) {

                    formData.append('superficie', this.superficie)

                }

                if (this.cat_fil != null) {

                    formData.append('cat_fil', this.cat_fil)

                }

                if (this.localiter != '') {

                    formData.append('localiter', this.localiter)

                }
                if (this.pays != null) {

                    formData.append('pays', this.pays)

                }
                if (this.max_prix != '') {

                    formData.append('max_prix', this.max_prix)

                }
                store.dispatch('proprieter/proprieterfilter', formData)
                    .then(response => {
                        this.proprieters = response

                        window.location.href = '#vupro'

                    }).catch(error => {
                        console.log(error)
                        Swal.fire({
                            title: "Erreur",
                            text: "Aucun resultat trouver",
                            icon: "error",
                            cancelButtonColor: "#f46a6a",
                            confirmButtonColor: "#34c38f",
                            confirmButtonText: "OK",
                        })

                        document.getElementById('btn_dmd').click()
                    })

            }
        }
        ,


        formatDate(dateString) {
            const options = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false,
                timeZone: 'UTC',
            };
            const date = new Date(dateString);
            return date.toLocaleDateString('en-US', options);
        },
        proprie() {
            store.dispatch('proprieter/proprie')
                .then(response => {
                    this.proprieters = response
                    this.choice = Math.floor(Math.random() * response.length)
                    this.imgproprieter = response[this.choice].image[this.hasard(response[this.choice].image.length)].image
                    this.imgprofil = response[this.choice].user.photo
                    if (response[this.choice].user.entreprise == null) {
                        this.profil = response[this.choice].user.first_name + ' ' + response[this.choice].user.last_name

                        console.log(response[this.choice].user)
                    }
                    else {
                        this.profil = response[this.choice].user.entreprise

                    }
                    this.userid = response[this.choice].user.id
                    this.propriete = response[this.choice].addresse
                    this.proprieterId = response[this.choice].id
                    this.prix = response[this.choice].prix
                    this.categorie = response[this.choice].categorie.nom
                    this.localisation = response[this.choice].user.addresse

                })
                .catch(error => {
                    console.log(error)
                })
        },
        redirect(type, id) {

            if (type == 'pro') {
                this.upcontact_pro(id)

            }
            else if (type == 'us') {
                this.upcontact_us(id)

            }
            alert('Cette action est reservé aux utilisateurs inscrit, Veuillez vous connecter ')

            window.location.replace('/register')

        },
        upcontact_pro(id) {
            store.dispatch('auth/upcontact_pro', { 'id': id })
                .then(response => {
                    console.log(response)
                })
                .catch(error => {
                    console.log(error)
                })
        },

        upcontact_us(id) {
            store.dispatch('auth/upcontact_us', { 'id': id })
                .then(response => {
                    console.log(response)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        proprietop() {
            store.dispatch('proprieter/proprietop')
                .then(response => {
                    console.log(response)
                    this.proprieterstop = response
                })
                .catch(error => {
                    console.log(error)
                })
        },
        articletop() {
            store.dispatch('article/articletop')
                .then(response => {
                    console.log(response)
                    this.articles = response
                })
                .catch(error => {
                    console.log(error)
                })
        },

        usertop() {
            store.dispatch('auth/usertop')
                .then(response => {
                    console.log(response)
                    this.userstop = response
                })
                .catch(error => {
                    console.log(error)
                })
        },
        getcategorie() {
            store.dispatch('categorie/getcategorie')
                .then(response => {
                    console.log(response)
                    this.categories = response
                })
                .catch(error => {
                    console.log(error)
                })
        },
        update_click_Pub(id) {
            store.dispatch('pub/update_click_Pub', { 'id': id })
                .then(response => {
                    console.log(response)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        update_click_Banniere(id) {
            store.dispatch('banniere/update_click_Banniere', { 'id': id })
                .then(response => {
                    console.log(response)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        getbanniere() {
            store.dispatch('banniere/getajour_Bannieres')
                .then(response => {
                    console.log(response)
                    this.bannieres = response
                })
                .catch(error => {
                    console.log(error)
                })
        },


    },
    mounted() {
        this.proprie(),
            this.proprietop(),
            this.usertop(),
            this.articletop(),
            this.getcategorie()
        this.getbanniere()
        const $select = $(this.$refs.mySelect);
document.title =  'Agence immobilière Cotonou : trouvez votre bien avec TGV Immo'
document.description=" TGV Immo Brio, agence immobilière à Cotonou, vous aide à acheter, vendre ou louer des biens. Découvrez nos annonces et trouvez votre prochain chez-vous!"
        $select.select2();

        $select.on('change', (event) => {
            // Gérez ici les changements de valeur de Select2
            this.pays = event.target.value;
        });
        store.dispatch('categorie/getcountry')
            .then(response => {
                this.country = response

            })
            .catch(error => {
                console.log(error)
            })
        store.dispatch('pub/get_random_pub')
            .then((response) => {
                this.pub = response.image
                this.pub_lien = response.lien
                this.pub_id = response.id
                // Vérification si 'pub' existe
                if (response.image) {
                    // Vérification si 'timepub' existe dans le localstorage
                    if (localStorage.getItem('timepub')) {
                        const currentTime = new Date().getTime(); // Temps actuel en millisecondes
                        const storedTime = localStorage.getItem('timepub');
                        const elapsedTime = currentTime - parseInt(storedTime); // Temps écoulé depuis le dernier enregistrement

                        // Vérification si le temps écoulé est supérieur à une heure (en millisecondes)
                        if (elapsedTime > 3600000) {
                            // Affichage de la fenêtre modale
                            document.getElementById("imagePopup").style.display = "block";
                            // Enregistrement du nouveau temps dans le localstorage
                            localStorage.setItem('timepub', currentTime.toString());
                        }
                    } else {
                        // Si 'timepub' n'existe pas dans le localstorage, afficher la fenêtre modale
                        document.getElementById("imagePopup").style.display = "block";
                        // Enregistrement du temps actuel dans le localstorage
                        localStorage.setItem('timepub', new Date().getTime().toString());
                    }
                }

                // Fermer la fenêtre modale en cliquant sur le bouton de fermeture
                document.getElementById("closePopup").addEventListener("click", function () {
                    document.getElementById("imagePopup").style.display = "none";
                });


            })
            .catch(error => {
                console.log(error)

            })
        store.dispatch('auth/_currentUser')
            .then(() => {
                this.validuser = true
            })
            .catch(error => {
                console.log(error)

            })

        const urlParams = new URLSearchParams(window.location.search);
        this.type = urlParams.get('type');
        if (this.type == 'devis') {
            document.getElementById('btn_dmd').click()
        }
    }

}
</script>

<template>
    <HeadComponent />

    <main>

        <!-- slider-area start -->
        <section>


            <div v-if="bannieres" id="carouselExampleCaptions" class="container-fluid carousel slide"
                data-ride="carousel">
                <ol class="carousel-indicators">
                    <li v-for="banniere, index in bannieres" :key="banniere.id" data-target="#carouselExampleCaptions"
                        :data-slide-to="index" :class="{ 'active': index === 0 }">
                    </li>
                </ol>
                <div class="carousel-inner" style='width:100%; height: auto;'>
                    <div v-for="banniere, index in bannieres" :key="banniere.id" class="carousel-item "
                        :class="{ 'active': index === 0 }">
                        <a target="_blank" :href="banniere.lien" @click="update_click_Banniere(banniere.id)">
                            <img :src="banniere.image" class="d-block w-100 img-fluid" alt="...">
                        </a>
                    </div>

                </div>
                <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>

        </section>
        <section>
            <div class="background-div d-flex justify-content-center align-items-center">
                <div class="background-content">
                    <h1>Bienvenue chez TGV Immo Brio, votre agence immobili&egrave;re de r&eacute;f&eacute;rence
                        &agrave; Cotonou</h1>
                    <p><strong>TGV Immo Brio</strong> est votre partenaire de confiance pour toutes vos
                        <strong>transactions
                            immobili&egrave;res &agrave; Cotonou</strong>, au <strong>B&eacute;nin</strong>, et partout
                        en
                        <strong>Afrique</strong>. Que vous soyez &agrave; la recherche d'une <strong>villa de plusieurs
                            pi&egrave;ces</strong>, d'un <strong>appartement</strong>, ou que vous souhaitiez
                        <strong>mettre en
                            location un bien</strong>, notre agence immobili&egrave;re vous accompagne dans toutes vos
                        d&eacute;marches pour acheter, vendre ou louer une propri&eacute;t&eacute;. Avec des
                        ann&eacute;es
                        d&rsquo;exp&eacute;rience sur le march&eacute; immobilier africain, nous sommes reconnus pour
                        notre
                        expertise, notre professionnalisme et notre service personnalis&eacute;.
                    </p>   <a href="#seachform" class="btn btn-dark text-light">Trouvez votre bien immobilier</a>
                    
                </div>
            </div>
            <div class="container mt-4">
                <div class="row align-items-center">
                    <!-- Colonne pour l'image à gauche -->
                    <div class="col-md-6">
                        <img src="../assets/mission.jpg" alt="Image d'illustration" class="img-fluid"
                            style="border-radius: 8px;">
                    </div>

                    <!-- Colonne pour le texte à droite -->
                    <div class="col-md-6 ">
                        <h3 class="text-center">TGV Immo Brio : L'agence immobili&egrave;re de confiance en Afrique</h3>
                        <p>Forte de son <strong>exp&eacute;rience sur le march&eacute; immobilier au
                                B&eacute;nin</strong> et
                            &agrave; travers le continent, TGV Immo Brio se distingue par son r&eacute;seau
                            &eacute;tendu et son
                            approche innovante. Nous comprenons l'importance d'un service client de qualit&eacute;, et
                            nous mettons
                            tout en &oelig;uvre pour offrir une <strong>exp&eacute;rience unique</strong> &agrave; nos
                            clients. Que
                            vous cherchiez &agrave; investir dans un appartement &agrave; Cotonou, une villa de
                            plusieurs
                            pi&egrave;ces &agrave; Abomey-Calavi, ou une location meubl&eacute;e dans l&rsquo;une des
                            grandes villes
                            du B&eacute;nin, <strong>TGV Immo Brio</strong> est l&agrave; pour vous aider &agrave;
                            r&eacute;aliser
                            vos projets immobiliers.</p>

                        <h3 class="text-center">Notre mission</h3>
                        <p>Notre mission est simple : <strong>offrir &agrave; nos clients une plateforme moderne et
                                facile &agrave;
                                utiliser pour visualiser et acc&eacute;der aux meilleures offres
                                immobili&egrave;res</strong>
                            disponibles &agrave; <a
                                href="https://decentralisation.gouv.bj/commune/48/cotonou/">Cotonou</a> et au
                            B&eacute;nin. Nous nous engageons &agrave; vous fournir des annonces actualis&eacute;es en
                            temps
                            r&eacute;el, que vous recherchiez&nbsp;:</p>
                        <ul class="text-start">
                            <li><i class="fa fa-check text-primary me-3"></i>Des appartements</li>
                            <li><i class="fa fa-check text-primary me-3"></i>Des chambres et pi&egrave;ces de divers
                                standings</li>
                            <li><i class="fa fa-check text-primary me-3"></i>Une r&eacute;sidence hup&eacute;e &agrave;
                                Fidjross&egrave;,</li>
                            <li><i class="fa fa-check text-primary me-3"></i>Des maisons,</li>
                            <li><i class="fa fa-check text-primary me-3"></i>Des parcelles&nbsp;;</li>
                            <li><i class="fa fa-check text-primary me-3"></i>Un terrain&nbsp;;</li>
                            <li><i class="fa fa-check text-primary me-3"></i>Des villas&nbsp;;</li>
                            <li><i class="fa fa-check text-primary me-3"></i>Des propri&eacute;t&eacute;s commerciales,
                                etc.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="text-center">
                <h2>Nos services : Achat, vente et location immobili&egrave;re</h2>


                <p>Chez TGV Immo Brio, nous proposons une gamme compl&egrave;te de services immobiliers, adapt&eacute;e
                    &agrave; vos besoins.</p>
            </div>
            <div class="container my-5">
                <div class="row text-center">
                    <!-- Card 1 - Acheter -->
                    <div class="col-lg-4">
                        <div class="card h-100 shadow">
                            <img src="../assets/achat.jpg" class="card-img-top" alt="Acheter">
                            <div class="card-body">
                                <h3 class="card-title">Achat de biens immobiliers</h3>
                                <p class="card-text">Vous souhaitez <strong>acheter un bien immobilier &agrave;
                                        Cotonou</strong>, &agrave; Abomey ou ailleurs
                                    au B&eacute;nin ? Notre &eacute;quipe d&rsquo;experts vous accompagne tout au long
                                    du processus, depuis
                                    la visualisation des propri&eacute;t&eacute;s jusqu&rsquo;&agrave; la signature de
                                    l&rsquo;acte de vente
                                    en passant par la gestion. Gr&acirc;ce &agrave; notre <strong>expertise
                                        locale</strong> et notre
                                    <strong>connaissance approfondie du march&eacute;</strong>, nous vous proposons des
                                    biens qui
                                    correspondent &agrave; vos crit&egrave;res et &agrave; votre budget.

                                </p>
                                <p>Nous disposons d&rsquo;une large gamme d&rsquo;annonces pour des appartements avec
                                    des pi&egrave;ces
                                    luxueuses, villas, ou maisons &agrave; vendre, que ce soit dans le centre de
                                    Cotonou, &agrave;
                                    Abomey-Calavi, ou dans d&rsquo;autres villes du B&eacute;nin. Vous pourrez ainsi
                                    choisir entre des biens
                                    haut de gamme ou plus abordables, avec des prix en CFA adapt&eacute;s &agrave; tous
                                    types de budgets.
                                </p>
                                <a href="/search?type=a vendre" class="btn btn-dark">Achetez votre bien</a>
                            </div>
                        </div>
                    </div>

                    <!-- Card 2 - Louer -->
                    <div class="col-md-6 col-lg-4">
                        <div class="card h-100 shadow">
                            <img src="../assets/vente.jpg" class="card-img-top" alt="Louer">
                            <div class="card-body">
                                <h3 class="card-title">Vente de biens immobiliers</h3>
                                <p class="card-text">Si vous souhaitez <strong>vendre un bien immobilier &agrave;
                                        Cotonou</strong>, TGV Immo Brio vous offre
                                    une visibilit&eacute; maximale gr&acirc;ce &agrave; notre <strong>large
                                        r&eacute;seau de clients et de
                                        partenaires</strong>. Nous publions votre bien sur notre plateforme, accessible
                                    &agrave; <strong>des
                                        milliers de visiteurs chaque jour</strong>, et nous vous assistons &agrave;
                                    chaque &eacute;tape pour
                                    garantir une transaction fluide et rapide.</p>
                                <p>Nous comprenons l'importance de la valorisation de votre propri&eacute;t&eacute;, que
                                    ce soit une villa,
                                    un appartement ou une maison de plusieurs pi&egrave;ces, et nous veillons &agrave;
                                    ce que les annonces
                                    mettent en avant les caract&eacute;ristiques essentielles de votre bien (nombre de
                                    chambres ou
                                    pi&egrave;ces, superficie en m&egrave;tres carr&eacute;s, etc.). Avec notre
                                    expertise en marketing
                                    immobilier, nous garantissons que votre bien se d&eacute;marque et attire
                                    l&rsquo;attention des
                                    acheteurs potentiels.</p>
                                <a href="/add" class="btn btn-dark">Vendez votre bien</a>
                            </div>
                        </div>
                    </div>

                    <!-- Card 3 - Vendre -->
                    <div class="col-md-6 col-lg-4">
                        <div class="card h-100 shadow">
                            <img src="../assets/louer.jpg" class="card-img-top" alt="Vendre">
                            <div class="card-body">
                                <h3 class="card-title">Location de biens immobiliers</h3>
                                <p class="card-text">
                                    La <strong>location</strong> est l'une de nos sp&eacute;cialit&eacute;s chez TGV
                                    Immo Brio. Que vous
                                    soyez &agrave; la recherche d'un <strong>appartement ou d'une villa
                                        meubl&eacute;e</strong>, nous
                                    disposons d'un vaste catalogue de biens &agrave; louer &agrave; Cotonou,
                                    Abomey-Calavi et dans les
                                    environs. Que vous ayez besoin d&rsquo;une location de plusieurs pi&egrave;ces pour
                                    une longue
                                    dur&eacute;e ou une courte dur&eacute;e, nous avons des offres qui conviennent
                                    &agrave; tous les
                                    besoins.</p>
                                <p>Nous proposons &eacute;galement <strong>des biens immobiliers pour les
                                        entreprises</strong> (bureaux,
                                    locaux commerciaux, etc.), adapt&eacute;s &agrave; toutes les tailles d'entreprises,
                                    des petites ETS
                                    (entreprises) locales aux multinationales cherchant &agrave; s'installer au
                                    B&eacute;nin.</p>

                                <a href="/search?type=a louer" class="btn btn-dark">Louez votre bien </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
        <section>
            <div class="container">
                <div class="row mt-n5">
                    <div v-for="article in articles" :key="article.id" class="col-md-6 col-lg-4 mt-5 wow fadeInUp"
                        data-wow-delay=".2s"
                        style="visibility: visible; animation-delay: 0.2s; animation-name: fadeInUp;">
                        <div class="blog-grid">
                            <div class="blog-grid-img position-relative"><img v-if="article.a_image[0]"
                                    :src="article.a_image[hasard(article.a_image.length)].image" alt=""
                                    style='width:550px; height:350px'></div>
                            <div class="blog-grid-text p-4">
                                <h3 class="h5 mb-3"><a href="#!">{{ article.titre }}</a></h3>
                                <div v-html="article.description" class="display-30 truncate"></div>
                              
                                <div class="meta meta-style2">
                                    <ul>
                                        <li><a href="#!"><i class="fas fa-calendar-alt"></i> {{
                                            formatDate(article.created_at) }}
                                            </a></li>
                                        <li><a href="#!"><i class="fas fa-heart"></i> {{ article.total_likes }}</a></li>
                                        <li><a href="#!"><i class="fas fa-comments"></i> {{ article.total_comments
                                                }}</a></li>
                                        <li><a :href="'/article_commentaire?id=' + article.id">Voir plus</a></li>
                                        <div class="sharethis-inline-share-buttons"
                                            :data-url="'tgvimmobrio.com/article_commentaire?id=' + article.id"
                                            :data-title="article.categorie.nom" :data-description="article.titre"
                                            :data-image="article.a_image[0].image">
                                        </div>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </section>
        <section>
            <div class="container-fluid" v-if="proprieterstop">
                <h3 class="text-center m-4">Biens immobiliers à la une</h3>
                <div class="row mt-5 ">
                    <div v-for="proprieter in proprieterstop" :key="proprieter.id"
                        :class="'col-xl-4 col-lg-6 col-md-6 proprieter'">
                        <div class="latest-news mb-40">
                            <a :href="'/commentaire?id=' + proprieter.id">
                                <div class="news__thumb " style="position: relative;">

                                    <span v-if="proprieter.meuble" class="badge badge-danger badge-lg"
                                        style="font-size:15px;position: absolute; top: 0; right: 0;">Meublé</span>
                                    <span class="badge badge-primary badge-lg"
                                        style="font-size:15px;position: absolute; top: 0; left: 0;">{{
                                            proprieter.type }}</span>



                                    <img v-if="proprieter.image[0]"
                                        :src="proprieter.image[hasard(proprieter.image.length)].image" alt=""
                                        style='width:550px; height:350px'>
                                    <span class="badge badge-success "
                                        style="font-size:22px; position: absolute; bottom: 0; left: 0;">
                                        <i class="fa fa-location-arrow "></i> {{ proprieter.addresse }}
                                    </span>
                                </div>
                            </a>
                            <div class="news__caption white-bg">
                                <div class="news-meta mb-15">
                                    <span class="text-danger"><i class="far fa-dollar"></i>{{ formatMonetaryValue(
                                        proprieter.prix) }} {{ proprieter.pays.currency_code }}/{{ proprieter.periode }}
                                    </span>
                                    <span class="text-danger"><a href="#"><i class="far fa-heart"></i> {{
                                        proprieter.total_likes }}</a></span>
                                    <span class="text-danger"><a href="#"><i class="far fa-comments"></i>{{
                                        proprieter.total_comments }}</a></span><br />
                                    <span>{{ formatDate(proprieter.created_at) }}</span>
                                    <br />


                                    <span class="text-danger"><a href="#"><i class="fa fa-database "></i> {{
                                        proprieter.categorie.nom }}</a></span>
                                </div>
                                <h3 class="truncate">{{ proprieter.description }}</h3>
                                <div class="social-profile" v-if="!validuser">
                                    <a href='' @click="redirect('pro', proprieter.id)"><i
                                            class="fa fa-envelope"></i></a>
                                    <a href='' @click="redirect('pro', proprieter.id)"><i
                                            class="fab fa-whatsapp"></i></a>
                                    <a :href="'/commentaire?id=' + proprieter.id"><i class="fa fa-eye"></i></a>

                                </div>

                                <div class="social-profile" v-else>

                                    <a target="_blank" @click="upcontact_pro(proprieter.id)"
                                        :href="'mailto:' + proprieter.user.email + '?subject=' + encodeURIComponent('Demande de renseignement') + '&body=' + encodeURIComponent('Bonjour, j\'ai vu ce bien sur TGV IMMO BRIO, ça m\'intéresse. https://tgvimmobrio.com/commentaire?id=' + proprieter.id)"><i
                                            class="fa fa-envelope"></i></a>
                                    <a target="_blank" @click="upcontact_pro(proprieter.id)"
                                        :href="'https://wa.me/' + proprieter.user.whatsapp + '?text=' + encodeURIComponent('Bonjour, j\'ai vu ce bien sur TGV IMMO BRIO, ça m\'intéresse. https://tgvimmobrio.com/commentaire?id=' + proprieter.id)"><i
                                            class="fab fa-whatsapp"></i></a>
                                    <a :href="'/commentaire?id=' + proprieter.id"><i class="fa fa-eye"></i></a>
                                </div>

                                <div class="sharethis-inline-share-buttons"
                                    :data-url="'tgvimmobrio.com/commentaire?id=' + proprieter.id"
                                    :data-title="proprieter.categorie.nom" 
                                    :data-description="proprieter.titre"
                                    :data-image="proprieter.image[0].image">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>

        <section style="overflow-x: auto;">
            <h3 class="text-center m-4">Meilleures agences immobilières</h3>
            <div v-if="userstop" class="container-fluid">
                <div style="white-space: nowrap;">
                    <div v-for="aguser in userstop" :key="aguser.id" class="  card m-2" style="display: inline-block;">
                        <center>
                            <img :src="aguser.photo" class="card-img-top" style="height: 250px; width:250px;" alt="">
                            <div class="card-body">
                                <h5 v-if="aguser.entreprise" class="card-title">{{ aguser.entreprise }}</h5>
                                <h5 v-else class="card-title">{{ aguser.first_name }} {{ aguser.last_name }}</h5>
                                <p class="card-text">{{ aguser.addresse }}</p>
                                <div class="social-profile" v-if="!validuser">
                                    <a href='' @click="redirect('us', aguser.id)"><i class="fa fa-envelope"></i></a>
                                    <a href='' @click="redirect('us', aguser.id)"><i class="fab fa-whatsapp"></i></a>
                                    <a :href="'/search?type=user&id=' + aguser.id"><i class="fa fa-eye"></i></a>
                                </div>
                                <div class="social-profile" v-else>
                                    <a target="_blank" @click="upcontact_us(aguser.id)"
                                        :href="'mailto:' + aguser.email + '?subject=' + encodeURIComponent('Demande de renseignement') + '&body=' + encodeURIComponent('Bonjour, j\'ai vu votre agence immo sur TGV IMMO BRIO')"><i
                                            class="fa fa-envelope"></i></a>

                                    <a target="_blank" @click="upcontact_us(aguser.id)"
                                        :href="'https://wa.me/' + aguser.whatsapp + '?text=' + encodeURIComponent('Bonjour, j\'ai vu votre agence immo sur TGV IMMO BRIO')"><i
                                            class="fab fa-whatsapp"></i></a>
                                    <a :href="'/search?type=user&id=' + aguser.id"><i class="fa fa-eye"></i></a>

                                </div>
                            </div>
                        </center>
                    </div>
                </div>
            </div>
        </section>
        <section class="latest-blog-area pt-95 pb-60 box-90">
            <div class="container-fluid ">
                <div class="row " >

                    <div class="col-sm-12 bg-dark" id='seachform'>
                        <div class="m-5 mb-40 ">

                            <form @submit.prevent="trieproprieter('filtre')">
                                <div class="row">
                                    <div class="col-sm-6">

                                        <label class="text-light">Pays</label>
                                        <select required ref="mySelect" v-model="pays" class="form-control">
                                            <option v-for="countrie in country" :key="countrie.iso"
                                                :value="countrie.iso">{{
                                                    countrie.name }}</option>
                                        </select>

                                    </div>
                                    <div class="col-sm-6">
                                        <div class="checkout-form-list country-select">
                                            <label class="text-light">Categorie </label>
                                            <select v-model="cat_fil" class="form-control" @change="autre_champs()">
                                                <option>choisir une categorie</option>
                                                <option v-for="cat in categories" :key="cat.id" :value="cat.id">{{
                                                    cat.nom }}
                                                </option>

                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="checkout-form-list">
                                            <label class="text-light">Ville </label>

                                            <input type="texte" v-model="localiter" class="form-control"
                                                @input="searchPlaces()" placeholder="Recherchez un lieu...">
                                            <div id="suggestions" v-if="suggestions.length">
                                                <div v-for="suggestion in suggestions" :key="suggestion.id"
                                                    @click="selectSuggestion(suggestion)">
                                                    {{ suggestion.title }}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="checkout-form-list">
                                            <label class="text-light">Montant maximal </label>

                                            <input type="text" v-model="formattedMaxPrix" class="form-control"
                                                @input="formatInput" />

                                        </div>
                                    </div>
                                    <div v-if="v_piece" class="col-sm-6">
                                        <div class="checkout-form-list">
                                            <label>Nombre pièce </label>
                                            <input type="number" class="form-control" v-model="nbr_piece" />
                                        </div>
                                    </div>

                                    <div v-if="v_parcelle" class="col-sm-6">
                                        <div class="checkout-form-list">
                                            <label class="text-light">Nombre de parcelle </label>
                                            <input type="number" class="form-control" v-model="nbr_parcelle" />
                                        </div>
                                    </div>

                                    <div v-if="v_hectare" class="col-sm-6">
                                        <div class="checkout-form-list">
                                            <label class="text-light">Nombre d'hectare </label>
                                            <input type="number" v-model="nbr_hectare" class="form-control" />
                                        </div>
                                    </div>
                                    <div v-if="v_superficie" class="col-sm-6">
                                        <div class="checkout-form-list">
                                            <label class="text-light">Superficie en m² </label>
                                            <input type="number" v-model="superficie" class="form-control" />
                                        </div>
                                    </div>





                                </div>

                                <center>

                                    <button type="submit" class="btn theme-btn-2 text-light">Lancer filtre</button>
                                    <button type="reset" @click="trieproprieter('all')" class=" bg-dark text-light"><i
                                            class="fa fa-refresh"></i> </button>

                                </center>
                            </form>
                        </div>
                    </div>


                    <div class="col-sm-12">
                        <div class="area-title mb-50">
                            <h3>
                                <center>Tous les biens immobiliers</center>
                            </h3>
                        </div>
                    </div>
                </div>

                <div class="row" v-if="proprieters" id="vupro">
                    <div v-for="proprieter in paginatedProprieters" :key="proprieter.id" :id="proprieter.id"
                        class="col-xl-4 col-lg-6 col-md-6 proprieter">
                        <div class="latest-news mb-40">
                            <a :href="'/commentaire?id=' + proprieter.id">
                                <div class="news__thumb " style="position: relative;">

                                    <span v-if="proprieter.meuble" class="badge badge-danger badge-lg"
                                        style="font-size:15px;position: absolute; top: 0; right: 0;">Meublé</span>
                                    <span class="badge badge-primary badge-lg"
                                        style="font-size:15px;position: absolute; top: 0; left: 0;">{{
                                            proprieter.type }}</span>


                                    <img v-if="proprieter.image[0]"
                                        :src="proprieter.image[hasard(proprieter.image.length)].image" alt=""
                                        style='width:550px; height:350px'>
                                    <span class="badge badge-success "
                                        style="font-size:22px; position: absolute; bottom: 0; left: 0;">
                                        <i class="fa fa-location-arrow "></i> {{ proprieter.addresse }}
                                    </span>
                                </div>
                            </a>
                            <div class="news__caption white-bg">
                                <div class="news-meta mb-15">
                                    <span class="text-danger"><i class="far fa-dollar"></i>{{ formatMonetaryValue(
                                        proprieter.prix) }} {{
                                            proprieter.pays.currency_code }} /{{ proprieter.periode }}</span>
                                    <span class="text-danger"><a href="#"><i class="far fa-heart"></i> {{
                                        proprieter.total_likes }}</a></span>
                                    <span class="text-danger"><a href="#"><i class="far fa-comments"></i>{{
                                        proprieter.total_comments }}</a></span><br />
                                    <span>{{ formatDate(proprieter.created_at) }}</span><br />

                                    <span class="text-danger"><a :href="'/commentaire?id=' + proprieter.id">
                                            <i class="fa fa-database "></i> {{
                                                proprieter.categorie.nom }}</a>
                                    </span>

                                </div>
                                <h3 class="truncate">{{ proprieter.description }}</h3>
                                <div class="social-profile" v-if="!validuser">
                                    <a href='' @click="redirect('pro', proprieter.id)"><i
                                            class="fa fa-envelope"></i></a>
                                    <a href='' @click="redirect('pro', proprieter.id)"><i
                                            class="fab fa-whatsapp"></i></a>
                                    <a :href="'/commentaire?id=' + proprieter.id"><i class="fa fa-eye"></i></a>

                                </div>

                                <div class="social-profile" v-else>

                                    <a target="_blank" @click="upcontact_pro(proprieter.id)"
                                        :href="'mailto:' + proprieter.user.email + '?subject=' + encodeURIComponent('Demande de renseignement') + '&body=' + encodeURIComponent('Bonjour, j\'ai vu ce bien sur TGV IMMO BRIO, ça m\'intéresse. https://tgvimmobrio.com/commentaire?id=' + proprieter.id)"><i
                                            class="fa fa-envelope"></i></a>
                                    <a target="_blank" @click="upcontact_pro(proprieter.id)"
                                        :href="'https://wa.me/' + proprieter.user.whatsapp + '?text=' + encodeURIComponent('Bonjour, j\'ai vu ce bien sur TGV IMMO BRIO, ça m\'intéresse. https://tgvimmobrio.com/commentaire?id=' + proprieter.id)"><i
                                            class="fab fa-whatsapp"></i></a>
                                    <a :href="'/commentaire?id=' + proprieter.id"><i class="fa fa-eye"></i></a>
                                </div>

                                <div class="sharethis-inline-share-buttons"
                                    :data-url="'tgvimmobrio.com/commentaire?id=' + proprieter.id"
                                    :data-title="proprieter.categorie.nom" 
                                    :data-description="proprieter.titre"
                                    :data-image="proprieter.image[0].image">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pagination justify-content-center">
                    <ul class="pagination">
                        <li v-for="pageNumber in totalPages" :key="pageNumber" @click="setPage(pageNumber)"
                            class="page-item" :class="{ 'active': pageNumber === currentPage }">
                            <a class="page-link"> {{ pageNumber }} </a>
                        </li>
                    </ul>
                </div>

                <div v-if="!proprieters" class="alert alert-warning alert-dismissible fade show" role="alert">
                    <div class="alert-heading">
                        <h4 class="alert-title">Alert</h4>
                    </div>
                    <p>Aucun bien immobilier n'est trouvé</p>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>

        </section>
        <!-- product-area end -->

        <section>

            <h2 class="text-center">Pourquoi choisir TGV Immo Brio ?</h2>
            <p class="text-center">Voici quelques-unes des raisons pour lesquelles TGV Immo Brio est le choix
                pr&eacute;f&eacute;r&eacute;
                de nombreux clients, qu&rsquo;ils soient acheteurs, vendeurs ou locataires :</p>


            <div class="container mt-4">
                <div class="row align-items-center">
                    <!-- Colonne pour l'image à gauche -->
                    <div class="col-md-6">
                        <img src="../assets/pq.jpg" alt="Image d'illustration" class="img-fluid"
                            style="border-radius: 8px;">
                    </div>

                    <!-- Colonne pour le texte à droite -->
                    <div class="col-md-6 ">
                        <h3>Un r&eacute;seau local et international</h3>
                        <p>Gr&acirc;ce &agrave; notre <strong>r&eacute;seau bien implant&eacute; &agrave; Cotonou,
                                &agrave;
                                Abomey-Calavi et dans d&rsquo;autres r&eacute;gions du B&eacute;nin</strong>, ainsi
                            qu&rsquo;&agrave; nos <strong>partenariats avec des agences immobili&egrave;res
                                internationales</strong>, nous vous offrons une visibilit&eacute; et une expertise
                            incomparables.
                            Cela nous permet de proposer des annonces exclusives et d&rsquo;acc&eacute;der aux
                            meilleures
                            opportunit&eacute;s du march&eacute; immobilier.</p>
                        <h3>Une plateforme innovante</h3>
                        <p>TGV Immo Brio est une <strong>plateforme innovante qui vous permet de visualiser facilement
                                les
                                meilleures annonces immobili&egrave;res</strong> au B&eacute;nin. Que vous recherchiez
                            des
                            appartements ou des maisons, nous proposons des biens avec plusieurs chambres ou
                            pi&egrave;ces,
                            meubl&eacute;s ou non, &agrave; des prix comp&eacute;titifs en FCFA.</p>
                        <p>D&eacute;couvrez des parcelles &agrave; vendre, des terrains pour vos projets de construction
                            ou encore
                            des r&eacute;sidences &agrave; fidjross&egrave;. Nous g&eacute;rons &eacute;galement des
                            immeubles et
                            proposons des options de gestion locative. Avec des annonces actualis&eacute;es et des biens
                            vari&eacute;s (maisons, terrains, appartements, pi&egrave;ces) que vous pouvez visualiser
                            directement,
                            TGV Immo Brio est votre r&eacute;f&eacute;rence immobili&egrave;re au B&eacute;nin.</p>
                        <h3>Des conseils personnalis&eacute;s</h3>
                        <p>Chez TGV Immo Brio, nous savons que chaque client est unique. C&rsquo;est pourquoi nous vous
                            proposons
                            <strong>un accompagnement ou une gestion sur mesure tout au long de votre projet
                                immobilier</strong>.
                            Que vous recherchiez un appartement d&rsquo;une ou deux chambres salon simple ou sanitaire
                            &agrave;
                            Cotonou, ou une villa spacieuse avec jardin &agrave; Calavi, nous &eacute;coutons vos
                            besoins et nous
                            nous engageons &agrave; vous proposer des solutions qui correspondent parfaitement &agrave;
                            vos
                            attentes.
                        </p>
                        <h3>Expertise sur le march&eacute; immobilier africain</h3>
                        <p>En tant qu'<a
                                href="https://www.imop.fr/blog/devenir-un-pro-de-limmobilier/5-criteres-pour-choisir-agence-immobiliere">agence
                                immobili&egrave;re</a> de r&eacute;f&eacute;rence en Afrique, nous avons <strong>une
                                connaissance
                                approfondie des dynamiques du march&eacute; immobilier au B&eacute;nin</strong>. Cela
                            nous permet de
                            vous conseiller sur les meilleures zones d&rsquo;investissement, que vous soyez
                            int&eacute;ress&eacute;
                            par une propri&eacute;t&eacute; &agrave; Cotonou, &agrave; Abomey, ou dans toute autre ville
                            d'Afrique.
                        </p>
                        <p>&nbsp;</p>
                    </div>
                </div>
            </div>

            <h2 class="text-center">Pourquoi opter pour le march&eacute; immobilier &agrave; Cotonou&nbsp;?</h2>

            <div class="container mt-4">
                <div class="row align-items-center">
                    <!-- Colonne pour l'image à gauche -->


                    <!-- Colonne pour le texte à droite -->
                    <div class="col-md-6 ">
                        <h3>Croissance et opportunit&eacute;s</h3>
                        <p>Le march&eacute; immobilier &agrave; Cotonou conna&icirc;t <strong>une croissance
                                rapide</strong>,
                            stimul&eacute;e par le d&eacute;veloppement &eacute;conomique du B&eacute;nin. De plus en
                            plus
                            d'investisseurs locaux et &eacute;trangers choisissent Cotonou comme destination
                            privil&eacute;gi&eacute;e pour leurs investissements immobiliers, attir&eacute;s par
                            <strong>la
                                stabilit&eacute; du pays</strong> et les opportunit&eacute;s qu&rsquo;il offre.
                        </p>
                        <p>&Agrave; Cotonou, les biens immobiliers varient en termes de prix, allant de
                            propri&eacute;t&eacute;s de
                            luxe &agrave; des options plus abordables, toujours avec des prix exprim&eacute;s en FCFA.
                            L&rsquo;agence TGV Immo Brio est l&agrave; pour vous accompagner dans vos recherches, que
                            vous cherchiez
                            un bien &agrave; acheter ou &agrave; louer.</p>
                        <h3>Un cadre id&eacute;al et attractif</h3>
                        <p>Cotonou, capitale &eacute;conomique du B&eacute;nin, offre <strong>un cadre de vie attractif
                            </strong>avec ses <strong>infrastructures modernes</strong>, son dynamisme &eacute;conomique
                            et sa
                            <strong>proximit&eacute; avec la mer</strong>. Que vous cherchiez &agrave; louer un
                            appartement en
                            centre-ville pour quelques milliers de FCFA, une villa dans un quartier r&eacute;sidentiel
                            ou encore une
                            location meubl&eacute;e pour vos s&eacute;jours temporaires, TGV Immo Brio a tout ce dont
                            vous avez
                            besoin pour trouver la propri&eacute;t&eacute; parfaite.
                        </p>
                    </div>
                    <div class="col-md-6">
                        <img src="../assets/ctn.jpg" alt="Image d'illustration" class="img-fluid"
                            style="border-radius: 8px;">
                    </div>
                </div>

            </div>



            <div class="container my-5">
                <div class="row text-center">
                    <div class="col">
                        <h2 class="mb-5">T&eacute;moignages clients</h2>
                        <p>Nos clients t&eacute;moignent de leur satisfaction avec TGV Immo Brio. Qu&rsquo;il
                            s&rsquo;agisse de la
                            rapidit&eacute; de nos services, de la diversit&eacute; des biens propos&eacute;s ou de
                            l'accompagnement
                            personnalis&eacute;, ils reconnaissent tous l&rsquo;expertise et le professionnalisme de
                            notre
                            &eacute;quipe.</p>
                    </div>
                </div>

                <div class="row">
                    <!-- Testimonial 1 -->
                    <div class="col-md-4">
                        <div class="card h-100 shadow p-3">
                            <div class="d-flex align-items-center mb-3">
                                <img src="https://tgvimmobrio.com/media/photo/229_61_05_04_67_20240125_210536.jpg" alt="Client 1" class="rounded-circle" width="60" height="60">
                                <div class="ms-3">
                                    <h5 class="mb-0">Rufiss</h5>
                                    <p class="text-muted mb-0">CEO, Entreprise X</p>
                                </div>
                            </div>
                            <p class="card-text">
                                "TGV Immo Brio a été fantastique ! Grâce à eux, j'ai trouvé la maison parfaite à
                                Cotonou. Leur service est irréprochable et leur réseau est immense."
                            </p>
                        </div>
                    </div>

                    <!-- Testimonial 2 -->
                    <div class="col-md-4">
                        <div class="card h-100 shadow p-3">
                            <div class="d-flex align-items-center mb-3">
                                <img src="https://tgvimmobrio.com/media/photo/236C2F93-B0AC-4CAD-AB58-04FEFAD24521.jpeg" alt="Client 2" class="rounded-circle" width="60" height="60">
                                <div class="ms-3">
                                    <h5 class="mb-0">d'ALMEIDA kevin</h5>
                                    <p class="text-muted mb-0">Directrice, Société Y</p>
                                </div>
                            </div>
                            <p class="card-text">
                                "L’équipe de TGV Immo Brio a dépassé mes attentes. Ils m'ont aidée à trouver une
                                location en un temps record, avec un suivi exemplaire."
                            </p>
                        </div>
                    </div>

                    <!-- Testimonial 3 -->
                    <div class="col-md-4">
                        <div class="card h-100 shadow p-3">
                            <div class="d-flex align-items-center mb-3">
                                <img src="https://tgvimmobrio.com/media/photo/17138833572994693489920933788889.jpg" alt="Client 3" class="rounded-circle" width="60" height="60">
                                <div class="ms-3">
                                    <h5 class="mb-0">Tossou laurent</h5>
                                    <p class="text-muted mb-0">Fondateur, Startup Z</p>
                                </div>
                            </div>
                            <p class="card-text">
                                "J'ai vendu ma propriété rapidement grâce à l'approche marketing de TGV Immo Brio. Je
                                recommande vivement leurs services."
                            </p>
                        </div>
                    </div>
                </div>
            </div>




        </section>

        <!-- top-seller-area start -->
        <!-- <section class="top-seller-area box-90">
            <div v-if="imgprofil" class="container-fluid">
                <div class="row">
                    <div class="col-xl-5 col-lg-8 col-md-7">
                        <div class="area-title mb-50">
                            <h3>Bien de l'instant</h3>

                        </div>
                    </div>
                    <div class="col-xl-7 col-lg-4 col-md-5">
                        <div class="vue-btn text-left text-md-right mb-50">
                            <a href="/" class="btn theme-btn">Collection</a>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-5 col-lg-5">
                        <div class="top-seller mb-50">
                            <img :src="imgprofil" alt="" style='width:695px; height:520px'>
                            <div class="seller-box text-center">
                                <div class="top-seller-content text-left">
                                    <h3><a>{{ profil }}</a></h3>
                                    <div class="pro-price mb-25">
                                        <span>{{ localisation }}</span>
                                    </div>

                                    <div class="top-seller-btn">
                                        <a :href="'/search?type=user&id=' + userid" class="btn theme-btn">Voir les
                                            biens</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-7 col-lg-7">
                        <div class="top-seller text-right mb-50">
                            <img :src="imgproprieter" alt="" style='width:695px; height:652px'>
                            <div class="sellet-2-content">
                                <h3><a>{{ propriete }}</a></h3>
                                <div class="pro-price mb-25">
                                    <span>{{ categorie }}</span>
                                </div>
                                <div class="top-seller-btn">
                                    <a :href="'/commentaire?id=' + proprieterId" class="btn theme-btn-2 mr-20">Voir
                                        details</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->
        <div id="imagePopup" class="image-popup">
            <div class="popup-content">
                <span class="close-btn p-2" id="closePopup" style="background-color:#f08d21">&times;</span>
                <a target="_blank" :href="pub_lien" @click="update_click_Pub(pub_id)">
                    <img :src="pub" class="img-fluid" alt="Image à afficher">
                </a>
            </div>
        </div>


    </main>

    <FootComponent />
</template>

<style scoped>
#suggestions div {
    padding: 8px;
    cursor: pointer;
    border-bottom: 1px solid #ccc;

    background-color: #f0f0f0;
}

#suggestions div:hover {
    background-color: gray;
}

#about-us {
    padding: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

#about-us .about-item {
    width: 80%;
    text-align: center;
    margin-right: 2px;
}

.about-item h2 {
    font-style: italic;
    font-weight: 900;
    font-size: 1.4em;
}

.about-uss {
    justify-content: center;
}

.about-item .fas {
    font-size: 2.5em;
    border: 3px solid rgb(205, 127, 50);
    border-radius: 100%;
    padding: 30px;
    color: rgb(205, 127, 50);
    width: 106px;
}

.image-popup {
    display: none;
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.8);
}

.popup-content {
    position: relative;
    margin: auto;
    padding: 20px;
    width: 80%;
    max-width: 800px;
}

/* Ajoutez ces règles pour styliser le bouton de fermeture */
.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
    width: 30px;
    /* Largeur et hauteur du bouton */
    height: 30px;
    border-radius: 50%;
    /* Rendre le bouton rond */
    background-color: rgba(0, 0, 0, 0.5);
    /* Couleur de fond du bouton */
    display: flex;
    justify-content: center;
    align-items: center;
}

.close-btn:hover {
    background-color: rgba(0, 0, 0, 0.7);
    /* Changement de couleur au survol */
}




body {
    margin-top: 20px;
}

.blog-grid {
    position: relative;
    box-shadow: 0 1rem 1.75rem 0 rgba(45, 55, 75, 0.1);
    height: 100%;
    border: 0.0625rem solid rgba(220, 224, 229, 0.6);
    border-radius: 0.25rem;
    transition: all .2s ease-in-out;
    height: 100%
}

.blog-grid span {
    color: #292dc2
}

.blog-grid img {
    width: 100%;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem
}

.blog-grid-text {
    position: relative
}

.blog-grid-text>span {
    color: #292dc2;
    font-size: 13px;
    padding-right: 5px
}

.blog-grid-text h4 {
    line-height: normal;
    margin-bottom: 15px
}

.blog-grid-text .meta-style2 {
    border-top: 1px dashed #cee1f8;
    padding-top: 15px
}

.blog-grid-text .meta-style2 ul li {
    margin-bottom: 0;
    font-weight: 500
}

.blog-grid-text .meta-style2 ul li:last-child {
    margin-right: 0
}

.blog-grid-text ul {
    margin: 0;
    padding: 0
}

.blog-grid-text ul li {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    margin: 5px 10px 5px 0
}

.blog-grid-text ul li:last-child {
    margin-right: 0
}

.blog-grid-text ul li i {
    font-size: 14px;
    font-weight: 600;
    margin-right: 5px
}

.blog-grid-text p {
    font-weight: 400;
    padding: 0
}

.blog-list-left-heading:after,
.blog-title-box:after {
    content: '';
    height: 2px
}

.blog-grid-simple-content a:hover {
    color: #1d184a
}

.blog-grid-simple-content a:hover:after {
    color: #1d184a
}

.blog-grid-text {
    position: relative
}

.blog-grid-text>span {
    color: #292dc2;
    font-size: 13px;
    padding-right: 5px
}

.blog-grid-text h4 {
    line-height: normal;
    margin-bottom: 15px
}

.blog-grid-text .meta-style2 {
    border-top: 1px dashed #cee1f8 !important;
    padding-top: 15px
}

.blog-grid-text .meta-style2 ul li {
    margin-bottom: 0;
    font-weight: 500
}

.blog-grid-text .meta-style2 ul li:last-child {
    margin-right: 0
}

.blog-grid-text ul {
    margin: 0;
    padding: 0
}

.blog-grid-text ul li {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    margin: 5px 10px 5px 0
}

.blog-grid-text ul li:last-child {
    margin-right: 0
}

.blog-grid-text ul li i {
    font-size: 14px;
    font-weight: 600;
    margin-right: 5px
}

.blog-grid-text p {
    font-weight: 400;
    padding: 0
}

a,
a:active,
a:focus {
    text-decoration: none;
    transition-timing-function: ease-in-out;
    -ms-transition-timing-function: ease-in-out;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    transition-duration: .2s;
    -ms-transition-duration: .2s;
    -moz-transition-duration: .2s;
    -webkit-transition-duration: .2s;
    -o-transition-duration: .2s;
}
 .display-30 .truncate {
      display: -webkit-box;
      -webkit-line-clamp: 3; /* Limite à 3 lignes */
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
.pagination {
    border-radius: 0;
    padding: 0;
    margin: 0
}

.pagination ul {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    margin: 0 auto;
    padding: 0
}

.pagination li {
    display: inline
}

.pagination a {
    float: left;
    padding: 0 18px;
    line-height: 40px;
    text-decoration: none;
    border: 1px solid #dbdbdb;
    border-left-width: 0;
    background: #fff
}

.pagination a:hover {
    background-color: #1d184a;
    color: #fff
}

.pagination .active a {
    background-color: #f7f7f7;
    color: #999;
    cursor: default
}

.pagination .disabled span {
    color: #999;
    background-color: transparent;
    cursor: default
}

.pagination .disabled a {
    color: #999;
    background-color: transparent;
    cursor: default
}

.pagination .disabled a:hover {
    color: #999;
    background-color: transparent;
    cursor: default
}

.pagination li:first-child a {
    border-left-width: 1px
}

.mt-6,
.my-6 {
    margin-top: 3.5rem;
}

.background-div {
    background-image: url('../assets/home.JPG');
    /* Remplacez par le chemin de votre image */
    background-size: cover;
    background-position: center;
    position: relative;
    padding: 90px;

    color: white;
    /* Texte en blanc */
}

.background-div h1,
.background-div p {
    color: white;
    /* Forcer le h1 à être en blanc */
}

.background-div::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Assombrissement */
    z-index: 1;
}

.background-content {
    position: relative;
    z-index: 2;
    text-align: center;
    /* Centrer le texte */
    color: white;
}
</style>
