<script>
import AsideComponent from "@/components/Asideadmin"
import HeadComponent from "@/components/Headadmin"
import FootComponent from "@/components/Footadmin"
import store from '@/store';
import $ from "jquery";
import 'select2';
import 'select2/dist/css/select2.min.css';
import Swal from 'sweetalert2';
import  Editor  from '@tinymce/tinymce-vue';

export default {

    components: {
        Editor,
        HeadComponent,
        AsideComponent,
        FootComponent
    },
    data() {

        return {
            
            caterid: null,
            categories: null,
            description: null,
          
            titre: null,
           
           loading:false,
            images: [],
          
            errors: null,
            id: null,
            editorInit: {
        height: 200,
        menubar: true,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount',
          'emoticons template textcolor colorpicker textpattern',
          'wordcount paste autoresize', // Ajouter des outils pour les mots et l'auto-ajustement
        ],
        toolbar:
          'undo redo | formatselect | bold italic underline strikethrough | fontselect fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | outdent indent | bullist numlist | link image media | table | emoticons | charmap | insertdatetime | code | preview | fullscreen | removeformat',
        menu: {
          file: { title: 'File', items: 'newdocument | preview | print ' },
          edit: { title: 'Edit', items: 'undo redo | cut copy paste | selectall' },
          view: { title: 'View', items: 'visualblocks visualchars | fullscreen | code' },
          insert: { title: 'Insert', items: 'link image media | table | emoticons | template' },
          format: { title: 'Format', items: 'bold italic underline strikethrough | formats | alignleft aligncenter alignright alignjustify | fontselect fontsizeselect | forecolor backcolor' },
          tools: { title: 'Tools', items: 'spellchecker code' },
          table: { title: 'Table', items: 'inserttable | cellprops | tableprops | deletetable' },
          help: { title: 'Help', items: 'help' },
        },
        content_style: 'body { font-family: Arial, sans-serif; font-size: 14px; }', // Style de contenu
      }
        }
    },
    name: 'UpdateComponent',
    mounted() {
        const $select = $(this.$refs.mySelect);

        $select.select2();

        $select.on('change', (event) => {
            // Gérez ici les changements de valeur de Select2
            this.pays = event.target.value;
        });

        // Écoutez l'événement de changement de Select2 et mettez à jour le modèle Vue
        this.getcategorie()

        const urlParams = new URLSearchParams(window.location.search)
        this.id = urlParams.get('id')
        store.dispatch('article/articleinfo', { 'id': this.id })
            .then(response => {
                    this.caterid = response.categorie.id,
                    this.description = response.description,
                    this.titre = response.titre,
                    
                this.photos = response.a_image
              

            })
            .catch(error => {
                console.log(error)
            })

      


    }, 
    methods: {
       

        deleteimage(id) {
            Swal.fire({
                title: "Question",
                text: "Voulez vous supprimer cette image ?",
                icon: "question",
                showCancelButton: true,
                cancelButtonColor: "#f46a6a",
                confirmButtonColor: "#34c38f",
                confirmButtonText: "Oui",
                cancelButtonText: 'Non',
            }).then(async result => {
                if (result.value) {


                    store.dispatch('article/deleteimage', { 'id': id }).then(
                        response => {
                            Swal.fire({
                                title: "Succès",
                                text: response.message,
                                icon: "success",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            }).then(() => {
                                document.getElementById(id).remove()
                            })
                        }).catch(error => {
                            Swal.fire({
                                title: "Erreur",
                                text: error.response.data.errors,
                                icon: "error",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            })

                        })
                }
            })
        },
        handleFileChange(event) {
            // Le fichier sélectionné est accessible via event.target.files
            const selectedFile = event.target.files;

            // Mettez à jour le modèle avec les informations sur le fichier
            this.images = selectedFile;

            // Vous pouvez effectuer d'autres actions avec le fichier ici
        },
        getcategorie() {
            store.dispatch('article/getcategorie')
                .then(response => {
                    console.log(response)
                    this.categories = response
                })
                .catch(error => {
                    console.log(error)
                })
        },
        save() {
            this.loading=true

            const formData = new FormData()
            formData.append('id', this.id)
            formData.append('categorie', this.caterid)
            formData.append('description', this.description)
            formData.append('titre', this.titre)
           
            for (var i = 0; i < this.images.length; i++) {

                formData.append('images', this.images[i])

            }



            store.dispatch('article/updatearticle', formData).then(response => {
                this.loading=false
                Swal.fire({
                    title: "Succès",
                    text: response.message,
                    icon: "success",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonColor: "#34c38f",
                    confirmButtonText: "OK",
                }).then(() => {
                    window.location.replace('/admarticle')
                })
            }).catch(error => {
                
            this.loading=false
                if (error.response) {
                    // La requête a été effectuée, mais le serveur a répondu avec un statut autre que 2xx
                    console.error('Réponse du serveur avec erreur :', error.response.data)

                    this.errors = error.response.data.errors
                } else if (error.request) {
                    // La requête a été effectuée, mais aucune réponse n'a été reçue
                    console.error('Pas de réponse du serveur :', error.request)
                    this.errors = 'Pas de réponse du serveur.'
                } else {
                    // Une erreur s'est produite lors de la configuration de la requête
                    console.error('Erreur lors de la configuration de la requête :', error.message)
                    this.errors = 'Erreur lors de la configuration de la requête.'
                }
            })

        }
    }
}

</script>

<template>
    <body class="g-sidenav-show  bg-gray-200" id="body">
        <AsideComponent />

        <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
            <!-- Navbar -->
            <HeadComponent />
            <!-- End Navbar -->
            <div class="container-fluid ">
                <section>
                    <div class="page-header">
                        <div class="container">
                            <div class="row">

                                <div class="card card-plain">
                                    <div class="card-header">
                                        <h4 class="font-weight-bolder">Modifier un article</h4>
                                        <div v-if="errors" class="alert alert-danger alert-dismissible fade show"
                                            role="alert">
                                            <div class="alert-heading">
                                                <h4 class="alert-title">ERREUR</h4>
                                            </div>
                                            <p>{{ errors }}</p>
                                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <form @submit.prevent="save">
                                            <div class="row">
                                                <div class="input-group input-group-outline  mb-3 col-md-6  ">
                                                    <label class="form-label ">Titre</label>
                                                    <input type="text" v-model="titre" class="form-control">
                                                </div>
                                             
                                                <div class="input-group input-group-outline mb-3 col-md-6">
                                                    <label class="form-label"
                                                        :class="{ 'active': caterid }">Categorie</label>
                                                    <select @change="autre_champs()" class="form-control" v-model="caterid"
                                                        aria-label="Default select example">
                                                        <option v-for="cat in categories" :key="cat.id" :value="cat.id">
                                                            {{
                                                                cat.nom }}</option>
                                                    </select>
                                                </div>

                                               
                                                <div class="input-group input-group-outline mb-3 col-md-6">

                                                    <input type="file" @change="handleFileChange" style="height: 45px;"
                                                        multiple class="form-control">
                                                </div>
                                             
                                                <editor  api-key="yq9iu126ilcqez6o65pxe5eqij3fmmbm5sdyciqvlcvbva41" v-model="description" :init="editorInit" />



                                            </div>
                                            <div v-if='photos' class="image-container" >
                                                <a v-for="(photo, index) in photos" :key="index" :id="photo.id"
                                                    @click="deleteimage(photo.id)">
                                                    <img :src=" photo.image" class="small-image m-4"
                                                        alt="blog image" style="width:100px ; height: 100px ;" />
                                                </a>
                                            </div>

                                            <div class="text-center">
                                                <div v-if='loading' class="d-flex justify-content-center align-items-center" style="height: 100vh;">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Chargement...</span>
    </div>
  </div>
                                                <button v-else type="submit"
                                                    class="btn btn-lg bg-gradient-primary btn-lg w-100 mt-4 mb-0">
                                                    Enregistrer
                                                </button>
                                            </div>

                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <FootComponent />
            </div>
        </main>
    </body>
</template>
<style>
.form-label.active {
    visibility: hidden;
}
</style>