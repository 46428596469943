<script>
import HeadComponent from "@/components/Head"
import FootComponent from '@/components/Foot'
export default {
    components: {
        HeadComponent,
        FootComponent
    },
    name: 'HomeComponent',
mounted(){
    document.title =  'A propos de tgv immobilier'
}

}
</script>
<template>
    <HeadComponent />
    <main>

        <section>
            <center>
                <div class="row  align-items-center justify-content-center ">
                    <div class="col-md-5  ">
                        <img src="img/logo/1.jpg" />
                    </div>
                    <div class="col-md-5">
                        <div class=" text-5xl mb-14 ">Bienvenue sur <strong> TGV IMMO BRIO </strong>,la référence en
                            matière d'immobilier en Afrique !
Notre plateforme vous offre une expérience unique pour vendre, acheter ou louer des biens immobiliers au Bénin et dans toute l'Afrique. Avec notre vaste réseau d'agences immobilières, nous vous garantissons les meilleures opportunités et une rentabilité inégalée. Découvrez dès maintenant notre sélection exclusive de propriétés biens structurées et réalisez vos projets immobiliers avec succès. 
Bienvenue dans le monde de l'immobilier, où rentabilité et excellence se rencontrent sur <strong>TGV IMMO BRIO.</strong>
                        </div>


                        <div class="text-5xl mt-10 ">
                            Chez nous nous croyons que l'accès à un logement idéal devrait
                            être une expérience
                            transparente, efficace et collaborative. Notre plate-forme tire parti des dernières avancées
                            technologiques pour créer un écosystème dynamique où les professionnels de l'immobilier
                            peuvent
                            interagir de manière fluide, les annonceurs peuvent mettre en valeur leurs biens avec une
                            visibilité maximale, et les utilisateurs peuvent explorer un éventail diversifié de biens
                            immobiliers, répondant à leurs besoins spécifiques.
                        </div>
                    </div>

                    <div class="col-md-5">
                        <div class=" text-5xl mb-14 ">Notre engagement envers l'excellence se reflète dans notre
                            approche centrée sur le client. <strong> TGV IMMO
                                BRIO</strong> offre une interface conviviale, des fonctionnalités de recherche avancées
                            et des outils de
                            communication innovants, facilitant ainsi le processus de recherche et d'achat pour les
                            clients.
                            Nous aspirons à créer une communauté immobilière dynamique où l'information est accessible,
                            les
                            transactions sont simplifiées, et les relations professionnelles prospèrent.

                        </div>


                        <div class="text-5xl mt-10 "> Que vous soyez un professionnel de l'immobilier cherchant à
                            étendre votre
                            portée,
                            un annonceur désirant maximiser la visibilité de vos biens, ou un utilisateur en quête de la
                            propriété parfaite, <strong> TGV IMMO BRIO </strong>est votre partenaire de confiance.
                            Rejoignez-nous dans cette aventure passionnante où chaque interaction devient une
                            opportunité de
                            réaliser des rêves immobiliers exceptionnels.
                            <strong>TGV IMMO BRIO</strong>, votre destination pour des
                            opportunité immobilières sans limites.
                        </div>

                    </div>

                    <div class="col-md-5 mb-100 ">
                        <img src="img/logo/2.jpg" />
                    </div>

                </div>

            </center>
        </section>
    </main>

    <FootComponent />
</template>