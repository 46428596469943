import axios from 'axios'
import axiosWithHeaders, { BASE_API_URL } from '@/services/api'

const state = {}
const getters = {}
const mutations = {}
const actions={

    addBanniere({commit}, data){
        commit=null
        console.log(commit)     
        return new Promise((resolve, reject) => {
            axiosWithHeaders.post(BASE_API_URL + 'bannieres/add',data)
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })
    },
    modBanniere({commit}, data){
        commit=null
        console.log(commit)     
        return new Promise((resolve, reject) => {
            axiosWithHeaders.put(BASE_API_URL + 'banniere_update',data)
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })
    },
    
    supBanniere({commit}, data){
        commit=null
        console.log(commit)     
        return new Promise((resolve, reject) => {
            axiosWithHeaders.delete(BASE_API_URL + 'banniere/'+data.id+'/delete')
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })
    },
    update_click_Banniere({commit}, data){
        commit=null
        console.log(commit)     
        return new Promise((resolve, reject) => {
            axios.get(BASE_API_URL + 'update_click_Banniere/'+data.id)
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })
    },
    getajour_Bannieres(){
        return new Promise((resolve, reject) => {
            axios.get(BASE_API_URL + 'getajour_Bannieres')
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })
    },
    getbanniere() {
        return new Promise((resolve, reject) => {
            axios.get(BASE_API_URL + 'bannieres')
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })
    },
    
    

}

export default {
    namespaced:true,
    state,
    getters,
    mutations,
    actions,
}