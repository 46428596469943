<script>
import HeadComponent from "@/components/Head"
import FootComponent from '@/components/Foot'
import store from '@/store'

export default {
    data() {
        return {
            email: null,
            password: null,
            c_password: null,
            code: null,
            errors: null,
            step: 1,
            pin1:null,
            pin2:null,
            pin3:null,
            pin4:null,
            pin5:null,
            pin6:null,
        }
    },
    components: {
        HeadComponent,
        FootComponent
    },
    name: 'ChangepassComponent',
    mounted() {
        document.title =  'changer de mot de passe'
        
    },
    methods: {
        modifypassword(){
            if(this.password.length>=8)
            {
            if (this.password == this.c_password)
            {
            store.dispatch('auth/modifypassword', { 'password': this.password })
                .then(response => {
                    console.log(response)
          
                    window.location.replace('/')
                })
                .catch(error => {
                    if (error.response) {
                        // La requête a été effectuée, mais le serveur a répondu avec un statut autre que 2xx
                        console.error('Réponse du serveur avec erreur :', error.response.data)

                        this.errors = error.response.data.errors
                    } else if (error.request) {
                        // La requête a été effectuée, mais aucune réponse n'a été reçue
                        console.error('Pas de réponse du serveur :', error.request)
                        this.errors = 'Pas de réponse du serveur.'
                    } else {
                        // Une erreur s'est produite lors de la configuration de la requête
                        console.error('Erreur lors de la configuration de la requête :', error.message)
                        this.errors = 'Erreur lors de la configuration de la requête.'
                    }
                })
            }else
            {
                this.errors='les deux mots de passe doivent ètre identique'
            }
        }else
            {
                this.errors='Pour des raisons de sécurité, votre mot de pass doit contenir au moin 8 caractere'
            }

        },
    },


}
</script>
<template>
    <HeadComponent />
    <main>
        <!-- login Area Strat-->
        <section class="login-area pt-10 pb-10">
            <div class="container">

                <div class="row">
                    <div class="col-lg-9 offset-lg-2">
                        <div v-if="errors" class="alert alert-danger alert-dismissible fade show" role="alert">
                            <div class="alert-heading">
                                <h4 class="alert-title">ERREUR</h4>
                            </div>
                            <p>{{ errors }}</p>
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        
                        <div  class="basic-login">
                            <h3 class="text-center mb-20">Modifier votre mot de passe</h3>
                            <form @submit.prevent="modifypassword">

                                <label for="name">Password <span>**</span></label>
                                <input v-model="password" id="name" type="password" required />
                                <label for="name">confirmer Password <span>**</span></label>
                                <input v-model="c_password" id="name" type="password"  required />
                                <button type="submit" class="btn theme-btn-2 w-100 text-light">Send code</button>
                                <div class="or-divide"><span>or</span></div>
                                <a href="/login" class="btn theme-btn w-100 text-light">Login Now</a>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- login Area End-->


    </main>


    <FootComponent />
</template>
