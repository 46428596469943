import { createStore } from 'vuex'
import auth from './User/auth'
import proprieter from './Proprieter/proprieter'
import categorie from './Categorie/categorie'
import banniere from './Banniere/banniere'
import pub from './Pub/pub'
import article from './Article/article'

export default createStore({
    modules: {
        auth,
        proprieter,
        categorie,
        banniere,
        pub,
        article
    },

})
