<script>
import HeadComponent from "@/components/Head"
import FootComponent from '@/components/Foot'
import store from '@/store';
import $ from "jquery";
import 'select2';
import 'select2/dist/css/select2.min.css';
import Swal from 'sweetalert2';


export default {
    components: {
        HeadComponent,
        FootComponent,
    },
    name: 'RegisterComponent',
    data() {
        return {
            email: null,
            password: null,
            c_password: null,
            nom: null,
            prenom: null,
            loading:false,
            type: "simple",
            agence: null,
            url: null,
            photo: null,
            pays: 'BJ',
            addresse: null,
            numero_appel: "",
            numero_whatsapp: "",
            errors: null,
            country: null,
            showPassword:false
        }
    },
    mounted() {
        const $select = $(this.$refs.mySelect);

        document.title =  'inscription'
        $select.select2();

        $select.on('change', (event) => {
            // Gérez ici les changements de valeur de Select2
            this.pays = event.target.value;
        });

        // Écoutez l'événement de changement de Select2 et mettez à jour le modèle Vue

        store.dispatch('categorie/getcountry')
            .then(response => {
                this.country = response

            })
            .catch(error => {
                console.log(error)
            })


    },
    methods: {
        handleFileChange(event) {
            // Le fichier sélectionné est accessible via event.target.files
            const selectedFile = event.target.files[0];

            // Mettez à jour le modèle avec les informations sur le fichier
            this.photo = selectedFile;

            // Vous pouvez effectuer d'autres actions avec le fichier ici
        },
        changeappel(newvalue) {

            this.numero_appel = newvalue

        },
        changewhatsapp(newvalue) {
            this.numero_whatsapp = newvalue
        },

        togglePasswordVisibility() {
            this.showPassword = !this.showPassword;
        },
        ajouturl()
        {
            if(this.url.includes('http://') ||this.url.includes('https://'))
            {
                console.log('ok')
            }
            else
            {
                this.url='http://'+this.url
            }
            if(this.url=='')
            {this.url=null

            }
        },
        register() {
            this.errors = null
            if (this.password < 8) {
                this.errors = 'Pour des raisons de sécurité, votre mot de pass doit contenir au moin 8 caractere'

            }
            else if (this.password != this.c_password) {

                this.errors = 'Les mots de passe doivent etre identique'
            } else {
            this.loading=true
                const formData = new FormData()

                formData.append('email', this.email)
                formData.append('username', this.email)
                formData.append('password', this.password)
                formData.append('photo', this.photo)
                formData.append('pays', this.pays)
                formData.append('addresse', this.addresse)
                formData.append('role', this.type)
                formData.append('whatsapp', this.numero_whatsapp)

                if (this.url!=null && this.url!='') {

                    formData.append('url', this.url)


                }

                if (this.type != "simple") {

                    formData.append('entreprise', this.agence)


                }
                else {

                    formData.append('first_name', this.nom)
                    formData.append('last_name', this.prenom)

                }
                store.dispatch('auth/register', formData).then(response => {
this.loading=false
                    Swal.fire({
                        title: "Succès",
                        text: response.message,
                        icon: "success",
                        cancelButtonColor: "#f46a6a",
                        confirmButtonColor: "#34c38f",
                        confirmButtonText: "OK",
                    }).then(() => {
                        localStorage.setItem('email', this.email)
                        window.location.replace('/rpin')
                    })
                }).catch(error => {
                    this.loading=false
                    if (error.response) {
                        // La requête a été effectuée, mais le serveur a répondu avec un statut autre que 2xx
                        console.error('Réponse du serveur avec erreur :', error.response.data)

                        this.errors = error.response.data.errors
                    } else if (error.request) {
                        // La requête a été effectuée, mais aucune réponse n'a été reçue
                        console.error('Pas de réponse du serveur :', error.request)
                        this.errors = 'Pas de réponse du serveur.'
                    } else {
                        // Une erreur s'est produite lors de la configuration de la requête
                        console.error('Erreur lors de la configuration de la requête :', error.message)
                        this.errors = 'Erreur lors de la configuration de la requête.'
                    }
                })
            }


        }

    }
}
</script>
<template>
    <HeadComponent />

    <main>
        <section class="login-area pt-10 pb-10">
            <div class="container">
                <div class="row">
                    <div class="col-lg-9 offset-lg-2">
                        <div v-if="errors" class="alert alert-danger alert-dismissible fade show" role="alert">
                            <div class="alert-heading">
                                <h4 class="alert-title">ERREUR</h4>
                            </div>
                            <p>{{ errors }}</p>
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="basic-login">
                            <h3 class="text-center">Inscrivez-vous ici</h3>
                            <form @submit.prevent="register">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="checkout-form-list">
                                            <label>Type d'utilisateur <span class="required">*</span></label>

                                            <select required class="form-control" v-model="type">
                                                <option value="simple">Particulier</option>
                                                <option value="agence">Agences</option>
                                            </select>
                                        </div>

                                    </div>
                                    <div v-if="type == 'simple'" class="col-md-6">
                                        <div class="checkout-form-list">
                                            <label>Nom <span class="required">*</span></label>
                                            <input type="text" v-model="nom" placeholder="Votre Nom" required />
                                        </div>
                                    </div>
                                    <div v-if="type == 'simple'" class="col-md-6">
                                        <div class="checkout-form-list">
                                            <label>Prénom(s) <span class="required">*</span></label>
                                            <input type="text" v-model="prenom" placeholder="Prénoms" required />
                                        </div>
                                    </div>
                                    <div class="col-md-6" v-if="type == 'agence'">
                                        <div class="checkout-form-list">
                                            <label>Nom de l'agence immobilière </label>
                                            <input type="text" v-model="agence" required placeholder="Nom de l'agence" />
                                        </div>
                                    </div>

                                    <div class="col-md-6" v-if="type == 'agence'">
                                        <div class="checkout-form-list">
                                            <label>Site web de l'agence immobilière </label>
                                            <input style="height: 45px;" type="text" v-model="url" @change="ajouturl()" value='http://'
                                                placeholder="http://siteweb.com" />
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="checkout-form-list">
                                            <label>Photo de profil ou logo de l'agence <span>*</span></label>
                                            <input @change="handleFileChange" style="height: 45px;" type="file"
                                                placeholder="Photo" required />
                                        </div>
                                    </div>
                                    <div class="col-md-6 ">
                                        <div class="checkout-form-list country-select">
                                            <label>Pays <span class="required">*</span></label>
                                            <select required ref="mySelect" v-model="pays">
                                                <option v-for="countrie in country" :key="countrie.iso"
                                                    :value="countrie.iso">{{ countrie.name }}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="checkout-form-list">
                                            <label>Addresse <span class="required">*</span></label>
                                            <input type="text" v-model="addresse" placeholder="Cotonou, Akpakpa"
                                                required />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="checkout-form-list">
                                            <label>Email <span>*</span></label>
                                            <input v-model="email" type="text" placeholder="exemple@gmail.com" />
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="checkout-form-list">
                                            <label>Numero de téléphone whatsapp <span>*</span></label>
                                            <input v-model="numero_whatsapp" type="tel">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="checkout-form-list">
                                            <label for="pass">Password <span>*</span></label>

                                            <div class="password-input-container">
                                                <input id="pass" v-model='password' :type="showPassword ? 'text' : 'password'"
                                                    placeholder="Mot de passe" />
                                                    <a class='button' @click="togglePasswordVisibility">
                                        <i class="far fa-eye"></i>
                                    </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="checkout-form-list">
                                            <label for="pass">Confirmer Password <span>*</span></label>


                                            <div class="password-input-container">
                                                <input id="pass"  v-model='c_password' :type="showPassword ? 'text' : 'password'"
                                                    placeholder="Mot de passe" />
                                                    <a class='button' @click="togglePasswordVisibility">
                                        <i class="far fa-eye"></i>
                                    </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-10"></div>
                                <div v-if='loading' class="d-flex justify-content-center align-items-center" style="height: 100vh;">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Chargement...</span>
    </div>
  </div>
                                <button  v-else class="btn theme-btn-2 w-100 text-light">Enregistrer</button>
                                <div class="or-divide"><span>or</span></div>
                                <a href="/login" class="btn theme-btn w-100 text-light">Connexion</a>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- login Area End-->


    </main>
    <FootComponent />
</template>

<style scoped>
.password-input-container {
    display: flex;
    align-items: center;
}

/* Styles spécifiques à votre icône ou composant SVG */
.button {
    background-color: transparent;
    border: none;
    font-size: medium;
    cursor: pointer;
    margin-left: 5px;
    /* Espacement entre le champ de saisie et le bouton */
}
</style>