<script>
import AsideComponent from "@/components/Asideadmin"
import HeadComponent from "@/components/Headadmin"
import FootComponent from "@/components/Footadmin"

import store from "@/store"

import Swal from 'sweetalert2'
export default {

    components: {
        HeadComponent,
        AsideComponent,
        FootComponent
    },
    data() {

        return {
            mod: null,
            bannieres: null,
            user: null,
            demandes: null,
            role: null,
            
            nom: null,
            date_debut:null,
            date_fin:null,
            lien:null
        }
    },
    name: 'BanniereComponent',
    mounted() {
        this.getbanniere()
        document.title =  'banniere'
        store.dispatch('auth/_currentUser')
            .then(() => {
                var currentuser = store.getters['auth/getCurrentUser'];
                this.user = currentuser.id
                this.role = currentuser.role
            })
            .catch(error => {
                console.log(error)
            })

    },
    methods: {
        handleFileChange(event) {
            // Le fichier sélectionné est accessible via event.target.files
            let selectedFile = event.target.files[0];

            // Mettez à jour le modèle avec les informations sur le fichier
            this.nom = selectedFile;

            // Vous pouvez effectuer d'autres actions avec le fichier ici
        },
        getbanniere() {
            store.dispatch('banniere/getbanniere')
                .then(response => {
                    console.log(response)
                    this.bannieres = response
                    console.log(response)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        modif(id,debut,fin,lien) {
            this.mod = id
            this.date_debut=debut
            this.date_fin=fin
            this.lien=lien
        },

        modBanniere() {
            
            let formData = new FormData();
            if(this.nom)
            {formData.append("image", this.nom);}
      
            formData.append("id", this.mod);
      formData.append("lien", this.lien);
      formData.append("date_debut", this.date_debut);
      formData.append("date_fin", this.date_fin);
            store.dispatch('auth/_currentUser')
                .then(() => {
                    store.dispatch('banniere/modBanniere', formData)
                        .then(response => {
                            Swal.fire({
                                title: "Succès",
                                text: response.message,
                                icon: "success",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            }).then(() => {
                                window.location.reload()
                            })

                        })
                        .catch(error => {
                            Swal.fire({
                                title: "Erreur",
                                text: error.data.errors,
                                icon: "error",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            })
                        })
                })
                .catch(error => {
                    console.error("Erreur lors de la récupération de l'utilisateur", error);
                }
                );
        },
        supBanniere(id) {

            Swal.fire({
                title: "Question",
                text: "Voulez vous supprimer cette banniere ?",
                icon: "question",
                showCancelButton: true,
                cancelButtonColor: "#f46a6a",
                confirmButtonColor: "#34c38f",
                confirmButtonText: "Oui",
                cancelButtonText: 'Non',
            }).then(async result => {
                if (result.value) {

                    store.dispatch('auth/_currentUser')
                        .then(() => {
                            store.dispatch('banniere/supBanniere', { 'id': id })
                                .then(response => {
                                    Swal.fire({
                                        title: "Succès",
                                        text: response.message,
                                        icon: "success",
                                        cancelButtonColor: "#f46a6a",
                                        confirmButtonColor: "#34c38f",
                                        confirmButtonText: "OK",
                                    }).then(() => {
                                        window.location.reload()
                                    })

                                })
                                .catch(error => {
                                    console.log(error)
                                })
                        })
                        .catch(error => {
                            Swal.fire({
                                title: "Erreur",
                                text: error.data.errors,
                                icon: "error",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            })
                        })
                }
            })
        },
        formatDate(dateString) {
            const options = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false,
                timeZone: 'UTC',
            };
            const date = new Date(dateString);
            return date.toLocaleDateString('en-US', options);
        },
        addBanniere() { 
            let formData = new FormData();
      formData.append("image", this.nom);
      formData.append("lien", this.lien);
      formData.append("date_debut", this.date_debut);
      formData.append("date_fin", this.date_fin);
            store.dispatch('auth/_currentUser')
                .then(() => {
                    store.dispatch('banniere/addBanniere', formData)
                        .then(response => {
                            Swal.fire({
                                title: "Succès",
                                text: response.message,
                                icon: "success",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            }).then(() => {
                                window.location.reload()
                            })
                            
                        })
                        .catch(error => {
                            Swal.fire({
                                title: "Erreur",
                                text: error,
                                icon: "error",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            })
                        })
                })
                .catch(error => {
                    console.error("Erreur lors de la récupération de l'utilisateur", error);
                }
                );
        }
    }
}

</script>

<template>
    <body class="g-sidenav-show  bg-gray-200" id="body">
        <AsideComponent />

        <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
            <!-- Navbar -->
            <HeadComponent />
            <!-- End Navbar -->
            <div class="container-fluid">
                <div class="page-header justify-center align-items-center">
                    <div class="container ">
                                <div class="card ">
                                    <div class="card-body">

                                        <form v-if="mod" role="form" class="text-start" @submit.prevent="modBanniere">
                                            <div class="row g-3 input-group input-group-outline">
                                                <div class="col-sm-6">
                                                    <label>image</label>
                                                    <input type="file" class="form-control" @change="handleFileChange" name="nom"
                                                        placeholder="Nom de la catégorie">
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>url</label>
                                                    <input type="text" class="form-control" v-model="lien"
                                                        placeholder="lien de la baniere">
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Date début</label>
                                                    <input type="datetime-local" class="form-control" v-model="date_debut"
                                                        placeholder="Date de fin">
                                        
                                                    </div>
                                                <div class="col-sm-6">
                                                    <label>Date fin</label>
                                                    <input type="datetime-local" class="form-control" v-model="date_fin"
                                                        placeholder="date de fin">
                                                </div>
                                                <div class="col-sm">
                                                    <button type="file" class="btn bg-gradient-primary">Modifier</button>
                                                </div>
                                            </div>
                                        </form>
                                        <form v-else role="form" class="text-start" @submit.prevent="addBanniere">
                                            <div class="row g-3 input-group input-group-outline">
                                                <div class="col-sm-6">
                                                    <label>image</label>
                                                    <input type="file" class="form-control" required @change="handleFileChange" name="nom"
                                                        placeholder="Nom de la catégorie">
                                                </div>
                                                
                                                <div class="col-sm-6">
                                                    <label>url</label>
                                                    <input type="text" class="form-control" v-model="lien"
                                                        placeholder="lien de la baniere">
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Date début</label>
                                                    <input type="datetime-local" class="form-control" v-model="date_debut"
                                                        placeholder="Date de fin">
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Date fin</label>
                                                    <input type="datetime-local" class="form-control" v-model="date_fin"
                                                        placeholder="date de fin">
                                                </div>
                                                <div class="col-sm">
                                                    <button type="submit"
                                                        class="btn bg-gradient-primary">Enregistrer</button>
                                                </div>
                                            </div>
                                        </form>
                                        <center>
                                            <table class="table table-responsive align-items-center justify-content-center">
                                                <thead>
                                                    <tr>
                                                        <th>Image</th>
                                                        <th>nombre de vue</th>
                                                        <th>nombre de click</th>
                                                        <th>Date de début</th>
                                                       
                                                        <th>Date de fin</th>
                                                        <th>lien</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="cat in bannieres" :key="cat.id">
                                                        <td> <div>
                                                            <img :src="cat.image"
                                                                class="avatar avatar-sm rounded-circle me-2" alt="xd">
                                                        </div></td>
                                                        <td>{{cat.nb_vu}} </td>
                                                        <td>{{cat.nb_click}} </td>
                                                        <td>{{formatDate(cat.date_debut ) }}</td>
                                                        <td>{{ formatDate(cat.date_fin) }}</td>
                                                       
                                                        <td>{{cat.lien}} </td>
                                                        <td><button @click="modif(cat.id,cat.date_debut,cat.date_fin,cat.lien )"
                                                                class="btn bg-gradient-primary">Modifier</button>

                                                            <button @click="supBanniere(cat.id)" class="btn btn-danger"><i
                                                                    class="material-icons">delete</i></button>

                                                        </td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </center>
                                    </div>
                                </div>
                            
                    </div>
                </div>
            </div>

            <FootComponent />

        </main>
    </body>
</template>