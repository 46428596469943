import axios from 'axios'
import axiosWithHeaders, { BASE_API_URL } from '@/services/api'

const state = {}
const getters = {}
const mutations = {}
const actions={

    addPub({commit}, data){
        commit=null
        console.log(commit)     
        return new Promise((resolve, reject) => {
            axiosWithHeaders.post(BASE_API_URL + 'pubs/add',data)
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })
    },
    modPub({commit}, data){
        commit=null
        console.log(commit)     
        return new Promise((resolve, reject) => {
            axiosWithHeaders.put(BASE_API_URL + 'pub_update',data)
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })
    },
    
    supPub({commit}, data){
        commit=null
        console.log(commit)     
        return new Promise((resolve, reject) => {
            axiosWithHeaders.delete(BASE_API_URL + 'pub/'+data.id+'/delete')
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })
    },
    getpub() {
        return new Promise((resolve, reject) => {
            axios.get(BASE_API_URL + 'pubs')
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })
    },
    
    update_click_Pub({commit}, data){
        commit=null
        console.log(commit)     
        return new Promise((resolve, reject) => {
            axios.get(BASE_API_URL + 'update_click_Pub/'+data.id)
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })
    },
    get_random_pub()
    {return new Promise((resolve, reject) => {
        axios.get(BASE_API_URL + 'get_random_pub')
           .then(response => {
                resolve(response.data)
            })
           .catch(error => {
                reject(error)
            })
    })
           }
    

}

export default {
    namespaced:true,
    state,
    getters,
    mutations,
    actions,
}