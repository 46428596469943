<script>
import HeadComponent from "@/components/Head"
import FootComponent from '@/components/Foot'
import store from "@/store"
import Swal from 'sweetalert2';

export default {
    data() {
        return {
            type: null,
            titre: "Blog immobilier",

            id: null,
            validuser: false,
            articles: [],
            categories: null,
            currentPage: 1, // Page actuelle
            itemsPerPage: 6,
            cat_fil: null,

        }
    }
    ,
    components: {
        HeadComponent,
        FootComponent
    },
    name: 'SearchComponent',
    mounted() {
        this.proprie()

        this.getcategorie()



        store.dispatch('auth/_currentUser')
            .then(() => {

                this.validuser = true
                console.log(this.validuser)
            })
            .catch(error => {
                console.log(error)

            })
    },
    computed: {
        totalPages() {
            return Math.ceil(this.articles.length / this.itemsPerPage);
        },
        paginatedarticles() {
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;
            return this.articles.slice(startIndex, endIndex);
        },
    },
    methods: {
        // Méthode pour changer de page

        formatMonetaryValue(value) {
            // Convertit le nombre en chaîne de caractères
            let formattedValue = String(value);

            // Vérifie si la valeur est supérieure à 3 chiffres
            if (formattedValue.length > 3) {
                // Détermine le nombre de groupes de trois chiffres
                const groups = Math.floor((formattedValue.length - 1) / 3);

                // Initialise l'index pour l'insertion de l'espace de mille
                let index = formattedValue.length - 3;

                // Boucle pour insérer les espaces de mille
                for (let i = 0; i < groups; i++) {
                    formattedValue = formattedValue.slice(0, index) + ' ' + formattedValue.slice(index);
                    index -= 3;
                }
            }

            // Retourne la valeur formatée
            return formattedValue;
        },
        setPage(pageNumber) {
            this.currentPage = pageNumber;
            window.location.href = '#vupro'

        }, getcategorie() {
            store.dispatch('article/getcategorie')
                .then(response => {
                    console.log(response)
                    this.categories = response
                })
                .catch(error => {
                    console.log(error)
                })
        },
        hasard(fin) {
            return Math.floor(Math.random() * fin)
        },
        formatDate(dateString) {
            const options = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false,
                timeZone: 'UTC',
            };
            const date = new Date(dateString);
            return date.toLocaleDateString('en-US', options);
        },
        proprie() {
            const urlParams = new URLSearchParams(window.location.search);
            this.type = urlParams.get('type');
            this.id = urlParams.get('id');
            if (this.type == 'search') {
                this.titre = "RECHERCHE:" + this.id
                document.title = "RECHERCHE: " + this.id
            }

            document.title = this.titre
            store.dispatch('article/articlesearch', { 'type': this.type, 'id': this.id })
                .then(response => {
                    console.log(response)
                    this.articles = response
                    if (this.type == 'search') {
                        this.titre = "RECHERCHE:" + this.id
                    }
                    else if (this.type == 'categorie') {
                        this.titre = "CATEGORIE: " + response[0].categorie.nom

                    }
                    else if (this.type == 'user') {
                        if (response[0].user.entreprise) {
                            this.titre = "UTILISATEUR: " + response[0].user.entreprise
                        }
                        else {
                            this.titre = "UTILISATEUR: " + response[0].user.first_name + ' ' + response[0].user.last_name
                        }
                    }

                    document.title = this.titre
                })
                .catch(error => {
                    console.log(error)
                })

        },
        triearticle(trie) {
            if (trie === 'all') {
                // Si trie est 'all', afficher tous les éléments en réinitialisant les filtres
                this.proprie()

            } else if (trie === 'filtre') {

                const formData = new FormData()
                if (this.id != null) {
                    formData.append('id', this.id)

                }
                formData.append('type', this.type)



                if (this.cat_fil != null) {

                    formData.append('cat_fil', this.cat_fil)

                }


                store.dispatch('article/articlefilter', formData)
                    .then(response => {
                        this.articles = response

                        window.location.href = '#vupro'

                    }).catch(error => {
                        console.log(error)
                        Swal.fire({
                            title: "Erreur",
                            text: "Aucun resultat trouver",
                            icon: "error",
                            cancelButtonColor: "#f46a6a",
                            confirmButtonColor: "#34c38f",
                            confirmButtonText: "OK",
                        })

                    })

            }
        },
    }
}
</script>

<template>
    <HeadComponent />
    <main>
        <section class="latest-blog-area pt-95 pb-60 box-90">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="area-title mb-50">
                            <h3>
                                <center>{{ titre }}</center>
                            </h3>

                        </div>

                    </div>


                </div>


                <div class="area-title mb-50">
                    <form @submit.prevent="triearticle('filtre')">
                        <center>

                            <div class="checkout-form-list country-select">
                                <label>Categorie </label>
                                <select v-model="cat_fil" class="form-control">
                                    <option>choisir une categorie</option>
                                    <option v-for="cat in categories" :key="cat.id" :value="cat.id">{{
                                        cat.nom }}
                                    </option>

                                </select>

                            </div>
                            <button type="submit" class="btn theme-btn-2 text-light">Lancer filtre</button>
                            <button type="reset" @click="triearticle('all')" class=" bg-dark text-light"><i
                                    class="fa fa-refresh"></i> </button>

                        </center>
                    </form>

                </div>
                <div class="row" v-if="articles" id="vupro">

                    <div v-for="article in paginatedarticles" :key="article.id"
                        class="col-md-6 col-lg-4 mt-5 wow fadeInUp" data-wow-delay=".2s"
                        style="visibility: visible; animation-delay: 0.2s; animation-name: fadeInUp;">
                        <div class="blog-grid">
                            <div class="blog-grid-img position-relative"><img v-if="article.a_image[0]"
                                    :src="article.a_image[hasard(article.a_image.length)].image" alt=""
                                    style='width:550px; height:350px'></div>
                            <div class="blog-grid-text p-4">
                                <h3 class="h5 mb-3"><a href="#!">{{ article.titre }}</a></h3>
                                
                                <div v-html="article.description" class="display-30 truncate"></div>
                                <div class="meta meta-style2">
                                    <ul>
                                        <li><a href="#!"><i class="fas fa-calendar-alt"></i> {{
                                            formatDate(article.created_at) }}
                                            </a></li>
                                        <li><a href="#!"><i class="fas fa-heart"></i> {{ article.total_likes }}</a></li>
                                        <li><a href="#!"><i class="fas fa-comments"></i> {{ article.total_comments
                                                }}</a></li>
                                        <li><a :href="'/article_commentaire?id=' + article.id">Voir plus</a></li>
                                    </ul>
                                    <div class="sharethis-inline-share-buttons"
                                        :data-url="'tgvimmobrio.com/article_commentaire?id=' + article.id"
                                        :data-title="article.categorie.nom" :data-description="article.titre"
                                        :data-image="article.a_image[0].image">
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
                <div class="pagination justify-content-center">
                    <ul class="pagination">
                        <li v-for="pageNumber in totalPages" :key="pageNumber" @click="setPage(pageNumber)"
                            class="page-item" :class="{ 'active': pageNumber === currentPage }">
                            <a class="page-link"> {{ pageNumber }} </a>
                        </li>
                    </ul>
                </div>

                <div v-if="!articles" class="alert alert-warning alert-dismissible fade show" role="alert">
                    <div class="alert-heading">
                        <h4 class="alert-title">Alert</h4>
                    </div>
                    <p>Aucun article n'est trouvé</p>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
        </section>


        <FootComponent />

    </main>
</template>
<style>
.about-item h2 {
    font-style: italic;
    font-weight: 900;
    font-size: 1.4em;
}

.about-uss {
    justify-content: center;
}

.about-item i {
    font-size: 2.5em;
    border: 3px solid rgb(205, 127, 50);
    border-radius: 100%;
    padding: 30px;
    color: rgb(205, 127, 50);
    width: 106px;
}

body {
    margin-top: 20px;
}
.display-30 .truncate {
      display: -webkit-box;
      -webkit-line-clamp: 3; /* Limite à 3 lignes */
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
.blog-grid {
    position: relative;
    box-shadow: 0 1rem 1.75rem 0 rgba(45, 55, 75, 0.1);
    height: 100%;
    border: 0.0625rem solid rgba(220, 224, 229, 0.6);
    border-radius: 0.25rem;
    transition: all .2s ease-in-out;
    height: 100%
}

.blog-grid span {
    color: #292dc2
}

.blog-grid img {
    width: 100%;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem
}

.blog-grid-text {
    position: relative
}

.blog-grid-text>span {
    color: #292dc2;
    font-size: 13px;
    padding-right: 5px
}

.blog-grid-text h4 {
    line-height: normal;
    margin-bottom: 15px
}

.blog-grid-text .meta-style2 {
    border-top: 1px dashed #cee1f8;
    padding-top: 15px
}

.blog-grid-text .meta-style2 ul li {
    margin-bottom: 0;
    font-weight: 500
}

.blog-grid-text .meta-style2 ul li:last-child {
    margin-right: 0
}

.blog-grid-text ul {
    margin: 0;
    padding: 0
}

.blog-grid-text ul li {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    margin: 5px 10px 5px 0
}

.blog-grid-text ul li:last-child {
    margin-right: 0
}

.blog-grid-text ul li i {
    font-size: 14px;
    font-weight: 600;
    margin-right: 5px
}

.blog-grid-text p {
    font-weight: 400;
    padding: 0
}

.blog-list-left-heading:after,
.blog-title-box:after {
    content: '';
    height: 2px
}

.blog-grid-simple-content a:hover {
    color: #1d184a
}

.blog-grid-simple-content a:hover:after {
    color: #1d184a
}

.blog-grid-text {
    position: relative
}

.blog-grid-text>span {
    color: #292dc2;
    font-size: 13px;
    padding-right: 5px
}

.blog-grid-text h4 {
    line-height: normal;
    margin-bottom: 15px
}

.blog-grid-text .meta-style2 {
    border-top: 1px dashed #cee1f8 !important;
    padding-top: 15px
}

.blog-grid-text .meta-style2 ul li {
    margin-bottom: 0;
    font-weight: 500
}

.blog-grid-text .meta-style2 ul li:last-child {
    margin-right: 0
}

.blog-grid-text ul {
    margin: 0;
    padding: 0
}

.blog-grid-text ul li {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    margin: 5px 10px 5px 0
}

.blog-grid-text ul li:last-child {
    margin-right: 0
}

.blog-grid-text ul li i {
    font-size: 14px;
    font-weight: 600;
    margin-right: 5px
}

.blog-grid-text p {
    font-weight: 400;
    padding: 0
}

a,
a:active,
a:focus {
    text-decoration: none;
    transition-timing-function: ease-in-out;
    -ms-transition-timing-function: ease-in-out;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    transition-duration: .2s;
    -ms-transition-duration: .2s;
    -moz-transition-duration: .2s;
    -webkit-transition-duration: .2s;
    -o-transition-duration: .2s;
}

.pagination {
    border-radius: 0;
    padding: 0;
    margin: 0
}

.pagination ul {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    margin: 0 auto;
    padding: 0
}

.pagination li {
    display: inline
}

.pagination a {
    float: left;
    padding: 0 18px;
    line-height: 40px;
    text-decoration: none;
    border: 1px solid #dbdbdb;
    border-left-width: 0;
    background: #fff
}

.pagination a:hover {
    background-color: #1d184a;
    color: #fff
}

.pagination .active a {
    background-color: #f7f7f7;
    color: #999;
    cursor: default
}

.pagination .disabled span {
    color: #999;
    background-color: transparent;
    cursor: default
}

.pagination .disabled a {
    color: #999;
    background-color: transparent;
    cursor: default
}

.pagination .disabled a:hover {
    color: #999;
    background-color: transparent;
    cursor: default
}

.pagination li:first-child a {
    border-left-width: 1px
}

.mt-6,
.my-6 {
    margin-top: 3.5rem;
}
</style>