import axios from 'axios'
import axiosWithHeaders,{ BASE_API_URL } from '@/services/api'

const state = {}
const getters = {}
const mutations = {}
const actions={

    proprie() {
        return new Promise((resolve, reject) => {
            axios.get(BASE_API_URL + 'proprieters')
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })
    },
    demandes() {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.get(BASE_API_URL + 'demandes')
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })
    },
    demandes_user({commit},data){
        commit=null
        console.log(commit)     
        return new Promise((resolve, reject) => {
            axiosWithHeaders.post(BASE_API_URL + 'demandes_user'+data.id )
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })

    },
    demandes_spec({commit},data){
        commit=null
        console.log(commit)     
        return new Promise((resolve, reject) => {
            axiosWithHeaders.get(BASE_API_URL + 'demandes/'+data.val )
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })

    },
    vuspecificproprieter({commit},data){
        commit=null
        console.log(commit)     
        return new Promise((resolve, reject) => {
            axiosWithHeaders.get(BASE_API_URL + 'admproprieters/'+data.val )
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })

    },
    admproprie() {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.get(BASE_API_URL + 'admproprieters')
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })
    },
    proprietop() {
        return new Promise((resolve, reject) => {
            axios.get(BASE_API_URL + 'proprieters/top')
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })
    },
    addproprieter({commit}, data) {
        commit=null
        console.log(commit)     
        return new Promise((resolve, reject) => {
            axiosWithHeaders.post(BASE_API_URL + 'proprieters/add' ,data )
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })
    }, proprieter_validation({commit},data)
    {
      commit=null
      console.log(commit)
      return new Promise((resolve, reject) => {
        axiosWithHeaders.post(BASE_API_URL + 'proprieter_validation/'+ data.id+'/'+data.val)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response)
          })
      })
    },
    proprieteinfo({commit}, id) {
        commit=null
        console.log(commit)     
        return new Promise((resolve, reject) => {
            axios.get(BASE_API_URL + 'proprieter/' + id.id)
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })
    },
    updateproprieter ({commit}, data) {
        commit=null
        console.log(commit)     
        return new Promise((resolve, reject) => {
            axiosWithHeaders.put(BASE_API_URL + 'proprieter_update',data)
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })
    },
    deleteimage({commit}, id) {
        commit=null
        console.log(commit)     
        return new Promise((resolve, reject) => {
            axiosWithHeaders.delete (BASE_API_URL + 'deleteimage/'+ id.id)
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                console.log(error)
                    reject(error)
                })
        })
    },
    deleteproprieter({commit}, id) {
        commit=null
        console.log(commit)     
        return new Promise((resolve, reject) => {
            axiosWithHeaders.delete (BASE_API_URL + 'proprieter/'+ id.id+'/delete')
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })
    },
    update_proprieter_top({commit},data)
    {
      commit=null
      console.log(commit)
      return new Promise((resolve, reject) => {
        axiosWithHeaders.get(BASE_API_URL + 'update_proprieter_top/'+ data.id)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response)
          })
      })
    },
    proprieterfilter({commit},data)
    {
      commit=null
      console.log(commit)
      return new Promise((resolve, reject) => {
        axios.post(BASE_API_URL + 'proprieterfilter', data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response)
          })
      })
    },
    proprietercommentaires({commit}, id) {
        commit=null
        console.log(commit)     
        return new Promise((resolve, reject) => {
            axios.get(BASE_API_URL + 'commentaires/' + id.id)
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })
    },
    
    commenter({commit}, data) {
        commit=null
        console.log(commit)     
        return new Promise((resolve, reject) => {
            axiosWithHeaders.post(BASE_API_URL + 'commentaires/add/' + data.id,{'message':data.message})
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })
    },
    proprieter({commit}, data) {
        commit=null
        console.log(commit)     
        return new Promise((resolve, reject) => {
            axiosWithHeaders.get(BASE_API_URL + 'proprieter/' + data.id)
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })
    },
    liker({commit}, data) {
        commit=null
        console.log(commit)     
        return new Promise((resolve, reject) => {
            axiosWithHeaders.post(BASE_API_URL + 'likes/add/' + data.id)
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })
    },
    proprietersearch({commit },data){
        let id
        commit=null
        console.log(commit)
        console.log(data)
        if(data.id)
        {
            id=data.id
        }
        else
        {
            id=''
        }
        return new Promise((resolve, reject) => {
            if(data.id)
            {
            axios.get(BASE_API_URL + 'proprietersearch/'+data.type+'/'+id)
            .then(response => {
                resolve(response.data)
            })
           .catch(error => {
                reject(error)
            })


        }
        else 
        {
            axios.get(BASE_API_URL + 'proprietersearch/'+data.type)
            .then(response => {
                resolve(response.data)
            })
           .catch(error => {
                reject(error)
            })
    


        }
          
    })
    }
}

export default {
    namespaced:true,
    state,
    getters,
    mutations,
    actions,
}