<script>
import HeadComponent from "@/components/Head"
import FootComponent from '@/components/Foot'
import store from '@/store'
import Swal from 'sweetalert2';

import { useMeta } from 'vue-meta';
export default {
    data() {
        return {
            articleID: null,
            propinfo: [],
            message: null,
            commpro: null,
            categories: null,
            validuser: null,
            currency_code: null,
            search: null,
            imag: null,
            cat: null,
        }
    },

    components: {
        HeadComponent,
        FootComponent
    },
    name: 'CommentaireComponent',

    methods: {

        commenter() {
            store.dispatch('auth/_currentUser')
                .then(() => {
                    store.dispatch('article/commenter', { 'id': this.articleID, 'message': this.message })
                        .then(response => {
                            console.log(response)
                            window.location.reload()
                        })
                        .catch(error => {
                            console.log(error)
                        })
                })
                .catch(error => {
                    console.error("Erreur lors de la récupération de l'utilisateur", error);
                }
                );
        },
        liker() {
            store.dispatch('auth/_currentUser')
                .then(() => {


                    store.dispatch('article/liker', { 'id': this.articleID })
                        .then(response => {
                            console.log(response)
                            Swal.fire({
                                title: "Succès",
                                text: 'Like effectuer avec succès!',
                                icon: "success",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            }).then(() => {
                                window.location.reload()
                            })
                        })
                        .catch(error => {
                            console.log(error)

                            Swal.fire({
                                title: "Erreur",
                                text: error.data,
                                icon: "error",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            })
                        })
                })
                .catch(error => {
                    Swal.fire({
                        title: "Erreur",
                        text: "Erreur" + error + " lors de la récupération de l'utilisateur veuillez vous connecter ou vous inscrire",
                        icon: "error",
                        cancelButtonColor: "#f46a6a",
                        confirmButtonColor: "#34c38f",
                        confirmButtonText: "OK",
                    })
                });

        },
        formatMonetaryValue(value) {
            // Convertit le nombre en chaîne de caractères
            let formattedValue = String(value);

            // Vérifie si la valeur est supérieure à 3 chiffres
            if (formattedValue.length > 3) {
                // Détermine le nombre de groupes de trois chiffres
                const groups = Math.floor((formattedValue.length - 1) / 3);

                // Initialise l'index pour l'insertion de l'espace de mille
                let index = formattedValue.length - 3;

                // Boucle pour insérer les espaces de mille
                for (let i = 0; i < groups; i++) {
                    formattedValue = formattedValue.slice(0, index) + ' ' + formattedValue.slice(index);
                    index -= 3;
                }
            }

            // Retourne la valeur formatée
            return formattedValue;
        },
        articleinfo(id) {
            store.dispatch('article/articleinfo', { 'id': id })
                .then(response => {
                    console.log(response)
                    this.propinfo = response
                    this.cat = this.propinfo.categorie.nom
                    this.imag = this.propinfo.a_image[0].image
                    document.title = this.propinfo.titre


                    useMeta({

                        title: this.cat,
                        meta: [
                            { name: 'description', content: this.propinfo.titre },
                            { property: 'og:title', content: this.cat },
                            { property: 'og:description', content: this.propinfo.titre },
                            { property: 'og:image', content: this.imag },
                            { property: 'og:url', content: `https://tgvimmobrio.com/article_commentaire?id=${this.propinfo.id}` }
                        ]

                    })
                })
                .catch(error => {
                    console.log(error)
                })
        },
        articlecommentaires(id) {
            store.dispatch('article/articlecommentaires', { 'id': id })
                .then(response => {
                    console.log(response)
                    this.commpro = response
                })
                .catch(error => {
                    console.log(error)
                })
        },
        formatDate(dateString) {
            const options = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false,
                timeZone: 'UTC',
            };
            const date = new Date(dateString);
            return date.toLocaleDateString('en-US', options);
        },
        toggleImageSize(event) {
            var clickedImageSrc = event.target.src;
            var img = document.getElementById('image')
            img.src = clickedImageSrc;
        },

        redirect(type, id) {

            if (type == 'pro') {
                this.upcontact_pro(id)

            }
            else if (type == 'us') {
                this.upcontact_us(id)

            }
            alert('Cette action est reservé aux utilisateurs inscrit, Veuillez vous connecter ')

            window.location.replace('/register')

        },
        submitsearch() {

            window.location.replace('/blog?type=search&id=' + this.search)
        }

        ,
        getcategorie() {
            store.dispatch('article/getcategorie')
                .then(response => {
                    console.log(response)
                    this.categories = response
                })
                .catch(error => {
                    console.log(error)
                })
        },
    },
    mounted() {
        const urlParams = new URLSearchParams(window.location.search)

        this.getcategorie()
        this.articleID = urlParams.get('id')
        this.articleinfo(this.articleID)
        this.articlecommentaires(this.articleID)




        store.dispatch('auth/_currentUser')
            .then(() => {
                this.validuser = true
            })
            .catch(error => {
                console.log(error)

            })
    },



}
</script>
<template>
    <HeadComponent />

    <main>

        <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" />
        <div id="main-content" class="blog-page">
            <div class="container">
                <div class="row clearfix">
                    <div class="col-lg-8 col-md-12 left-box">
                        <div class="card single_post">
                            <div class="body">
                                <div class="img-post">
                                    <img class="d-block  img-fluid" v-if="propinfo.a_image && propinfo.a_image[0].image"
                                        id="image" :src="propinfo.a_image[0].image" alt="First slide">
                                </div>
                                <h3><a href="blog-details.html">{{ propinfo.titre }}</a></h3>
                                <div v-html="propinfo.description"></div>
                                <div class="social-profile mt-5">
                                    <a @click="liker" class="text-white"><i class="far fa-heart text-white"></i> {{
                                        propinfo.total_likes }} </a>
                                    <a href='#comm'><i class="fa fa-envelope"></i> {{ propinfo.total_comments }} </a>


                                </div>
                                <div class="sharethis-inline-share-buttons"
                                    :data-url="'tgvimmobrio.com/article_commentaire?id=' + propinfo.id" :data-title="cat"
                                    :data-description="propinfo.titre" :data-image="imag">
                                </div>

                            </div>
                        </div>
                        <div class="card">

                            <div class="body">
                                <ul class="comment-reply list-unstyled">
                                    <li class="row clearfix" v-for="com in commpro" :key="com.id">
                                        <div class="icon-box col-md-2 col-4"><img class="img-fluid img-thumbnail"
                                                :src="com.user.photo" alt="Awesome Image"></div>
                                        <div class="text-box col-md-10 col-8 p-l-0 p-r0">
                                            <h5 class="m-b-0">{{ com.user.email }}</h5>
                                            <p>{{ com.message }} </p>
                                            <ul class="list-inline">
                                                <li><a href="javascript:void(0);">{{ formatDate(com.created_at) }}</a>
                                                </li>

                                            </ul>
                                        </div>
                                    </li>
                                </ul>

                            </div>
                        </div>
                        <div class="card">

                            <div class="body" id="comm">
                                <div class="comment-form">
                                    <form class="row clearfix" @submit.prevent="commenter">

                                        <div class="col-sm-12">
                                            <div class="form-group">
                                                <textarea rows="4" required v-model="message" name="comments"
                                                    id="comments" class="form-control no-resize"
                                                    placeholder="Please type what you want..."></textarea>
                                            </div>
                                            <button type="submit" class="btn btn-block btn-primary">SUBMIT</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12 right-box">
                        <div class="card">
                            <div class="body search">
                                <div class="input-group m-b-0">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" @click="submitsearch"><i
                                                class="fa fa-search"></i></span>
                                    </div>
                                    <input type="text" v-model="search" class="form-control" placeholder="Search...">
                                </div>
                            </div>
                        </div>
                        <div class="card">

                            <div class="body widget">
                                <ul class="list-unstyled instagram-plugin m-b-0">
                                    <li v-for="(image, index) in propinfo.a_image" :key="index"><a
                                            href="javascript:void(0);"><img :src="image.image" class="small-image"
                                                alt="blog image" @click="toggleImageSize"
                                                style="width:100px ; height: 100px ;"></a></li>

                                </ul>
                            </div>
                        </div>

                        <div class="card">
                            <div class="header">
                                <h2>Categories</h2>
                            </div>
                            <div class="body widget">
                                <ul class="list-unstyled categories-clouds m-b-0">
                                    <li v-for="cat in categories" :key="cat.id"> <a
                                            :href="'/blog?type=categorie&id=' + cat.id">{{ cat.nom }}</a></li>
                                </ul>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>

    </main>

    <FootComponent />
</template>
<style scoped>
.card {
    background: #fff;
    transition: .5s;
    border: 0;
    margin-bottom: 30px;
    border-radius: .55rem;
    position: relative;
    width: 100%;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
}

.card .body {
    color: #444;
    padding: 20px;
    font-weight: 400;
}

.card .header {
    color: #444;
    padding: 20px;
    position: relative;
    box-shadow: none;
}

.single_post {
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.single_post .body {
    padding: 30px
}

.single_post .img-post {
    position: relative;
    overflow: hidden;
    max-height: 500px;
    margin-bottom: 30px
}

.single_post .img-post>img {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    -webkit-transition: -webkit-transform .4s ease, opacity .4s ease;
    transition: transform .4s ease, opacity .4s ease;
    max-width: 100%;
    filter: none;
    -webkit-filter: grayscale(0);
    -webkit-transform: scale(1.01)
}

.single_post .img-post:hover img {
    -webkit-transform: scale(1.02);
    -ms-transform: scale(1.02);
    transform: scale(1.02);
    opacity: .7;
    filter: gray;
    -webkit-filter: grayscale(1);
    -webkit-transition: all .8s ease-in-out
}

.single_post .img-post:hover .social_share {
    display: block
}

.single_post .footer {
    padding: 0 30px 30px 30px
}

.single_post .footer .actions {
    display: inline-block
}

.single_post .footer .stats {
    cursor: default;
    list-style: none;
    padding: 0;
    display: inline-block;
    float: right;
    margin: 0;
    line-height: 35px
}

.single_post .footer .stats li {
    border-left: solid 1px rgba(160, 160, 160, 0.3);
    display: inline-block;
    font-weight: 400;
    letter-spacing: 0.25em;
    line-height: 1;
    margin: 0 0 0 2em;
    padding: 0 0 0 2em;
    text-transform: uppercase;
    font-size: 13px
}

.single_post .footer .stats li a {
    color: #777
}

.single_post .footer .stats li:first-child {
    border-left: 0;
    margin-left: 0;
    padding-left: 0
}

.single_post h3 {
    font-size: 20px;
    text-transform: uppercase
}

.single_post h3 a {
    color: #242424;
    text-decoration: none
}

.single_post p {
    font-size: 16px;
    line-height: 26px;
    font-weight: 300;
    margin: 0
}

.single_post .blockquote p {
    margin-top: 0 !important
}

.single_post .meta {
    list-style: none;
    padding: 0;
    margin: 0
}

.single_post .meta li {
    display: inline-block;
    margin-right: 15px
}

.single_post .meta li a {
    font-style: italic;
    color: #959595;
    text-decoration: none;
    font-size: 12px
}

.single_post .meta li a i {
    margin-right: 6px;
    font-size: 12px
}

.single_post2 {
    overflow: hidden
}

.single_post2 .content {
    margin-top: 15px;
    margin-bottom: 15px;
    padding-left: 80px;
    position: relative
}

.single_post2 .content .actions_sidebar {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 60px
}

.single_post2 .content .actions_sidebar a {
    display: inline-block;
    width: 100%;
    height: 60px;
    line-height: 60px;
    margin-right: 0;
    text-align: center;
    border-right: 1px solid #e4eaec
}

.single_post2 .content .title {
    font-weight: 100
}

.single_post2 .content .text {
    font-size: 15px
}

.right-box .categories-clouds li {
    display: inline-block;
    margin-bottom: 5px
}

.right-box .categories-clouds li a {
    display: block;
    border: 1px solid;
    padding: 6px 10px;
    border-radius: 3px
}

.right-box .instagram-plugin {
    overflow: hidden
}

.right-box .instagram-plugin li {
    float: left;
    overflow: hidden;
    border: 1px solid #fff
}

.comment-reply li {
    margin-bottom: 15px
}

.comment-reply li:last-child {
    margin-bottom: none
}

.comment-reply li h5 {
    font-size: 18px
}

.comment-reply li p {
    margin-bottom: 0px;
    font-size: 15px;
    color: #777
}

.comment-reply .list-inline li {
    display: inline-block;
    margin: 0;
    padding-right: 20px
}

.comment-reply .list-inline li a {
    font-size: 13px
}

.blog-page {
    margin: 10px;
}

@media (max-width: 640px) {
    .blog-page .left-box .single-comment-box>ul>li {
        padding: 25px 0
    }

    .blog-page .left-box .single-comment-box ul li .icon-box {
        display: inline-block
    }

    .blog-page .left-box .single-comment-box ul li .text-box {
        display: block;
        padding-left: 0;
        margin-top: 10px
    }

    .blog-page .single_post .footer .stats {
        float: none;
        margin-top: 10px
    }

    .blog-page .single_post .body,
    .blog-page .single_post .footer {
        padding: 30px
    }
}
</style>