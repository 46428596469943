<script>

import AsideComponent from "@/components/Asideadmin"
import HeadComponent from "@/components/Headadmin"
import FootComponent from "@/components/Footadmin"
import store from '@/store';
import $ from "jquery";
import 'select2';
import 'select2/dist/css/select2.min.css';
import Swal from 'sweetalert2';


import  Editor  from '@tinymce/tinymce-vue';

export default {

    components: {
        Editor,
        HeadComponent,
        AsideComponent,
        FootComponent
    },
    data() {

        return {
            caterid: null,
            categories: null,
            description: null,

            titre: null,

            images: null,
            loading: false,
            errors: null,
            editorInit: {
        height: 200,
        menubar: true,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount',
          'emoticons template textcolor colorpicker textpattern',
          'wordcount paste autoresize', // Ajouter des outils pour les mots et l'auto-ajustement
        ],
        toolbar:
          'undo redo | formatselect | bold italic underline strikethrough | fontselect fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | outdent indent | bullist numlist | link image media | table | emoticons | charmap | insertdatetime | code | preview | fullscreen | removeformat',
        menu: {
          file: { title: 'File', items: 'newdocument | preview | print ' },
          edit: { title: 'Edit', items: 'undo redo | cut copy paste | selectall' },
          view: { title: 'View', items: 'visualblocks visualchars | fullscreen | code' },
          insert: { title: 'Insert', items: 'link image media | table | emoticons | template' },
          format: { title: 'Format', items: 'bold italic underline strikethrough | formats | alignleft aligncenter alignright alignjustify | fontselect fontsizeselect | forecolor backcolor' },
          tools: { title: 'Tools', items: 'spellchecker code' },
          table: { title: 'Table', items: 'inserttable | cellprops | tableprops | deletetable' },
          help: { title: 'Help', items: 'help' },
        },
        content_style: 'body { font-family: Arial, sans-serif; font-size: 14px; }', // Style de contenu
      }
    }
},
    name: 'AddComponent',
    mounted() {
        const $select = $(this.$refs.mySelect);
        document.title = 'Ajouter une proprieter'
        $select.select2();

        $select.on('change', (event) => {
            // Gérez ici les changements de valeur de Select2
            this.pays = event.target.value;
        });

        // Écoutez l'événement de changement de Select2 et mettez à jour le modèle Vue
        this.getcategorie()
        store.dispatch('categorie/getcountry')
            .then(response => {
                this.country = response

            })
            .catch(error => {
                console.log(error)
            })


    },
    methods: {


        handleFileChange(event) {
            // Le fichier sélectionné est accessible via event.target.files
            const selectedFile = event.target.files;

            // Mettez à jour le modèle avec les informations sur le fichier
            this.images = selectedFile;

            // Vous pouvez effectuer d'autres actions avec le fichier ici
        },
        getcategorie() {
            store.dispatch('article/getcategorie')
                .then(response => {
                    console.log(response)
                    this.categories = response
                })
                .catch(error => {
                    console.log(error)
                })
        },
        save() {
            this.loading = true
            const formData = new FormData()
            formData.append('categorie', this.caterid)
            formData.append('description', this.description)
            formData.append('titre', this.titre)

            for (var i = 0; i < this.images.length; i++) {

                formData.append('images', this.images[i])

            }



            store.dispatch('article/addarticle', formData).then(response => {
                this.loading = false
                Swal.fire({
                    title: "Succès",
                    text: response.message,
                    icon: "success",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonColor: "#34c38f",
                    confirmButtonText: "OK",
                }).then(() => {
                    window.location.replace('/adm_article')
                })

            }).catch(error => {
                this.loading = false
                if (error.response) {
                    // La requête a été effectuée, mais le serveur a répondu avec un statut autre que 2xx
                    console.error('Réponse du serveur avec erreur :', error.response.data)

                    this.errors = error.response.data.errors
                } else if (error.request) {
                    // La requête a été effectuée, mais aucune réponse n'a été reçue
                    console.error('Pas de réponse du serveur :', error.request)
                    this.errors = 'Pas de réponse du serveur.'
                } else {
                    // Une erreur s'est produite lors de la configuration de la requête
                    console.error('Erreur lors de la configuration de la requête :', error.message)
                    this.errors = 'Erreur lors de la configuration de la requête.'
                }
            })

        }
    }
}

</script>
<template>

    <body class="g-sidenav-show  bg-gray-200" id="body">
        <AsideComponent />

        <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
            <!-- Navbar -->
            <HeadComponent />
            <!-- End Navbar -->
            <div class="container-fluid ">
                <section>
                    <div class="page-header">
                        <div class="card container">
                            <div class="row">

                                <div class="card card-plain">
                                    <div class="card-header">
                                        <h4 class="font-weight-bolder">Enregistrer un article</h4>
                                        <div v-if="errors" class="alert alert-danger alert-dismissible fade show"
                                            role="alert">
                                            <div class="alert-heading">
                                                <h4 class="alert-title">ERREUR</h4>
                                            </div>
                                            <p>{{ errors }}</p>
                                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <form @submit.prevent="save">
                                            <div class="row">
                                                <div class="input-group input-group-outline mb-3 col-md-6  ">
                                                    <label class="form-label">Titre</label>
                                                    <input type="text" v-model="titre" class="form-control">
                                                </div>

                                                <div class="input-group input-group-outline mb-3 col-md-6">
                                                    <label class="form-label"
                                                        :class="{ 'active': caterid }">Categorie</label>
                                                    <select class="form-control" v-model="caterid"
                                                        aria-label="Default select example">
                                                        <option v-for="cat in categories" :key="cat.id" :value="cat.id">
                                                            {{
                                                                cat.nom }}</option>
                                                    </select>
                                                </div>


                                                <div class="input-group input-group-outline mb-3 col-md-6">

                                                    <input type="file" @change="handleFileChange" style="height: 45px;"
                                                        multiple class="form-control">
                                                </div>

                                                <editor  api-key="yq9iu126ilcqez6o65pxe5eqij3fmmbm5sdyciqvlcvbva41" v-model="description" :init="editorInit" />

                                                <div class="text-center">
                                                    <div v-if='loading'
                                                        class="d-flex justify-content-center align-items-center"
                                                        style="height: 100vh;">
                                                        <div class="spinner-border" role="status">
                                                            <span class="visually-hidden">Chargement...</span>
                                                        </div>
                                                    </div>
                                                    <button v-else type="submit"
                                                        class="btn btn-lg bg-gradient-primary btn-lg w-100 mt-4 mb-0">
                                                        Enregistrer
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <FootComponent />
            </div>
        </main>
    </body>
</template>
<style>
.form-label.active {
    visibility: hidden;
}

.quill-editor {
    min-height: 200px;
    border: 1px solid #ccc;
    padding: 10px;
}
</style>