<script>
import AsideComponent from "@/components/Asideadmin"
import HeadComponent from "@/components/Headadmin"
import FootComponent from "@/components/Footadmin"
import Swal from 'sweetalert2';
import store from '@/store'
export default {

    components: {
        HeadComponent,
        AsideComponent,
        FootComponent
    },
    data() {

        return {
            articles: [],
            prop: [],
            
            categories: null,
            nbr_piece: null,
            nbr_parcelle: null,
            nbr_hectare: null,
            localiter: null,
            max_prix: null,
            cat_fil: null,
            currentPage: 1, // Page actuelle
            itemsPerPage: 6,
            role: null
        }
    },
    name: 'articleComponent',
    mounted() {
        this.proprie()
        this.getcategorie()
        document.title =  'article'
        store.dispatch('auth/_currentUser')
            .then(() => {
                var currentUser = store.getters['auth/getCurrentUser'];
                this.role = currentUser.role
            })
            .catch(error => {
                console.error("Erreur lors de la récupération de l'utilisateur", error);

                window.location.replace('/')

            });

    },
    computed: {
        totalPages() {
            return Math.ceil(this.articles.length / this.itemsPerPage);
        },
        paginatedarticles() {
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;
            return this.articles.slice(startIndex, endIndex);
        },
    },
    methods: {
        setPage(pageNumber) {
            this.currentPage = pageNumber;

            window.location.href = '#vupro'
        },
        hasard(fin) {
            return Math.floor(Math.random() * fin)
        },
        proprie() {
            store.dispatch('article/admarticle')
                .then(response => {
                    this.articles = response

                })
                .catch(error => {
                    console.log(error)
                })
        },
        article_validation(id,val){
            Swal.fire({
    title: "Question",
    text: "Voulez vous valider/rejeter ce article ?",
    icon: "question",
    showCancelButton: true,
    cancelButtonColor: "#f46a6a",
    confirmButtonColor: "#34c38f",
    confirmButtonText: "Oui",
    cancelButtonText: 'Non',
  }).then(async result => {
    if (result.value) {
            store.dispatch('article/article_validation',{'id':id,'val':val})
                .then(response => { Swal.fire({
                                title: "Succès",
                                text: response.message,
                                icon: "success",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            }).then(() => {
                                window.location.reload()
                            })

                })
                .catch(error => {
                    Swal.fire({
                                title: "Erreur",
                                text: error.data.errors,
                                icon: "error",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            })
                })
            }
            })
        },
        vuspecificarticle(val){
            store.dispatch('article/vuspecificarticle',{'val':val})
                .then(response => {
                    this.articles = response





                })
                .catch(error => {
                    console.log(error)
                })
        },
        update_article_top(id) {
            Swal.fire({
                title: "Question",
                text: "Voulez vous modifier l'importance de cette article ?",
                icon: "question",
                showCancelButton: true,
                cancelButtonColor: "#f46a6a",
                confirmButtonColor: "#34c38f",
                confirmButtonText: "Oui",
                cancelButtonText: 'Non',
            }).then(async result => {
                if (result.value) {
                    store.dispatch('article/update_article_top', { 'id': id })
                        .then(response => {
                            Swal.fire({
                                title: "Succès",
                                text: response.message,
                                icon: "success",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            }).then(() => {
                                window.location.reload()
                            })
                        })
                        .catch(error => {
                            Swal.fire({
                                title: "Erreur",
                                text: error.data.errors,
                                icon: "error",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            })
                        })
                }
            })

        },
        copy(textToCopy) {
            var tempInput = document.createElement("input");
            tempInput.setAttribute("value", textToCopy);
            document.body.appendChild(tempInput);

            // Select and copy the text
            tempInput.select();
            document.execCommand("copy");

            // Remove the temporary input element
            document.body.removeChild(tempInput);
            Swal.fire({
                title: "Succès",
                text: 'copie effetuée',
                icon: "success",
                cancelButtonColor: "#f46a6a",
                confirmButtonColor: "#34c38f",
                confirmButtonText: "OK",
            })
        },
        deletearticle(id) {
            Swal.fire({
                title: "Question",
                text: "Voulez vous supprimer cette article ?",
                icon: "question",
                showCancelButton: true,
                cancelButtonColor: "#f46a6a",
                confirmButtonColor: "#34c38f",
                confirmButtonText: "Oui",
                cancelButtonText: 'Non',
            }).then(async result => {
                if (result.value) {
                    store.dispatch('article/deletearticle', { 'id': id })
                        .then(response => {
                            Swal.fire({
                                title: "Succès",
                                text: response.message,
                                icon: "success",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            }).then(() => {
                                window.location.reload()
                            })
                        })
                        .catch(error => {
                            Swal.fire({
                                title: "Erreur",
                                text: error.data.errors,
                                icon: "error",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            })
                        })
                }
            })
        },
        getcategorie() {
            store.dispatch('categorie/getcategorie')
                .then(response => {
                    this.categories = response
                })
                .catch(error => {
                    console.log(error)
                })
        },
        triearticle(trie) {
            if (trie === 'all') {
                // Si trie est 'all', afficher tous les éléments en réinitialisant les filtres
                this.proprie()
                // Réinitialiser les valeurs des filtres

                this.localiter = null;
                this.prop=[]
            } else if (trie === 'filtre') {



                store.dispatch('article/proprieteinfo', { "id": this.localiter })
                    .then(response => {
                        this.prop = response
                    }).catch(error => {
                        console.log(error)
                        Swal.fire({
                            title: "Erreur",
                            text: "Aucun resultat trouver",
                            icon: "error",
                            cancelButtonColor: "#f46a6a",
                            confirmButtonColor: "#34c38f",
                            confirmButtonText: "OK",
                        })
                    })

                window.location.href = '#vupro'

            }
            window.location.href = '#vupro'

        }


    },
}

</script>

<template>

    <body class="g-sidenav-show  bg-gray-200 " id="body">
        <AsideComponent />

        <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
            <!-- Navbar -->
            <HeadComponent />
            <!-- End Navbar -->
            <div class="card container-fluid py-4">
                <a href="/add_article" type="button" class="btn btn-outline-primary btn-sm mb-0">
                    <i class="opacity-10 material-icons">add</i>
                    Ajouter un article</a>

                <div class="col-12 mt-4">
                    <div class="mb-5 ps-3">
                        <h6 class="mb-1">articles</h6>
                        <p class="text-sm">Liste des articles</p>
                        <!-- <div class="col-sm-12">
                            <div class="m-5 mb-40 ">

                                <form @submit.prevent="triearticle('filtre')">
                                    <div class="row">

                                        <div class="col-sm-6">
                                            <div class="checkout-form-list input-group input-group-outline">
                                                <label class="form-label">Id de la article </label>
                                                <input type="text" v-model="localiter" class="form-control" />

                                            </div>
                                        </div>


                                        <div class="col-sm-6">


                                            <button type="submit" class="btn btn-primary text-light">Rechercher</button>

                                            <button @click="triearticle('all')" type="reset"
                                                class="btn btn-dark text-light ">RESET</button>

                                        </div>
                                    </div>


                                </form>
                            </div>
                            <button @click=" vuspecificarticle('attente')" v-if=" role=='admin'" class="btn btn-warning" ><i class="material-icons" >visibility</i> voir article en attente</button>
                                    <button @click=" vuspecificarticle('rejeter')" v-if=" role=='admin'" class="btn btn-danger" ><i class="material-icons" >visibility</i> voir article rejeter</button>
                                       

                        </div> -->
                    </div>
                    <div class="row" v-if="prop.id" >
                        <div class="card card-blog shadow-xl card-plain my-6 col-xl-6 col-md-6 mb-xl-0 mb-4 article">
                            <div class="">


                                <div class="card-header p-0 mt-n4 mx-2">
                                    <a class="d-block shadow-xl border-radius-xl" style="position: relative;">

                                        <span v-if="prop.meuble" class="badge badge-danger badge-lg"
                                            style="font-size:15px;position: absolute; top: 0; right: 0;">Meublé</span>
                                        <span class="badge badge-primary badge-lg"
                                            style="font-size:15px;position: absolute; top: 0; left: 0;">{{
                                    prop.type }}</span>


                                        <img v-if="prop.a_image && prop.a_image[0].image" :src=" prop.a_image[0].image"
                                            alt="img-blur-shadow" class="img-fluid shadow border-radius-xl">
                                        <span class="badge badge-success "
                                            style="font-size:22px; position: absolute; bottom: 0; left: 0;">
                                            {{ prop.categorie.nom }}
                                        </span>
                                    </a>
                                </div>
                                <div class="card-body p-3">
                                    <button class="btn" @click="copy(prop.id)"> {{ prop.id }}
                                        <i class="material-icons opacity-10">copy</i>
                                    </button>
                                    <a href="javascript:;">
                                        <h5>
                                            {{ prop.addresse }}
                                        </h5>
                                    </a>
                                    <p class="mb-4 text-sm">

                                        {{ prop.description }}
                                    </p>
                                    <span v-if="article.etat==true"
                                                        class="badge badge-sm bg-gradient-success">Valider</span>
                                                    <span v-else-if="article.etat==false"
                                                        class="badge badge-sm bg-gradient-danger">Rejeter</span>
                                                    <span v-else
                                                        class="badge badge-sm bg-gradient-warning">En attente</span>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <a :href="'/commentaire?id=' + prop.id" type="button"
                                            class="btn btn-outline-primary btn-sm mb-0">Voir détails</a>
                                        {{ prop.total_likes }}<i class="material-icons">favorite</i>
                                        {{ prop.total_comments }} <i class="material-icons">comment</i>
                                        <div class="avatar-group mt-2">
                                            <a href="javascript:;" class="avatar avatar-lg rounded-circle"
                                                data-bs-toggle="tooltip" data-bs-placement="bottom"
                                                :title="prop.user.first_name + ' ' + prop.user.last_name">
                                                <img alt="Image placeholder" :src=" prop.user.photo">
                                            </a>
                                        </div>
                                    </div>
                                    <div v-if="prop.etat==true" class="d-flex align-items-center justify-content-between">
                                        <a :href="'/updatearticle?id=' + prop.id" type="button"
                                            class="btn btn-dark btn-sm mb-0">Modifier</a>
                                        <a v-if="!prop.top && role == 'admin'" @click="update_article_top(prop.id)"
                                            type="button" class="btn btn-warning btn-sm mb-0 text-light">mettre au
                                            top</a>
                                        <a v-if="prop.top && role == 'admin'" @click="update_article_top(prop.id)"
                                            type="button" class="btn btn-info btn-sm mb-0 text-light">Retirer du top</a>
                                        <a @click="deletearticle(prop.id)" type="button"
                                            class="btn btn-danger btn-sm mb-0 text-light">Supprimer</a>
                                    </div>
                                    <div v-else class="d-flex align-items-center justify-content-between">
                                        <a :href="'/updatearticle?id=' + prop.id" type="button"
                                            class="btn btn-dark btn-sm mb-0">Modifier</a>
                                        <a v-if=" role == 'admin'"
                                            @click="article_validation(prop.id,'valide')" type="button"
                                            class="btn btn-success btn-sm mb-0 text-light">valider</a>
                                        <a v-if=" role == 'admin'"
                                            @click="article_validation(prop.id,'rejeter')" type="button"
                                            class="btn btn-danger btn-sm mb-0 text-light">Rejeter</a>
                                        <a @click="deletearticle(prop.id)" type="button"
                                            class="btn btn-danger btn-sm mb-0 text-light">Supprimer</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-else-if="articles" id="vupro">
                        <div v-for="article in paginatedarticles" :key="article.id"
                            class="card card-blog shadow-xl card-plain my-6 col-xl-6 col-md-6 mb-xl-0 mb-4 article"
                            :id="article.id">
                            <div class="">


                                <div class="card-header p-0 mt-n4 mx-2">
                                    <a class="d-block shadow-xl border-radius-xl" style="position: relative;">

                                        <!-- <span v-if="article.meuble" class="badge badge-danger badge-lg"
                                            style="font-size:15px;position: absolute; top: 0; right: 0;">Meublé</span>
                                        <span class="badge badge-primary badge-lg"
                                            style="font-size:15px;position: absolute; top: 0; left: 0;">{{
                                    article.type }}</span> -->


                                        <img v-if="article.a_image[0]"
                                            :src=" article.a_image[hasard(article.a_image.length)].image"
                                            alt="img-blur-shadow" class="img-fluid shadow border-radius-xl">
                                        <span class="badge badge-success "
                                            style="font-size:22px; position: absolute; bottom: 0; left: 0;">
                                            {{ article.categorie.nom }}
                                        </span>
                                    </a>
                                </div>
                                <div class="card-body p-3">
                                    <!-- <button class="btn" @click="copy(article.id)"> {{ article.id }}
                                        <i class="material-icons opacity-10">copy</i>
                                    </button>
                                    <a href="javascript:;">
                                        <h5>
                                            {{ article.addresse }}
                                        </h5>
                                    </a> -->
                                    <p class="mb-4 text-sm">

                                        {{ article.titre}}
                                    </p>
                                    <!-- <span v-if="article.etat==true"
                                                        class="badge badge-sm bg-gradient-success">Valider</span>
                                                    <span v-else-if="article.etat==false"
                                                        class="badge badge-sm bg-gradient-danger">Rejeter</span>
                                                    <span v-else
                                                        class="badge badge-sm bg-gradient-warning">En attente</span> -->
                                    <div class="d-flex align-items-center justify-content-between">
                                        <a :href="'/article_commentaire?id=' + article.id" type="button"
                                            class="btn btn-outline-primary btn-sm mb-0">Voir détails</a>
                                        {{ article.total_likes }}<i class="material-icons">favorite</i>
                                        {{ article.total_comments }} <i class="material-icons">comment</i>
                                        <div class="avatar-group mt-2">
                                            <a href="javascript:;" class="avatar avatar-lg rounded-circle"
                                                data-bs-toggle="tooltip" data-bs-placement="bottom"
                                                :title="article.user.first_name + ' ' + article.user.last_name">
                                                <img alt="Image placeholder" :src=" article.user.photo">
                                            </a>
                                        </div>
                                    </div>
                                    <!-- <div v-if="article.etat==true" class="d-flex align-items-center justify-content-between">
                                        <a :href="'/updatearticle?id=' + article.id" type="button"
                                            class="btn btn-dark btn-sm mb-0">Modifier</a>
                                        <a v-if="!article.top && role == 'admin'"
                                            @click="update_article_top(article.id)" type="button"
                                            class="btn btn-warning btn-sm mb-0 text-light">mettre au top</a>
                                        <a v-if="article.top && role == 'admin'"
                                            @click="update_article_top(article.id)" type="button"
                                            class="btn btn-info btn-sm mb-0 text-light">Retirer du top</a>
                                        <a @click="deletearticle(article.id)" type="button"
                                            class="btn btn-danger btn-sm mb-0 text-light">Supprimer</a>
                                    </div> -->
                                    <div  class="d-flex align-items-center justify-content-between">
                                        <a :href="'/updatearticle?id=' + article.id" type="button"
                                            class="btn btn-dark btn-sm mb-0">Modifier</a>
                                            <!-- <a v-if=" role == 'admin'"
                                            @click="article_validation(article.id,'valide')" type="button"
                                            class="btn btn-success btn-sm mb-0 text-light">valider</a>
                                        <a v-if=" role == 'admin'"
                                            @click="article_validation(article.id,'rejeter')" type="button"
                                            class="btn btn-danger btn-sm mb-0 text-light">Rejeter</a> -->
                                        <a @click="deletearticle(article.id)" type="button"
                                            class="btn btn-danger btn-sm mb-0 text-light">Supprimer</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="pagination justify-content-center">
                            <ul class="pagination">
                                <li v-for="pageNumber in totalPages" :key="pageNumber" @click="setPage(pageNumber)"
                                    class="page-item" :class="{ 'active': pageNumber === currentPage }">
                                    <a class="page-link"> {{ pageNumber }} </a>
                                </li>
                            </ul>
                        </div>

                    </div>
                    <div v-else class="alert alert-warning alert-dismissible fade show" role="alert">
                        <div class="alert-heading">
                            <h4 class="alert-title">Alert</h4>
                        </div>
                        <p class="text-light">Aucun article immobilier n'est trouvé</p>
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
                <FootComponent />
            </div>
        </main>
    </body>
</template>