<script>
import HeadComponent from "../components/Head"
import FootComponent from '../components/Foot'
import store from '@/store'
import Swal from 'sweetalert2';

export default {
    data() {
        return {
            agenceUser: null,
            validuser: false,
            
            pays: 'BJ',
            country: null,
            addresse: null,
        }
    },
    components: {
        HeadComponent,
        FootComponent
    },
    name: 'AgencesComponent',

    methods: {
        usersAgence() {

            const formData = new FormData()

            if (this.addresse != null) {

                formData.append('addresse', this.addresse)


            }
            if (this.pays != null) {

                formData.append('pays', this.pays)

            }
            store.dispatch('auth/usersAgence', formData)
                .then(response => {
                    console.log(response)
                    this.agenceUser = response
                })
                .catch(error => {
                    console.log(error)
                    Swal.fire({
                        title: "Erreur",
                        text: "Aucun resultat trouver",
                        icon: "error",
                        cancelButtonColor: "#f46a6a",
                        confirmButtonColor: "#34c38f",
                        confirmButtonText: "OK",
                    })
                    this.agenceUser = null
                })
        },

        redirect(type, id) {

            if (type == 'pro') {
                this.upcontact_pro(id)

            }
            else if (type == 'us') {
                this.upcontact_us(id)

            }
            alert('Cette action est reservé aux utilisateurs inscrit, Veuillez vous connecter ')

            window.location.replace('/register')

        },
        upcontact_pro(id) {
            store.dispatch('auth/upcontact_pro', { 'id': id })
                .then(response => {
                    console.log(response)
                })
                .catch(error => {
                    console.log(error)
                })
        },

        upcontact_us(id) {
            store.dispatch('auth/upcontact_us', { 'id': id })
                .then(response => {
                    console.log(response)
                })
                .catch(error => {
                    console.log(error)
                })
        },

    },
    mounted() {
        document.title = "Agences immobilliere";
        store.dispatch('categorie/getcountry')
            .then(response => {
                this.country = response

            })
            .catch(error => {
                console.log(error)
            })
        store.dispatch('auth/_currentUser')
            .then(() => {
                this.validuser = true
            })
            .catch(error => {
                console.log(error)

            })
    }

}
</script>

<template>
    <HeadComponent />

    <main>
        <div class="container-fluid">


            <section>
                <div class="container">

                    <div class="row">

                        <div class="col-md-4">
                            <div class="card">
                                <img src="img/instagram/agence.jpg" style="height: 250px;" alt="" class="card-img-top">
                                <div class="card-body">
                                    <h4 class="card-title">Découverte des Agences Immobilières en Afrique</h4>

                                    Bienvenue sur TGV IMMO BRIO, votre destination numéro un pour découvrir les agences
                                    immobilières
                                    de confiance à travers l'Afrique.
                                    <p class="collapse " id="collapseEx">
                                        Que vous recherchiez une agence pour vendre, acheter ou louer
                                        un bien immobilier, notre plateforme vous offre une expérience simple et
                                        efficace pour trouver
                                        l'agence qui correspond à vos besoins.

                                    </p>
                                    <a data-toggle="collapse" href="#collapseEx" aria-expanded="false"
                                        aria-controls="collapseExample" class="coll btn btn-outline-info btn-sm">Voir
                                        plus</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card">
                                <img src="img/instagram/agence-comment.jpg" style="height: 250px;" alt=""
                                    class="card-img-top">
                                <div class="card-body">
                                    <h4 class="card-title">Comment Utiliser cette Session ?
                                    </h4>

                                    <strong>Sélectionnez votre Pays :</strong> Choisissez le pays
                                    dans lequel vous recherchez une
                                    agence immobilière
                                    en utilisant notre menu déroulant.
                                    <br>
                                    <p class="collapse" id="collapseE">

                                        <strong>Facultatif :</strong> Spécifiez votre Ville ou Village : Si vous avez
                                        une
                                        localisation
                                        précise en tête,
                                        vous pouvez la saisir pour affiner davantage votre recherche.
                                        <br>
                                        <strong>Explorez les Résultats :</strong> Parcourez les résultats de votre
                                        recherche et
                                        consultez les profils
                                        d'agences pour trouver celle qui répond le mieux à vos besoins.
                                        <br>
                                        <strong>Prenez Contact :</strong> Une fois que vous avez trouvé une agence qui
                                        vous
                                        intéresse,
                                        contactez-la
                                        directement pour discuter de vos projets immobiliers.
                                    </p>

                                    <a class="coll btn btn-outline-info btn-sm" data-toggle="collapse" href="#collapseE"
                                        aria-expanded="false" aria-controls="collapseExample">Voir
                                        plus</a>

                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="card">
                                <img src="img/instagram/pourquoi.jpg" style="height: 250px;" alt=""
                                    class="card-img-top">
                                <div class="card-body">
                                    <h4 class="card-title">Pourquoi Choisir TGV IMMO BRIO</h4>

                                    <strong> Large Réseau :</strong> Nous avons un vaste réseau d'agences immobilières
                                    partenaires à travers
                                    l'Afrique, ce qui vous donne un large éventail d'options.
                                    <p class="collapse " id="collapse">

                                        <strong> Facilité d'Utilisation :</strong> Notre plateforme conviviale rend la
                                        recherche et la sélection d'une
                                        agence immobilière simple et intuitive.
                                        <strong>Confiance et Fiabilité :</strong> Nous travaillons uniquement avec des
                                        agences immobilières réputées
                                        et
                                        fiables pour vous offrir une expérience de qualité.
                                        Ne perdez plus de temps à chercher la bonne agence immobilière en Afrique.
                                        Utilisez TGV IMMO BRIO dès aujourd'hui et concrétisez vos projets immobiliers
                                        avec confiance
                                        et
                                        facilité !
                                    </p>
                                    <a data-toggle="collapse" href="#collapse" aria-expanded="false"
                                        aria-controls="collapseExample" class="coll btn btn-outline-info btn-sm">Voir
                                        plus</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <h1 class="text-center m-4">Liste des agences</h1>
            <div class="card-body">
                <form @submit.prevent="usersAgence">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="checkout-form-list country-select">
                                <label class="form-label">Pays<span>*</span></label>
                                <select required ref="mySelect" v-model="pays" class="form-control">
                                    <option v-for="countrie in country" :key="countrie.iso" :value="countrie.iso">{{
                    countrie.name }}</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-sm-6">
                            <div class="checkout-form-list">
                                <label class="form-label">Ville,quartier <span>*</span></label>
                                <input type="text" v-model="addresse" class="form-control">
                            </div>
                        </div>



                        <center>

                            <button type="submit" class="btn theme-btn-2 text-light">
                                Rechercher
                            </button>

                        </center>
                    </div>
                </form>
                <section>
                    <div class="alert alert-danger mt-5" role="alert">
                        Que faut t'il savoir sur l'immobillier dans ce pays. 
                        <a :href="'/article?pays='+pays"
                            class="alert-link">cliquez ici </a> pour en savoir plus
                    </div>
                </section>

            </div>
            <div v-if=" agenceUser ">
                <div v-for=" aguser  in agenceUser" :key=" aguser.id " class="card" style="width: 18rem;">
                    <img :src="aguser.photo " class="card-img-top" alt="">
                    <div class="card-body">
                        <h5 class="card-title">{{ aguser.entreprise }}</h5>
                        <p class="card-text">{{ aguser.addresse }}</p>

                        <div class="social-profile" v-if=" !validuser ">
                            <a href='' @click="redirect('us', aguser.id)"><i class="fa fa-envelope"></i></a>
                            <a href='' @click="redirect('us', aguser.id)"><i class="fab fa-whatsapp"></i></a>
                            <a :href=" '/search?type=user&id=' + aguser.id "><i class="fa fa-eye"></i></a>

                        </div>

                        <div class="social-profile" v-else>
                            <a target="_blank" @click="upcontact_us(aguser.id)" 
                            :href=" 'mailto:' + aguser.email + '?subject=' + encodeURIComponent('Demande de renseignement') + '&body=' + encodeURIComponent('Bonjour, j\'ai vu votre agence immo sur TGV IMMO BRIO') "><i
                                    class="fa fa-envelope"></i></a>
                            <a target="_blank" @click="upcontact_us(aguser.id)"
                                :href=" 'https://wa.me/' + aguser.whatsapp + '?text=' + encodeURIComponent('Bonjour, j\'ai vu votre agence immo sur TGV IMMO BRIO')"><i class="fab fa-whatsapp"></i></a>

                            <a :href=" '/search?type=user&id=' + aguser.id "><i class="fa fa-eye"></i></a>

                        </div>

                    </div>
                </div>
            </div>


        </div>

    </main>

    <FootComponent />
</template>
<style>
#about-us {
    justify-content: center;
}



#about-us .about-item h2 {
    font-style: italic;
    font-weight: 900;
    font-size: 1.4em;
}

#about-us .about-item i {
    font-size: 2.5em;
    border: 3px solid rgb(205, 127, 50);
    border-radius: 100%;
    padding: 30px;
    color: rgb(205, 127, 50);
    width: 106px;
}
</style>