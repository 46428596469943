import axios from 'axios'
import router from '@/router/index'
import axiosWithHeaders, { BASE_API_URL } from '@/services/api'

export const state = {
  accessToken: null,
  refreshToken: null,
  currentUser: null,
}

export const getters = {
  getAccessToken: state => state.accessToken,
  getRefreshToken: state => state.refreshToken,
  getCurrentUser: state => state.currentUser,
}

export const mutations = {
  setTokens(state, { access, refresh }) {
    state.accessToken = access
    state.refreshToken = refresh
    localStorage.setItem('access_token', access)
    localStorage.setItem('refresh_token', refresh)
    localStorage.setItem('user', true)
  },

  removeTokens(state) {
    state.accessToken = null
    state.refreshToken = null
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('user')
  },

  setCurrentUser(state, user) {
    state.currentUser = user
  },
}


export const actions = {




  login({ commit }, loginData) {
    return new Promise((resolve, reject) => {
      axios.post(BASE_API_URL + 'login', loginData)
        .then(response => {
          if(response.data.user)
          {
          commit('setTokens', response.data)
          commit('setCurrentUser', response.data.user)
          if(response.data.user.role!='simple')
          {
            
          window.location.replace('/dashboard')
          }
        }
        else{
          window.location.replace('/rpin')
        }
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  update_user ({ commit }, registerData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put(BASE_API_URL + 'update_user', registerData)
        .then(response => {
          commit('setTokens', response.data)
          commit('setCurrentUser', response.data.user)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  contacter({ commit }, contacter) {
    return new Promise((resolve, reject) => {
      axios.post(BASE_API_URL + 'contacter', contacter)
        .then(response => {
          commit('setTokens', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  register({ commit }, registerData) {
    return new Promise((resolve, reject) => {
      axios.post(BASE_API_URL + 'register', registerData)
        .then(response => {
          commit('setTokens', response.data)
          commit('setCurrentUser', response.data.user)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  logout({ commit }, dataLogout) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.post('deconnect', dataLogout)
        .then(response => {
          commit('removeTokens')
          resolve(response.data)
          router.push('/login')
        })
        .catch(error => {
          reject(error.response)
          router.push('/login')
        })
    })
  },
  userDemande({commit},data) {
    commit=null
    console.log(commit)
    return new Promise((resolve, reject) => {
      axiosWithHeaders.post(BASE_API_URL + 'demande/user', data)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  demandes_validation({commit},data)
  {
    commit=null
    console.log(commit)
    return new Promise((resolve, reject) => {
      axiosWithHeaders.post(BASE_API_URL + 'demandes_validation/'+ data.id+'/'+data.val)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  supdemande({commit},data)
  {
    commit=null
    console.log(commit)
    return new Promise((resolve, reject) => {
      axiosWithHeaders.delete(BASE_API_URL + 'supdemande/'+ data.id)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  block_user({commit},data)
  {
    commit=null
    console.log(commit)
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put(BASE_API_URL + 'block_user/'+ data.id)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
update_user_max_cat({commit},data)
{
  commit=null
  console.log(commit)
  return new Promise((resolve, reject) => {
    axiosWithHeaders.put(BASE_API_URL + 'update_user_max_cat/'+ data.id+'/'+data.max)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response)
      })
  })
},


  update_user_top({commit},data)
  {
    commit=null
    console.log(commit)
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(BASE_API_URL + 'update_user_top/'+ data.id)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  update_user_dmd({commit},data)
  {
    commit=null
    console.log(commit)
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(BASE_API_URL + 'update_user_dmd/'+ data.id)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  _currentUser({ commit }) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('get-current-user')
        .then(response => {
          commit('setCurrentUser', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  dashboard()
{
  return new Promise((resolve, reject) => {
    axiosWithHeaders.get(BASE_API_URL + 'dashboard')
       .then(response => {
            resolve(response.data)
        })
       .catch(error => {
            reject(error)
        })
  })} ,
  
  dashboard_user({commit},data)
  {
    commit=null
    console.log(commit)
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(BASE_API_URL + 'dashboard_user/'+data.id)
         .then(response => {
              resolve(response.data)
          })
         .catch(error => {
              reject(error)
          })
    })
} , usertop() {
    return new Promise((resolve, reject) => {
        axios.get(BASE_API_URL + 'users/top')
           .then(response => {
                resolve(response.data)
            })
           .catch(error => {
                reject(error)
            })
      })
  },
  
  users() {
    return new Promise((resolve, reject) => {
        axiosWithHeaders.get(BASE_API_URL + 'users')
           .then(response => {
                resolve(response.data)
            })
           .catch(error => {
                reject(error)
            })
      })
  },

  modifypassword({commit},data)
  {
    commit=null
  console.log(commit)
    return new Promise((resolve, reject) => {
    axiosWithHeaders.put(BASE_API_URL + 'modifypassword', data)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
    
  },
verifycode({commit},data)
{
  return new Promise((resolve, reject) => {
  axios.post(BASE_API_URL + 'verifycode', data)
    .then(response => {
      commit('setTokens', response.data)
      commit('setCurrentUser', response.data.user)
      resolve(response.data)
    })
    .catch(error => {
      reject(error)
    })
})
  
},

forgetpassword({commit},data)
{
  commit=null
  console.log(commit)   
return new Promise((resolve, reject) => {
  axios.post(BASE_API_URL + 'forgetpassword',data)
     .then(response => {
          resolve(response.data)
      })
     .catch(error => {
          reject(error)
      })
})
},
  usersAgence({commit},data)
  {
    commit=null
    console.log(commit)
    return new Promise((resolve, reject) => {
        axios.post(BASE_API_URL + 'users/agence',data)
           .then(response => {
                resolve(response.data)
            })
           .catch(error => {
                reject(error)
            })
      })
  },

upcontact_us({commit},data)
{
  commit=null
  console.log(commit)   
return new Promise((resolve, reject) => {
  axios.get(BASE_API_URL + 'upcontact_us/'+data.id)
     .then(response => {
          resolve(response.data)
      })
     .catch(error => {
          reject(error)
      })
})
},
upcontact_pro({commit},data)
{
  commit=null
  console.log(commit)   
return new Promise((resolve, reject) => {
  axios.get(BASE_API_URL + 'upcontact_pro/'+data.id)
     .then(response => {
          resolve(response.data)
      })
     .catch(error => {
          reject(error)
      })
})
},

}
export default {
  state,
  getters,
  actions,
  mutations,
  
  namespaced: true,
}
