<script>
import store from '@/store'

import Swal from 'sweetalert2';
export default {
    name: 'FootComponent',
    data() {
        return {
            // Initialisez currentUser à null
            categories: null,
            a_categories: null,
            email: null,
            emaill: null,
            nom:null,
            tel:null,
            msg:null,
        }
    },

    mounted() {
        this.getcategorie()
        this.get_art_categorie()
    },
    methods: {

        contacter()
        {
            const formData = new FormData()
            formData.append('nom', this.nom)
                    formData.append('tel', this.tel)
                    formData.append('email', this.emaill)
                    formData.append('msg', this.msg)

                
                store.dispatch('auth/contacter', formData).then(response => {

                    Swal.fire({
                        title: "Succès",
                        text: response.message,
                        icon: "success",
                        cancelButtonColor: "#f46a6a",
                        confirmButtonColor: "#34c38f",
                        confirmButtonText: "OK",
                    })
                }).catch(error => {

                    Swal.fire({
                                title: "Erreur",
                                text:"erreur lors de l'envoie du message ",
                                icon: "error",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            })
                    console.log(error)
                })
        },
        getcategorie() {
            store.dispatch('categorie/getcategorie')
                .then(response => {
                    this.categories = response
                })
                .catch(error => {
                    console.log(error)
                })


        },
        get_art_categorie() {
            store.dispatch('article/getcategorie')
                .then(response => {
                    this.a_categories = response
                })
                .catch(error => {
                    console.log(error)
                })


        },
        add_news() {

            const formData = new FormData()
            formData.append('email', this.email)
            store.dispatch('categorie/add_news', formData)
                .then(response => {
                    Swal.fire({
                        title: "Succès",
                        text: response.message,
                        icon: "success",
                        cancelButtonColor: "#f46a6a",
                        confirmButtonColor: "#34c38f",
                        confirmButtonText: "OK",
                    })
                })
                .catch(error => { Swal.fire({
                        title: "Erreur",
                        text: error.data,
                        icon: "error",
                        cancelButtonColor: "#f46a6a",
                        confirmButtonColor: "#34c38f",
                        confirmButtonText: "OK",
                    })
                })
        },
    }
}
</script>
<template>
    <footer class="">
        <div class="footer-area box-90 pt-100 pb-60">
            <div class="container-fluid">
                <div class="row " >


                    <div class=" col-12 ">
                        <div class="container mt-5 ">
                <h2>Contactez-nous dès aujourd'hui</h2>
                <p>Prêt à franchir le pas et à concrétiser votre projet immobilier ? Contactez TGV Immo Brio dès
                    aujourd'hui pour visualiser nos annonces de vente, d'achat ou de location de biens immobiliers
                    (appartements, terrain ou parcelle, résidence, villas, maison, etc.) au Bénin. Que vous soyez à la
                    recherche d’un appartement, d’une maison, ou d’une villa meublée, nous vous garantissons un service
                    d'excellence.</p>


                <!-- Contact Form -->
                <form action="/send_message/" method="post">
                    <div class="row">
                        <!-- Name -->
                        <div class="col-md-6 mb-3">
                            <label for="name" class="form-label">Nom complet</label>
                            <input type="text" class="form-control" id="name" name="name" v-model='nom' placeholder="Entrez votre nom"
                                required>
                        </div>

                        <!-- Email -->
                        <div class="col-md-6 mb-3">
                            <label for="email" class="form-label">Email</label>
                            <input type="email" class="form-control" id="email" name="email" v-model='emaill'
                                placeholder="Entrez votre email" required>
                        </div>
                    </div>

                    <div class="row">
                        <!-- Phone -->
                        <div class="col-md-6 mb-3">
                            <label for="phone" class="form-label">Téléphone</label>
                            <input type="tel" class="form-control" id="phone" name="phone" v-model="tel"
                                placeholder="Entrez votre numéro de téléphone" required>
                        </div>

                        <!-- Subject -->
                        <div class="col-md-6 mb-3">
                            <label for="subject" class="form-label">Objet</label>
                            <input type="text" class="form-control" id="subject" name="subject" 
                                placeholder="Objet du message" required>
                        </div>
                    </div>

                    <!-- Message -->
                    <div class="mb-3">
                        <label for="message" class="form-label">Message</label>
                        <textarea class="form-control" id="message" v-model='msg' name="message" rows="5" placeholder="Votre message"
                            required></textarea>
                    </div>

                    <!-- Submit Button -->
                    <button type="submit" class="btn btn-primary">Envoyer</button>
                </form>
            </div>
                        
                        <div class="footer-widget mb-40">


                            <div class="footer-time d-flex mt-30">
                                <div class="time-icon">
                                    <img src="img/icon/time.png" alt="">
                                </div>
                                <div class="time-text">
                                    <span>Des Questions ? Appeler 24/7!</span>

                                    <a target="_blank" href="tel:+22962620862">
                                        <h2>(+229) 62 62 08 62</h2>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 ">
                        <div class="footer-widget pl-50 mb-40">
                            <h3>Categorie d'article</h3>
                            <ul class="footer-link">
                                <li v-for="cat in a_categories" :key="cat.id"> <a
                                        :href="'/blog?type=categorie&id=' + cat.id">{{ cat.nom }}</a></li>

                            </ul>
                        </div>
                    </div>
                    <div class=" col-4 ">
                        <div class="footer-widget pl-30 mb-40">
                            <h3>Categorie de bien</h3>
                            <ul class="footer-link">
                                <li v-for="cat in categories" :key="cat.id"> <a
                                        :href="'/search?type=categorie&id=' + cat.id">{{ cat.nom }}</a></li>

                            </ul>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="footer-widget mb-40">
                            <h3>Pages</h3>
                            <ul class="footer-link">

                                <li>
                                    <a href="/"> Home </a>
                                </li>
                                <li>
                                    <a href="/search?type=a vendre"> Immobilier à vendre </a>
                                </li>
                                <li>
                                    <a href="/search?type=a louer">Immobilier à louer </a>
                                </li>
                                <li>
                                    <a href="/agence"> Agences immobilières </a>
                                </li>

                                <li>
                                    <a href="/about"> Qui sommes nous ?</a>
                                </li>
                                <li>
                                    <a href="/article">Que faut'il savoir ?</a>
                                </li>

                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="copyright-area box-105 bg-dark">
            <div class="container-fluid">
                <div class="copyright-border pt-30 pb-30 ">
                    <div class="row">
                        
                        <div class=" col-12  bg-dark">
                            <div class="footer-widget">
                                <div class="footer-banner ">
                                    <center>
                                        <h2 class="text-center text-light">Inscrivez-vous à notre Newsletter</h2>
                                        <form @submit.prevent="add_news">
                                            <div class="mb-3 flex">
                                                <input type="email" class="form-control" id="email" v-model='email' name="email"
                                                    placeholder="Entrez votre email" required>
                                            </div>
                                            <div class="d-grid">
                                                <button type="submit" class="btn text-light"
                                                    style="background-color:#f08d21; margin-bottom: 10px">S'inscrire</button>
                                            </div>
                                        </form>
                                    </center>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6">
                            <div class="copyright text-center text-md-left">
                                <p>Copyright © 2024 <a href="#">kdigit</a>. All Rights Reserved</p>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6">
                            <div class="footer-icon text-center text-md-right ">
                                <a href="#"><i class="fab fa-facebook-f"></i></a>
                                <a href="#"><i class="fab fa-twitter"></i></a>
                                <a href="#"><i class="fab fa-behance"></i></a>
                                <a href="#"><i class="fab fa-linkedin-in"></i></a>
                                <a href="#"><i class="fab fa-youtube"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <!-- footer end -->
</template>