import axios from 'axios'
import axiosWithHeaders, { BASE_API_URL } from '@/services/api'

const state = {}
const getters = {}
const mutations = {}
const actions={

    addCategorie({commit}, data){
        commit=null
        console.log(commit)     
        return new Promise((resolve, reject) => {
            axiosWithHeaders.post(BASE_API_URL + 'categories/add',data)
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })
    },
    add_news({commit}, data) {
        commit=null
        console.log(commit)     
        return new Promise((resolve, reject) => {
            axios.post(BASE_API_URL + 'news/add' ,data )
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })
    },
    modCategorie({commit}, data){
        commit=null
        console.log(commit)     
        return new Promise((resolve, reject) => {
            axiosWithHeaders.put(BASE_API_URL + 'categorie/'+data.id+'/update',data)
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })
    },
    
    supCategorie({commit}, data){
        commit=null
        console.log(commit)     
        return new Promise((resolve, reject) => {
            axiosWithHeaders.delete(BASE_API_URL + 'categorie/'+data.id+'/delete')
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })
    },
    getcategorie() {
        return new Promise((resolve, reject) => {
            axios.get(BASE_API_URL + 'categories')
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })
    },
    get_news() {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.get(BASE_API_URL + 'news')
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })
    },
    getcountry() {
        return new Promise((resolve, reject) => {
            axios.get(BASE_API_URL + 'get-country')
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })
    },
    abonner({commit}, data) {
        commit=null
        console.log(commit)     
        return new Promise((resolve, reject) => {
            axiosWithHeaders.post(BASE_API_URL + 'abonners/add/' + data.id)
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })
    },
    getabonnement({commit}) {
        commit=null
        console.log(commit)     
        return new Promise((resolve, reject) => {
            axiosWithHeaders.get(BASE_API_URL + 'abonners')
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })
    },

}

export default {
    namespaced:true,
    state,
    getters,
    mutations,
    actions,
}