<script>
import AsideComponent from "@/components/Asideadmin"
import HeadComponent from "@/components/Headadmin"
import FootComponent from "@/components/Footadmin"

import store from "@/store"
import Swal from 'sweetalert2';
import $ from 'jquery'
export default {

    components: {
        HeadComponent,
        AsideComponent,
        FootComponent
    },
    data() {

        return {
            user: null,
            demandes: null,
            role: null,


        }
    },
    name: 'DemandeComponent',
    mounted() {
        const urlParams = new URLSearchParams(window.location.search);
        var val = urlParams.get('search')
        if (val) {
            this.vuspecificdemande(val)
        } else {
            this.vudemande()
        }

        store.dispatch('auth/_currentUser')
            .then(() => {
                var currentuser = store.getters['auth/getCurrentUser'];
                this.user = currentuser.id
                this.role = currentuser.role
            })
            .catch(error => {
                console.log(error)
            })

    },
    methods: {
        formatMonetaryValue(value) {
            // Convertit le nombre en chaîne de caractères
            let formattedValue = String(value);

            // Vérifie si la valeur est supérieure à 3 chiffres
            if (formattedValue.length > 3) {
                // Détermine le nombre de groupes de trois chiffres
                const groups = Math.floor((formattedValue.length - 1) / 3);

                // Initialise l'index pour l'insertion de l'espace de mille
                let index = formattedValue.length - 3;

                // Boucle pour insérer les espaces de mille
                for (let i = 0; i < groups; i++) {
                    formattedValue = formattedValue.slice(0, index) + ' ' + formattedValue.slice(index);
                    index -= 3;
                }
            }

            // Retourne la valeur formatée
            return formattedValue;
        },

        upcontact_us(id) {
            store.dispatch('auth/upcontact_us', { 'id': id })
                .then(response => {
                    console.log(response)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        supdemande(id) {
            Swal.fire({
                title: "Question",
                text: "Voulez vous supprimer cette demande ?",
                icon: "question",
                showCancelButton: true,
                cancelButtonColor: "#f46a6a",
                confirmButtonColor: "#34c38f",
                confirmButtonText: "Oui",
                cancelButtonText: 'Non',
            }).then(async result => {
                if (result.value) {
                    store.dispatch('auth/supdemande', { 'id': id })
                        .then(response => {
                            Swal.fire({
                                title: "Succès",
                                text: response.message,
                                icon: "success",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            }).then(() => {
                                window.location.reload()
                            })

                        })
                        .catch(error => {
                            Swal.fire({
                                title: "Erreur",
                                text: error.data.errors,
                                icon: "error",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            })
                        })
                }
            })
        },
        initializeDataTable() {
            // Détruire le DataTable existant si nécessaire
            if ($.fn.DataTable.isDataTable('#Table')) {
                $('#Table').DataTable().destroy();
            }
        },
        vudemande() {
            store.dispatch('proprieter/demandes')
                .then(response => {
                    this.demandes = response

                    this.$nextTick(() => {
                        this.initializeDataTable();
                    });

                })
                .catch(error => {
                    console.log(error)
                })
        },
        demandes_validation(id, val) {
            Swal.fire({
                title: "Question",
                text: "Voulez vous valider/rejeter cette demande ?",
                icon: "question",
                showCancelButton: true,
                cancelButtonColor: "#f46a6a",
                confirmButtonColor: "#34c38f",
                confirmButtonText: "Oui",
                cancelButtonText: 'Non',
            }).then(async result => {
                if (result.value) {
                    store.dispatch('auth/demandes_validation', { 'id': id, 'val': val })
                        .then(response => {
                            Swal.fire({
                                title: "Succès",
                                text: response.message,
                                icon: "success",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            }).then(() => {
                                window.location.reload()
                            })

                        })
                        .catch(error => {
                            Swal.fire({
                                title: "Erreur",
                                text: error.data.errors,
                                icon: "error",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            })
                        })
                }
            })
        },
        vuspecificdemande(val) {
            store.dispatch('proprieter/demandes_spec', { 'val': val })
                .then(response => {
                    this.demandes = response

                    this.$nextTick(() => {
                        this.initializeDataTable();
                    });


                })
                .catch(error => {
                    Swal.fire({
                                title: "Erreur",
                                text:'aucune demande en attente/rejeter',
                                icon: "error",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            })
                    console.log(error)
                })
        }
    }
}

</script>
<template>

    <body class="g-sidenav-show  bg-gray-200" id="body">
        <AsideComponent />

        <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
            <!-- Navbar -->
            <HeadComponent />
            <!-- End Navbar -->
            <div class="container-fluid py-4">

                <div class="row">

                    <div class="col-12">
                        <a :href="'/demande?search=attente'" v-if="role == 'admin'" class="btn btn-warning"><i
                                class="material-icons">visibility</i> voir demande en attente</a>
                        <a  :href="'/demande?search=rejeter'" v-if="role == 'admin'" class="btn btn-danger"><i
                                class="material-icons">visibility</i> voir demande rejeter</a>

                        <div class="card my-4">

                            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                                <div class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
                                    <h6 class="text-white text-capitalize p-3">Demande </h6>


                                </div>
                            </div>
                            <div class="card-body px-0 pb-2">
                                <div class="table-responsive p-0">
                                    <table id="Table" class="table align-items-center justify-content-center mb-0">
                                        <thead>
                                            <tr>
                                                <th
                                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                    Utilisateur</th>
                                                <th
                                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                    Categorie</th>
                                                <th
                                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                    Pays</th>
                                                <th
                                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                    Ville</th>
                                                <th
                                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                    Budget</th>
                                                <th
                                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                    Details</th>
                                                <th
                                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                    Statut</th>

                                                <th
                                                    class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2">
                                                    Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr v-for="demande in demandes" :key="demande.id">
                                                <td>
                                                    <div class="d-flex px-2">
                                                        <div>
                                                            <img :src="demande.user.photo"
                                                                class="avatar avatar-sm rounded-circle me-2" alt="xd">
                                                        </div>
                                                        <div class="my-auto">
                                                            <h6 class="mb-0 text-sm">{{ demande.user.first_name }} {{
                                                                demande.user.last_name }}</h6>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span class="text-xs font-weight-bold">{{ demande.categorie.nom
                                                        }}</span>
                                                </td>
                                                <td>
                                                    <span class="text-xs font-weight-bold">{{ demande.pays.name
                                                        }}</span>
                                                </td>
                                                <td>
                                                    <span class="text-xs font-weight-bold">{{ demande.ville }}</span>
                                                </td>
                                                <td>
                                                    <p class="text-sm font-weight-bold mb-0">{{ formatMonetaryValue(
                                                        demande.budget )}} {{ demande.pays.currency_code }}</p>
                                                </td>
                                                <td>
                                                    <p class="text-sm font-weight-bold mb-0">{{ demande.caracteristique
                                                        }}</p>
                                                </td>
                                                <td> <span v-if="demande.etat == true"
                                                        class="badge badge-sm bg-gradient-success">Valider</span>
                                                    <span v-else-if="demande.etat == false"
                                                        class="badge badge-sm bg-gradient-danger">Rejeter</span>
                                                    <span v-else class="badge badge-sm bg-gradient-warning">En
                                                        attente</span>
                                                </td>
                                                <td class="align-middle text-center">
                                                    <div class="social-profile">
                                                        <button v-if="!demande.etat || demande.etat == false"
                                                            class="btn btn-success m-2"
                                                            @click="demandes_validation(demande.id, 'valide')">
                                                            valider
                                                        </button>
                                                        <button v-if="!demande.etat" class="btn btn-danger m-2"
                                                            @click="demandes_validation(demande.id, 'rejeter')">
                                                            rejeter
                                                        </button>
                                                        <a target="_blank" :href="'mailto:' + demande.user.email"
                                                            @click="upcontact_us(demande.user.id)"><i
                                                                class="fa fa-envelope"></i></a>
                                                        <a target="_blank"
                                                            :href="'https://wa.me/' + demande.user.whatsapp"
                                                            @click="upcontact_us(demande.user.id)"><i
                                                                class="fab fa-whatsapp"></i></a>
                                                        <button @click="supdemande(demande.id)"
                                                            v-if="user == demande.user.id || role == 'admin'"
                                                            class="btn btn-danger"><i
                                                                class="material-icons">delete</i></button>
                                                    </div>


                                                </td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FootComponent />
            </div>
        </main>
    </body>
</template>