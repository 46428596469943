import axios from 'axios'
import axiosWithHeaders,{ BASE_API_URL } from '@/services/api'

const state = {}
const getters = {}
const mutations = {}
const actions={

    articles() {
        return new Promise((resolve, reject) => {
            axios.get(BASE_API_URL + 'articles')
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })
    },
  
    getcategorie() {
        return new Promise((resolve, reject) => {
            axios.get(BASE_API_URL + 'categories_article')
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })
    },
    vuspecificarticle({commit},data){
        commit=null
        console.log(commit)     
        return new Promise((resolve, reject) => {
            axiosWithHeaders.get(BASE_API_URL + 'admarticles/'+data.val )
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })

    },
    admarticle() {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.get(BASE_API_URL + 'admarticles')
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })
    },
    articletop() {
        return new Promise((resolve, reject) => {
            axios.get(BASE_API_URL + 'articles/top')
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })
    },
    addarticle({commit}, data) {
        commit=null
        console.log(commit)     
        return new Promise((resolve, reject) => {
            axiosWithHeaders.post(BASE_API_URL + 'articles/add' ,data )
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })
    }, article_validation({commit},data)
    {
      commit=null
      console.log(commit)
      return new Promise((resolve, reject) => {
        axiosWithHeaders.post(BASE_API_URL + 'article_validation/'+ data.id+'/'+data.val)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response)
          })
      })
    },
    articleinfo({commit}, id) {
        commit=null
        console.log(commit)     
        return new Promise((resolve, reject) => {
            axios.get(BASE_API_URL + 'article/' + id.id)
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })
    },
    updatearticle ({commit}, data) {
        commit=null
        console.log(commit)     
        return new Promise((resolve, reject) => {
            axiosWithHeaders.put(BASE_API_URL + 'article_update',data)
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })
    },
    deleteimage({commit}, id) {
        commit=null
        console.log(commit)     
        return new Promise((resolve, reject) => {
            axiosWithHeaders.delete (BASE_API_URL + 'a_deleteimage/'+ id.id)
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                console.log(error)
                    reject(error)
                })
        })
    },
    deletearticle({commit}, id) {
        commit=null
        console.log(commit)     
        return new Promise((resolve, reject) => {
            axiosWithHeaders.delete (BASE_API_URL + 'article/'+ id.id+'/delete')
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })
    },
    update_article_top({commit},data)
    {
      commit=null
      console.log(commit)
      return new Promise((resolve, reject) => {
        axiosWithHeaders.get(BASE_API_URL + 'update_article_top/'+ data.id)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response)
          })
      })
    },
    articlefilter({commit},data)
    {
      commit=null
      console.log(commit)
      return new Promise((resolve, reject) => {
        axios.post(BASE_API_URL + 'articlefilter', data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response)
          })
      })
    },
    articlecommentaires({commit}, id) {
        commit=null
        console.log(commit)     
        return new Promise((resolve, reject) => {
            axios.get(BASE_API_URL + 'a_commentaires/' + id.id)
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })
    },
    
    commenter({commit}, data) {
        commit=null
        console.log(commit)     
        return new Promise((resolve, reject) => {
            axiosWithHeaders.post(BASE_API_URL + 'a_commentaires/add/' + data.id,{'message':data.message})
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })
    },
    article({commit}, data) {
        commit=null
        console.log(commit)     
        return new Promise((resolve, reject) => {
            axiosWithHeaders.get(BASE_API_URL + 'article/' + data.id)
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })
    },
    liker({commit}, data) {
        commit=null
        console.log(commit)     
        return new Promise((resolve, reject) => {
            axiosWithHeaders.post(BASE_API_URL + 'a_likes/add/' + data.id)
               .then(response => {
                    resolve(response.data)
                })
               .catch(error => {
                    reject(error)
                })
        })
    },
    articlesearch({commit },data){
        let id
        commit=null
        console.log(commit)
        console.log(data)
        if(data.id)
        {
            id=data.id
        }
        else
        {
            id=''
        }
        return new Promise((resolve, reject) => {
            if(data.id)
            {
            axios.get(BASE_API_URL + 'articlesearch/'+data.type+'/'+id)
            .then(response => {
                resolve(response.data)
            })
           .catch(error => {
                reject(error)
            })


        }
        else 
        {
            axios.get(BASE_API_URL + 'articlesearch/'+data.type)
            .then(response => {
                resolve(response.data)
            })
           .catch(error => {
                reject(error)
            })
    


        }
          
    })
    }
}

export default {
    namespaced:true,
    state,
    getters,
    mutations,
    actions,
}