<script>
import AsideComponent from "@/components/Asideadmin"
import HeadComponent from "@/components/Headadmin"
import FootComponent from "@/components/Footadmin"

import store from "@/store"
import Swal from 'sweetalert2'
export default {

    components: {
        HeadComponent,
        AsideComponent,
        FootComponent
    },
    data() {

        return {
            dashboard: null,
            user: null,
            demandes: null,
            role: null,
            nb_contact: 0,
            
            nb_like: 0,
            nb_comment: 0,
            nb_bien: 0,
            id: null


        }
    },
    name: 'DashboardComponent',
    mounted() {
        store.dispatch('auth/_currentUser')
            .then(() => {

                var currentuser = store.getters['auth/getCurrentUser'];
                const urlParams = new URLSearchParams(window.location.search);
                this.user = currentuser.id
                this.role = currentuser.role
                this.id = urlParams.get('id');
                this.vudemande(this.id)
                this.dashboard_info(this.id)

            })
            .catch(error => {
                console.log(error)
            })

    },
    methods: {

        upcontact_pro(id) {
            store.dispatch('auth/upcontact_pro', { 'id': id })
                .then(response => {
                    console.log(response)
                })
                .catch(error => {
                    console.log(error)
                })
        },

        upcontact_us(id) {
            store.dispatch('auth/upcontact_us', { 'id': id })
                .then(response => {
                    console.log(response)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        proprietop() {
            store.dispatch('proprieter/proprietop')
                .then(response => {
                    console.log(response)
                    this.proprieterstop = response
                })
                .catch(error => {
                    console.log(error)
                })
        },
        usertop() {
            store.dispatch('auth/usertop')
                .then(response => {
                    console.log(response)
                    this.userstop = response
                })
                .catch(error => {
                    console.log(error)
                })
        },
        supdemande(id) {
            Swal.fire({
                title: "Attention?",
                text: "Voulez vous supprimer cette demande ?",
                icon: "warning",
                showCancelButton: true,
                cancelButtonColor: "#f46a6a",
                confirmButtonColor: "#34c38f",
                confirmButtonText: "Oui",
                cancelButtonText: 'Non',
            }).then(async result => {
                if (result.value) {
                    store.dispatch('auth/supdemande', { 'id': id })
                        .then(response => {
                            console.log(response)
                            window.location.reload()
                        })
                        .catch(error => {
                            console.log(error)
                        })
                }
            })
        },

        vudemande(id) {
            store.dispatch('proprieter/demandes_user', { 'id': id })
                .then(response => {
                    this.demandes = response

                })
                .catch(error => {
                    console.log(error)
                })
        },
        dashboard_info(id) {
            store.dispatch('auth/dashboard_user', { 'id': id })
                .then(response => {


                    this.nb_like = response.nb_like
                    this.nb_comment = response.nb_comment
                    this.nb_bien = response.nb_bien
                    this.nb_contact = response.nb_contact
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }
}

</script>

<template>

    <body class="g-sidenav-show  bg-gray-200" id="body">
        <AsideComponent />

        <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
            <!-- Navbar -->
            <HeadComponent />
            <!-- End Navbar -->
            <div class="container-fluid py-4 row">


                <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                    <div class="card">
                        <div class="card-header p-3 pt-2">
                            <div
                                class="icon icon-lg icon-shape bg-gradient-dark shadow-dark text-center border-radius-xl mt-n4 position-absolute">
                                <i class="material-icons opacity-10">house</i>
                            </div>
                            <div class="text-end pt-1">
                                <p class="text-sm mb-0 text-capitalize">Mes biens </p>
                                <h4 class="mb-0">{{ nb_bien }}</h4>
                            </div>
                        </div>
                        <hr class="dark horizontal my-0">

                    </div>
                </div>
                <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                    <div class="card">
                        <div class="card-header p-3 pt-2">
                            <div
                                class="icon icon-lg icon-shape bg-gradient-primary shadow-primary text-center border-radius-xl mt-n4 position-absolute">
                                <i class="material-icons opacity-10">thumb_up</i>
                            </div>
                            <div class="text-end pt-1">
                                <p class="text-sm mb-0 text-capitalize">Mes likes</p>
                                <h4 class="mb-0">{{ nb_like }}</h4>
                            </div>
                        </div>
                        <hr class="dark horizontal my-0">

                    </div>
                </div>
                <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                    <div class="card">
                        <div class="card-header p-3 pt-2">
                            <div
                                class="icon icon-lg icon-shape bg-gradient-success shadow-success text-center border-radius-xl mt-n4 position-absolute">
                                <i class="material-icons opacity-10">chat</i>
                            </div>
                            <div class="text-end pt-1">
                                <p class="text-sm mb-0 text-capitalize">Mes commentaires</p>
                                <h4 class="mb-0">{{ nb_comment }}</h4>
                            </div>
                        </div>
                        <hr class="dark horizontal my-0">

                    </div>
                </div>
                <div class="col-xl-3 col-sm-6">
                    <div class="card">
                        <div class="card-header p-3 pt-2">
                            <div
                                class="icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 position-absolute">
                                <i class="material-icons opacity-10">deskphone</i>
                            </div>
                            <div class="text-end pt-1">
                                <p class="text-sm mb-0 text-capitalize">Mes Proprieter contacter </p>
                                <h4 class="mb-0">{{ nb_contact }}</h4>
                            </div>
                        </div>
                        <hr class="dark horizontal my-0">

                    </div>
                </div>
            </div>


            <div class="col-12 mb-md-0 mb-4">
                <div class="card">
                    <div class="card-header pb-0">
                        <div class="row">
                            <div class="col-lg-6 col-7">
                                <h6>Demandes</h6>
                                <p class="text-sm mb-0">
                                    <i class="fa fa-check text-info" aria-hidden="true"></i>
                                    <span v-if="demandes" class="font-weight-bold ms-1">{{ demandes.length }}</span>
                                </p>
                            </div>
                            <div class="col-lg-6 col-5 my-auto text-end">
                                <div class="dropdown float-lg-end pe-4">
                                    <a class="cursor-pointer" id="dropdownTable" data-bs-toggle="dropdown"
                                        aria-expanded="false">
                                        <i class="fa fa-ellipsis-v text-secondary"></i>
                                    </a>
                                    <ul class="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5" aria-labelledby="dropdownTable">
                                        <li><a class="dropdown-item border-radius-md" href="javascript:;">Action</a>
                                        </li>
                                        <li><a class="dropdown-item border-radius-md" href="javascript:;">Another
                                                action</a></li>
                                        <li><a class="dropdown-item border-radius-md" href="javascript:;">Something
                                                else here</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body px-0 pb-2">
                        <div class="table-responsive">
                            <table class="table align-items-center justify-content-center mb-0">
                                <thead>
                                    <tr>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                            Utilisateur</th>
                                        <th
                                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                            Categorie</th>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                            Pays</th>

                                        <th
                                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                            Ville</th>
                                        <th
                                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                            Budget</th>

                                        <th
                                            class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2">
                                            Actions</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr v-for="demande in demandes" :key="demande.id">
                                        <td>
                                            <div class="d-flex px-2">
                                                <div>
                                                    <img :src=" demande.user.photo"
                                                        class="avatar avatar-sm rounded-circle me-2" alt="xd">
                                                </div>
                                                <div class="my-auto">
                                                    <h6 class="mb-0 text-sm">{{ demande.user.first_name }} {{
                                    demande.user.last_name }}</h6>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <span class="text-xs font-weight-bold">{{ demande.categorie.nom
                                                }}</span>
                                        </td>
                                        <td>
                                            <span class="text-xs font-weight-bold">{{ demande.pays.name }}</span>
                                        </td>
                                        <td>
                                            <span class="text-xs font-weight-bold">{{ demande.ville }}</span>
                                        </td>
                                        <td>
                                            <p class="text-sm font-weight-bold mb-0">{{ demande.budget }}{{demande.pays.currency_code }}</p>
                                        </td>
                                        <td class="align-middle text-center">
                                            <div class="social-profile">

                                                <a target="_blank" :href="'mailto:' + demande.user.email"
                                                    @click="upcontact_us(demande.user.id)"><i
                                                        class="fa fa-envelope"></i></a>
                                                <a target="_blank" :href="'https://wa.me/' + demande.user.whatsapp"
                                                    @click="upcontact_us(demande.user.id)"><i
                                                        class="fab fa-whatsapp"></i></a>
                                                <button @click="supdemande(demande.id)"
                                                    v-if="user == demande.user.id || role == 'admin'"
                                                    class="btn btn-danger"><i class="material-icons">delete</i></button>
                                            </div>

                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>










            <FootComponent />

        </main>
    </body>
</template>