<script>
import AsideComponent from "@/components/Asideadmin"
import HeadComponent from "@/components/Headadmin"
import FootComponent from "@/components/Footadmin"
import store from '@/store';
import $ from "jquery";
import 'select2';
import 'select2/dist/css/select2.min.css';
import Swal from 'sweetalert2';

export default {

    components: {
        HeadComponent,
        AsideComponent,
        FootComponent
    },
    data() {

        return {
            pays: 'BJ',
            country: null,
            caterid: null,
            categories: null,
            description: null,
            type: null,
            addresse: null,
            nbr_piece: null,
            nbr_parcelle: null,
            superficie: null,
            nbr_hectare: null,
            v_superficie: null,
            v_piece: null,
            v_parcelle: null,
            v_hectare: null,
            images: null,
            prix: '',
            periode:null,
            meuble: null,
            loading:false,
            errors: null
        }
    },
    name: 'AddComponent',
    mounted() {
        const $select = $(this.$refs.mySelect);
        document.title =  'Ajouter une proprieter'
        $select.select2();

        $select.on('change', (event) => {
            // Gérez ici les changements de valeur de Select2
            this.pays = event.target.value;
        });

        // Écoutez l'événement de changement de Select2 et mettez à jour le modèle Vue
        this.getcategorie()
        store.dispatch('categorie/getcountry')
            .then(response => {
                this.country = response

            })
            .catch(error => {
                console.log(error)
            })


    }, computed: {
  
    formattedMaxPrix: {
      get() {
        return this.formatMoney(this.prix);
      },
      set(value) {
        // Remove non-digit characters and parse as integer
        const newValue = parseInt(value.replace(/\D/g, ''), 10);
        if (!isNaN(newValue)) {
          this.prix = newValue;
        }
      }
    }
  },
  methods: {
    formatMoney(value) {
      // Convert value to string and add commas for thousands separator
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    },
    formatInput(event) {
      // Get current input value
      let value = event.target.value;

      // Remove non-digit characters
      value = value.replace(/\D/g, '');

      // Format value
      value = this.formatMoney(value);

      // Update input value
      event.target.value = value;
    }
  ,
        autre_champs() {
            this.v_piece = false
            this.v_parcelle = false
            this.v_hectare = false
            this.v_superficie = false
            this.categories.forEach(cat => {

                if (cat.id == this.caterid) {
                    if (cat.nbr_piece) {
                        this.v_piece = true
                    }

                    if (cat.nbr_hectare) {
                        this.v_hectare = true
                    }

                    if (cat.nbr_parcelle) {
                        this.v_parcelle = true
                    }
                    if (cat.superficie) {
                        this.v_superficie = true
                    }

                }

            });


        },
        handleFileChange(event) {
            // Le fichier sélectionné est accessible via event.target.files
            const selectedFile = event.target.files;

            // Mettez à jour le modèle avec les informations sur le fichier
            this.images = selectedFile;

            // Vous pouvez effectuer d'autres actions avec le fichier ici
        },
        getcategorie() {
            store.dispatch('categorie/getcategorie')
                .then(response => {
                    console.log(response)
                    this.categories = response
                })
                .catch(error => {
                    console.log(error)
                })
        },
        save() {

            this.loading=true
            const formData = new FormData()
            formData.append('meuble', this.meuble)
            formData.append('categorie', this.caterid)
            formData.append('description', this.description)
            formData.append('addresse', this.addresse)
            formData.append('prix', this.prix)

formData.append('periode', this.periode)
        
            formData.append('pays', this.pays)
            formData.append('type', this.type)
            for (var i = 0; i < this.images.length; i++) {

                formData.append('images', this.images[i])

            }



            if (this.nbr_parcelle != null) {

formData.append('nbr_parcelle', this.nbr_parcelle)


}
            if (this.nbr_hectare != null) {

                formData.append('nbr_hectare', this.nbr_hectare)

            }
            if (this.superficie != null) {

                formData.append('superficie', this.superficie)

            }

            if (this.nbr_piece != null) {

                formData.append('nbr_piece', this.nbr_piece)

            }
            store.dispatch('proprieter/addproprieter', formData).then(response => {
                
            this.loading=false
                Swal.fire({
                    title: "Succès",
                    text: response.message,
                    icon: "success",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonColor: "#34c38f",
                    confirmButtonText: "OK",
                }).then(() => {
                    window.location.replace('/proprieter')
                })

            }).catch(error => {
                this.loading=false
                if (error.response) {
                    // La requête a été effectuée, mais le serveur a répondu avec un statut autre que 2xx
                    console.error('Réponse du serveur avec erreur :', error.response.data)

                    this.errors = error.response.data.errors
                } else if (error.request) {
                    // La requête a été effectuée, mais aucune réponse n'a été reçue
                    console.error('Pas de réponse du serveur :', error.request)
                    this.errors = 'Pas de réponse du serveur.'
                } else {
                    // Une erreur s'est produite lors de la configuration de la requête
                    console.error('Erreur lors de la configuration de la requête :', error.message)
                    this.errors = 'Erreur lors de la configuration de la requête.'
                }
            })

        }
    }
}

</script>
<template>

    <body class="g-sidenav-show  bg-gray-200" id="body">
        <AsideComponent />

        <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
            <!-- Navbar -->
            <HeadComponent />
            <!-- End Navbar -->
            <div class="container-fluid ">
                <section>
                    <div class="page-header">
                        <div class="card container">
                            <div class="row">

                                <div class="card card-plain">
                                    <div class="card-header">
                                        <h4 class="font-weight-bolder">Enregistrer un bien</h4>
                                        <div v-if="errors" class="alert alert-danger alert-dismissible fade show"
                                            role="alert">
                                            <div class="alert-heading">
                                                <h4 class="alert-title">ERREUR</h4>
                                            </div>
                                            <p>{{ errors }}</p>
                                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <form @submit.prevent="save">
                                            <div class="row">
                                                <div class="input-group input-group-outline mb-3 col-md-6  ">
                                                    <label class="form-label">Ville,quartier</label>
                                                    <input type="text" v-model="addresse" class="form-control">
                                                </div>
                                                <div class="input-group input-group-outline mb-3 col-md-6">
                                                    <label class="form-label">Description</label>
                                                    <input type="text" v-model="description" class="form-control">
                                                </div>
                                                <div class="input-group input-group-outline mb-3 col-md-6">
                                                    <label  class="form-label" :class="{ 'active': caterid }">Categorie</label>
                                                    <select @change="autre_champs()" class="form-control"
                                                        v-model="caterid" aria-label="Default select example">
                                                        <option v-for="cat in categories" :key="cat.id" :value="cat.id">
                                                            {{
                                            cat.nom }}</option>
                                                    </select>
                                                </div>

                                                <div class=" input-group input-group-outline mb-3 col-md-6">
                                                    <label  class="form-label" :class="{ 'active': pays }">Pays</label>
                                                    <select required ref="mySelect" v-model="pays" class="form-control">
                                                        <option v-for="countrie in country" :key="countrie.iso"
                                                            :value="countrie.iso">{{ countrie.name }}</option>
                                                    </select>
                                                </div>


                                                <div class="input-group input-group-outline mb-3 col-md-6">
                                                    <label  class="form-label" :class="{ 'active': meuble!==null}">Meublé</label>
                                                    <select required v-model="meuble" class="form-control">
                                                        
                                                        <option :value="false"> Meublé: Non </option>
                                                        <option :value="true"> Meublé: Oui </option>
                                                    </select>
                                                </div>
                                                <div class="input-group input-group-outline mb-3 col-md-6">
                                                    <label class="form-label">Prix </label>
                                                    <input type="text" v-model="formattedMaxPrix" @input="formatInput"  class="form-control" required>
                                                </div>
                                                <div class="input-group input-group-outline mb-3 col-md-6">
                                                    <label  class="form-label" :class="{ 'active': periode!==null}">Periode</label>
                                                    <select required v-model="periode" class="form-control">
                                                        <option :value="'payement unique'">payement unique</option>
                                                        <option :value="'Jour'"> Jour</option>
                                                        <option :value="'Mois'"> Mois </option>
                                                    </select>
                                                </div>
                                                <div class="input-group input-group-outline mb-3 col-md-6">

                                                    <input type="file" @change="handleFileChange" style="height: 45px;"
                                                        multiple class="form-control">
                                                </div>
                                                <div class="input-group input-group-outline mb-3 col-md-6">
                                                    <label class="form-label" :class="{ 'active': type }">Type de bien</label>
                                                    <select required v-model="type" class="form-control">
                                                        <option value="a louer"> A louer </option>
                                                        <option value="a vendre"> A vendre</option>
                                                    </select>
                                                </div>

                                                <div v-if="v_piece" class="input-group input-group-outline mb-3 col-md-4">
                                                    <label class="form-label" :class="{ 'active': nbr_piece }">Nombre de piece</label>
                                                    <input type="number" v-model="nbr_piece" class="form-control" >
                                                </div>

                                                <div v-if="v_parcelle"
                                                    class="input-group input-group-outline mb-3 col-md-4">
                                                    <label class="form-label" :class="{ 'active': nbr_parcelle }">Nombre de parcelle</label>
                                                    <input type="number" v-model="nbr_parcelle" class="form-control">
                                                </div>



                                                <div v-if="v_hectare"
                                                    class="input-group input-group-outline mb-3 col-md-4">
                                                    <label class="form-label" :class="{ 'active': nbr_hectare }">Nombre d'hectare</label>
                                                    <input type="number" v-model="nbr_hectare" class="form-control">
                                                </div>
                                                <div v-if="v_superficie"
                                                    class="input-group input-group-outline mb-3 col-md-4">
                                                    <label class="form-label" :class="{ 'active': superficie }">Superficie en m²</label>
                                                    <input type="number" v-model="superficie" class="form-control">
                                                </div>




                                                <div class="text-center">
                                                    <div v-if='loading' class="d-flex justify-content-center align-items-center" style="height: 100vh;">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Chargement...</span>
    </div>
  </div>
                                                    <button v-else type="submit"
                                                        class="btn btn-lg bg-gradient-primary btn-lg w-100 mt-4 mb-0">
                                                        Enregistrer
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <FootComponent />
            </div>
        </main>
    </body>
</template>
<style>


.form-label.active {
    visibility: hidden;
}

</style>