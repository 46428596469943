<script>
import AsideComponent from "@/components/Asideadmin"
import HeadComponent from "@/components/Headadmin"
import FootComponent from "@/components/Footadmin"

import store from "@/store"

import Swal from 'sweetalert2'
export default {

    components: {
        HeadComponent,
        AsideComponent,
        FootComponent
    },
    data() {

        return {
            mod: null,
            categories: null,
            user: null,
            demandes: null,
            nb_piece:false,
            nb_parcelle:false,
            nb_hectare:false,
            superficie:false,
            role: null,
            
            nom: null
        }
    },
    name: 'CategorieComponent',
    mounted() {
        this.getcategorie()
        document.title =  'categorie'
        store.dispatch('auth/_currentUser')
            .then(() => {
                var currentuser = store.getters['auth/getCurrentUser'];
                this.user = currentuser.id
                this.role = currentuser.role
            })
            .catch(error => {
                console.log(error)
            })

    },
    methods: {
        getcategorie() {
            store.dispatch('categorie/getcategorie')
                .then(response => {
                    console.log(response)
                    this.categories = response
                    console.log(response)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        modif(id, nom,piece,parcelle,hectare,superficie) {
            this.mod = id
            this.nom = nom
            this.nb_piece=piece
            this.nb_parcelle=parcelle
            this.nb_hectare=hectare
            this.superficie=superficie

        },

        modCategorie() {
            store.dispatch('auth/_currentUser')
                .then(() => {
                    store.dispatch('categorie/modCategorie', { 'id': this.mod, 'nom': this.nom,'nbr_piece':this.nb_piece,'nbr_parcelle':this.nb_parcelle,'nbr_hectare':this.nb_hectare,'superficie':this.superficie })
                        .then(response => {
                            Swal.fire({
                                title: "Succès",
                                text: response.message,
                                icon: "success",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            }).then(() => {
                                window.location.reload()
                            })

                        })
                        .catch(error => {
                            Swal.fire({
                                title: "Erreur",
                                text: error.data.errors,
                                icon: "error",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            })
                        })
                })
                .catch(error => {
                    console.error("Erreur lors de la récupération de l'utilisateur", error);
                }
                );
        },
        supCategorie(id) {

            Swal.fire({
                title: "Question",
                text: "Voulez vous supprimer cette categorie ?",
                icon: "question",
                showCancelButton: true,
                cancelButtonColor: "#f46a6a",
                confirmButtonColor: "#34c38f",
                confirmButtonText: "Oui",
                cancelButtonText: 'Non',
            }).then(async result => {
                if (result.value) {

                    store.dispatch('auth/_currentUser')
                        .then(() => {
                            store.dispatch('categorie/supCategorie', { 'id': id })
                                .then(response => {
                                    Swal.fire({
                                        title: "Succès",
                                        text: response.message,
                                        icon: "success",
                                        cancelButtonColor: "#f46a6a",
                                        confirmButtonColor: "#34c38f",
                                        confirmButtonText: "OK",
                                    }).then(() => {
                                        window.location.reload()
                                    })

                                })
                                .catch(error => {
                                    console.log(error)
                                })
                        })
                        .catch(error => {
                            Swal.fire({
                                title: "Erreur",
                                text: error.data.errors,
                                icon: "error",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            })
                        })
                }
            })
        },
        addCategorie() {
            store.dispatch('auth/_currentUser')
                .then(() => {
                    store.dispatch('categorie/addCategorie', { 'nom': this.nom,'nbr_piece':this.nb_piece,'nbr_parcelle':this.nb_parcelle,'nbr_hectare':this.nb_hectare,'superficie':this.superficie })
                        .then(response => {
                            Swal.fire({
                                title: "Succès",
                                text: response.message,
                                icon: "success",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            }).then(() => {
                                window.location.reload()
                            })
                        })
                        .catch(error => { Swal.fire({
                                title: "Erreur",
                                text: error,
                                icon: "error",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            })
                        })
                })
                .catch(error => {
                    console.error("Erreur lors de la récupération de l'utilisateur", error);
                }
                );
        }
    }
}

</script>

<template>
    <body class="g-sidenav-show  bg-gray-200" id="body">
        <AsideComponent />

        <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
            <!-- Navbar -->
            <HeadComponent />
            <!-- End Navbar -->
            <div class="container-fluid">
                <div class="page-header justify-center align-items-center">
                    <div class="container ">
                                <div class="card ">
                                    <div class="card-body">

                                        <form v-if="mod" role="form" class="text-start" @submit.prevent="modCategorie">
                                            <div class="row g-3 input-group input-group-outline">
                                                <div class="col-sm-7">
                                                    <input type="text" class="form-control" v-model="nom" name="nom"
                                                        placeholder="Nom de la catégorie">
                                                </div>
                                                <div class="form-check form-switch row ps-0">
                                                    <div class="col-4">
                                                    <input class="form-check-input ms-auto" v-model="nb_piece" type="checkbox"
                                                        id="flexSwitchCheckDefault">
                                                    <label class="form-check-label text-body ms-3 text-truncate w-80 mb-0"
                                                        for="flexSwitchCheckDefault">Nombre de piece</label>
                                                </div>
                                                <div class="col-4">
                                                    <input class="form-check-input ms-auto" v-model="nb_parcelle" type="checkbox"
                                                        id="flexSwitchCheckDefault">
                                                    <label class="form-check-label text-body ms-3 text-truncate w-80 mb-0"
                                                        for="flexSwitchCheckDefault">Nombre de parcelle</label>
                                                </div>
                                                <div class="col-4">
                                                    <input class="form-check-input ms-auto" v-model="nb_hectare" type="checkbox"
                                                        id="flexSwitchCheckDefault">
                                                    <label class="form-check-label text-body ms-3 text-truncate w-80 mb-0"
                                                        for="flexSwitchCheckDefault">Nombre d'hectare</label>
                                                </div>
                                                <div class="col-4">
                                                    <input class="form-check-input ms-auto" v-model="superficie" type="checkbox"
                                                        id="flexSwitchCheckDefault">
                                                    <label class="form-check-label text-body ms-3 text-truncate w-80 mb-0"
                                                        for="flexSwitchCheckDefault">Superficie</label>
                                                </div>
                                            </div>
                                                <div class="col-sm">
                                                    <button type="submit" class="btn bg-gradient-primary">Modifier</button>
                                                </div>
                                            </div>
                                        </form>
                                        <form v-else role="form" class="text-start" @submit.prevent="addCategorie">
                                            <div class="row g-3 input-group input-group-outline">
                                                <div class="col-sm-7">
                                                    <input type="text" class="form-control" v-model="nom" name="nom"
                                                        placeholder="Nom de la catégorie">
                                                </div>
                                                <div class="form-check form-switch row ps-0">
                                                    <div class="col-4">
                                                    <input class="form-check-input ms-auto" v-model="nb_piece" type="checkbox"
                                                        id="flexSwitchCheckDefault">
                                                    <label class="form-check-label text-body ms-3 text-truncate w-80 mb-0"
                                                        for="flexSwitchCheckDefault">Nombre de piece</label>
                                                </div>
                                                <div class="col-4">
                                                    <input class="form-check-input ms-auto" v-model="nb_parcelle" type="checkbox"
                                                        id="flexSwitchCheckDefault">
                                                    <label class="form-check-label text-body ms-3 text-truncate w-80 mb-0"
                                                        for="flexSwitchCheckDefault">Nombre de parcelle</label>
                                                </div>
                                                <div class="col-4">
                                                    <input class="form-check-input ms-auto" v-model="nb_hectare" type="checkbox"
                                                        id="flexSwitchCheckDefault">
                                                    <label class="form-check-label text-body ms-3 text-truncate w-80 mb-0"
                                                        for="flexSwitchCheckDefault">Nombre d'hectare</label>
                                                </div>
                                                <div class="col-4">
                                                    <input class="form-check-input ms-auto" v-model="superficie" type="checkbox"
                                                        id="flexSwitchCheckDefault">
                                                    <label class="form-check-label text-body ms-3 text-truncate w-80 mb-0"
                                                        for="flexSwitchCheckDefault">Superficie en m²</label>
                                                </div>
                                            </div>
                                                <div class="col-sm">
                                                    <button type="submit"
                                                        class="btn bg-gradient-primary">Enregistrer</button>
                                                </div>
                                            </div>
                                        </form>
                                        <center>
                                            <table class="table table-responsive align-items-center justify-content-center">
                                                <thead>
                                                    <tr>
                                                        <th>Nom de la catégorie</th>
                                                        <th>caractéristique associé</th>
                                                        <th>Nombre d'abonner</th>
                                                        <th>Nombre de bien</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="cat in categories" :key="cat.id">
                                                        <td>{{ cat.nom }}</td>
                                                        <td>  <span v-if="cat.nbr_piece"
                                                        class="badge badge-sm bg-gradient-info">Nombre de piece</span>
                                                        <span v-if="cat.nbr_parcelle"
                                                        class="badge badge-sm bg-gradient-info">Nombre de parcelle</span>
                                                        <span v-if="cat.nbr_hectare"
                                                        class="badge badge-sm bg-gradient-info">Nombre d'hectare</span>
                                                        <span v-if="cat.superficie"
                                                        class="badge badge-sm bg-gradient-info">Superficie</span>
                                                  
                                                    </td>
                                                        <td>{{ cat.nb_abonner }}</td>
                                                        
                                                        <td>{{ cat.nb_proprieter }}</td>
                                                        <td><button @click="modif(cat.id, cat.nom,cat.nbr_piece,cat.nbr_parcelle,cat.nbr_hectare,cat.superficie)"
                                                                class="btn bg-gradient-primary">Modifier</button>

                                                            <button @click="supCategorie(cat.id)" class="btn btn-danger"><i
                                                                    class="material-icons">delete</i></button>

                                                        </td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </center>
                                    </div>
                                </div>
                            
                    </div>
                </div>
            </div>

            <FootComponent />

        </main>
    </body>
</template>