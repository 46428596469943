<script>
import HeadComponent from "@/components/Head"
import FootComponent from '@/components/Foot'
import store from '@/store'

export default {
    data() {
        return {
            email: null,
            code: null,
            errors: null,

            pin1:null,
            pin2:null,
            pin3:null,
            pin4:null,
            pin5:null,
            pin6:null,
        }
    },
    components: {
        HeadComponent,
        FootComponent
    },
    name: 'PinComponent',
    mounted() {

        this.email=localStorage.getItem('email')
        this.pininput()
        
    },
    methods: {
        
        verifycode(){
            this.code=''+this.pin1+this.pin2+this.pin3+this.pin4+this.pin5+this.pin6
            this.errors = null
            store.dispatch('auth/verifycode', { 'code': this.code, 'email': this.email })
                .then(response => {
                    window.location.replace('/')
                    console.log(response)
                })
                .catch(error => {
                    if (error.response) {
                        // La requête a été effectuée, mais le serveur a répondu avec un statut autre que 2xx
                        console.error('Réponse du serveur avec erreur :', error.response.data)

                        this.errors = error.response.data.errors
                    } else if (error.request) {
                        // La requête a été effectuée, mais aucune réponse n'a été reçue
                        console.error('Pas de réponse du serveur :', error.request)
                        this.errors = 'Pas de réponse du serveur.'
                    } else {
                        // Une erreur s'est produite lors de la configuration de la requête
                        console.error('Erreur lors de la configuration de la requête :', error.message)
                        this.errors = 'Erreur lors de la configuration de la requête.'
                    }
                })

        },
        pininput(){
            var pinContainer = document.querySelector(".pin-code");
                    pinContainer.addEventListener('keyup', function (event) {
                        var target = event.srcElement;

                        var maxLength = parseInt(target.attributes["maxlength"].value, 10);
                        var myLength = target.value.length;
                        var next = null
                        if (myLength >= maxLength) {
                            next = target.nextElementSibling;

                            if (next !== null && next.tagName.toLowerCase() == "input") {
                                next.focus();
                            }
                        }

                        if (myLength === 0) {
                            next = target.previousElementSibling;

                            if (next !== null && next.tagName.toLowerCase() == "input") {
                                next.focus();
                            }
                        }
                    }, false);

                    pinContainer.addEventListener('keydown', function (event) {
                        var target = event.srcElement;
                        target.value = "";
                    }, false);
        },
        
    }

}
</script>
<template>
    <HeadComponent />
    <main>
        <!-- login Area Strat-->
        <section class="login-area pt-10 pb-10">
            <div class="container">

                <div class="row">
                    <div class="col-lg-9 offset-lg-2">
                        <div v-if="errors" class="alert alert-danger alert-dismissible fade show" role="alert">
                            <div class="alert-heading">
                                <h4 class="alert-title">ERREUR</h4>
                            </div>
                            <p>{{ errors }}</p>
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                      
                        <div  class="basic-login">
                            <h3 class="text-center mb-20"></h3>
                            <form @submit.prevent="verifycode">

                                <label for="name">Code de vérification <span>**</span></label>
                                <div class="pin-code">
                                    <input v-model="pin1" type="number" maxlength="1" autofocus>
                                    <input v-model="pin2" type="number" maxlength="1">
                                    <input v-model="pin3" type="number" maxlength="1">
                                    <input v-model="pin4" type="number" maxlength="1">
                                    <input v-model="pin5" type="number" maxlength="1">
                                    <input v-model="pin6" type="number" maxlength="1">
                                </div>
                                <button type="submit" class="btn theme-btn-2 w-100 text-light">Send code</button>
                                <div class="or-divide"><span>or</span></div>
                                <a href="" class="btn theme-btn w-100 text-light">Retour</a>
                            </form>
                        </div>

                        
                    </div>
                </div>
            </div>
        </section>
        <!-- login Area End-->


    </main>


    <FootComponent />
</template>
<style>
.pin-code {
    padding: 0;
    margin: 0 auto;
    display: flex;
    justify-content: center;

}

.pin-code input {
    border: none;
    text-align: center;
    width: 60px;
    height: 60px;
    font-size: 25px;
    background-color: #F3F3F3;
    margin-right: 5px;
}



.pin-code input:focus {
    border: 1px solid #573D8B;
    outline: none;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;

}
</style>