<script>
import HeadComponent from "@/components/Head"
import FootComponent from '@/components/Foot'
import store from '@/store'
import Swal from 'sweetalert2';

export default {
    data() {
        return {
            email: null,
            loading:false,
            password: null,
            errors: null,
            showPassword: false
        }
    },
    mounted(){

        document.title =  'login'
    },
    components: {
        HeadComponent,
        FootComponent
    },
    name: 'LoginComponent',
    methods: {
        togglePasswordVisibility() {
            this.showPassword = !this.showPassword;
        },
        login() {
            this.errors = null
            this.loading=true
            store.dispatch('auth/login', { email: this.email, password: this.password })
                .then(response => {
                    this.loading=false
                    Swal.fire({
                        title: "Succès",
                        text: response.message,
                        icon: "success",
                        cancelButtonColor: "#f46a6a",
                        confirmButtonColor: "#34c38f",
                        confirmButtonText: "OK",
                    }).then(() => {
                        window.location.replace('/')
                    })
                })
                .catch(error => {
                    this.loading=false
                    if (error.response) {
                        // La requête a été effectuée, mais le serveur a répondu avec un statut autre que 2xx
                        console.error('Réponse du serveur avec erreur :', error.response.data)

                        this.errors = error.response.data.errors
                    } else if (error.request) {
                        // La requête a été effectuée, mais aucune réponse n'a été reçue
                        console.error('Pas de réponse du serveur :', error.request)
                        this.errors = 'Pas de réponse du serveur.'
                    } else {
                        // Une erreur s'est produite lors de la configuration de la requête
                        console.error('Erreur lors de la configuration de la requête :', error.message)
                        this.errors = 'Erreur lors de la configuration de la requête.'
                    }
                })

        }
    },


}
</script>
<template>
    <HeadComponent />
    <main>
        <!-- login Area Strat-->
        <section class="login-area pt-10 pb-10">
            <div class="container">

                <div class="row">
                    <div class="col-lg-9 offset-lg-2">
                        <div v-if="errors" class="alert alert-danger alert-dismissible fade show" role="alert">
                            <div class="alert-heading">
                                <h4 class="alert-title">ERREUR</h4>
                            </div>
                            <p>{{ errors }}</p>
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="basic-login">
                            <h3 class="text-center mb-20">Connecter-vous ici</h3>
                            <form @submit.prevent="login">

                                <label for="name">Email <span>**</span></label>
                                <input v-model="email" id="name" type="email" placeholder="Email address..." required />
                                <label for="pass">Mot de passe <span>**</span></label>

                                <div class="password-input-container">
                                    <input id="pass" v-model="password" :type="showPassword ? 'text' : 'password'"
                                        placeholder="Enter password..." required />
                                    <a class='button' @click="togglePasswordVisibility">
                                        <i class="far fa-eye"></i>
                                    </a>
                                </div>
                                <div class="login-action mb-20 fix">
                                    <span class="forgot-login f-right">
                                        <a href="/forget">Mot de passe oublié ?</a>
                                    </span>
                                </div>
                                <div v-if='loading' class="d-flex justify-content-center align-items-center" style="height: 100vh;">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Chargement...</span>
    </div>
  </div>
                                <button  v-else type="submit" class="btn theme-btn-2 w-100 text-light">Se connecter</button>
                                <div class="or-divide"><span>or</span></div>
                                <a href="/register" class="btn theme-btn w-100 text-light">Inscription</a>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- login Area End-->


    </main>


    <FootComponent />
</template>
<style scoped>
.password-input-container {
  display: flex;
  align-items: center;
}

/* Styles spécifiques à votre icône ou composant SVG */
.button {
  background-color: transparent;
  border: none;
  font-size:medium;
  cursor: pointer;
  margin-left: 5px; /* Espacement entre le champ de saisie et le bouton */
}
</style>