
<script>
import HeadComponent from "@/components/Headadmin"
import AsideComponent from "@/components/Asideadmin"
import FootComponent from "@/components/Footadmin"
import store from "@/store"

import Swal from 'sweetalert2';
import $ from "jquery";
import 'select2';
import 'select2/dist/css/select2.min.css';
export default {

    components: {
        HeadComponent,
        AsideComponent,
        FootComponent
    },
    data() {

        return {
            categories: null,
            abonnement: [],
            currentUse: null,
            
            photo: null,
            nom: null,
            url: null,
            prenom: null,
            email: null,
            telephone: null,
            entreprise: null,
            whatsapp: null,
            pays: null,
            addresse: null,
            country: null,
            role: null,
            f_nom: null,
            f_url: null,
            f_prenom: null,
            f_email: null,
            f_telephone: null,
            f_entreprise: null,
            f_whatsapp: null,
            f_pays: null,
            f_addresse: null,
            f_photo: null,
            errors: null

        }
    },
    name: 'ProfilComponent',
    mounted() {
        const $select = $(this.$refs.mySelect);

        $select.select2();
        document.title =  'profile'

        $select.on('change', (event) => {
            // Gérez ici les changements de valeur de Select2
            this.pays = event.target.value;
        });

        // Écoutez l'événement de changement de Select2 et mettez à jour le modèle Vue

        store.dispatch('categorie/getcountry')
            .then(response => {
                this.country = response

            })
            .catch(error => {
                console.log(error)
            })
        this.get_user()
        this.getcategorie()

        store.dispatch('categorie/getabonnement')
            .then(response => {
                console.log(response)
                response.forEach(element => {
                    this.abonnement.push(element.categorie.id)

                })

                console.log(this.abonnement)

            })
            .catch(error => {
                console.log(error)

            })

    },
    methods: {
        abonner(id) {
            store.dispatch('categorie/abonner', { 'id': id })
                .then(response => {
                    Swal.fire({
                        title: "Succès",
                        text: response.message,
                        icon: "success",
                        cancelButtonColor: "#f46a6a",
                        confirmButtonColor: "#34c38f",
                        confirmButtonText: "OK",
                    })
                })
                .catch(error => {
                    Swal.fire({
                        title: "Erreur",
                        text: error.data.errors,
                        icon: "error",
                        cancelButtonColor: "#f46a6a",
                        confirmButtonColor: "#34c38f",
                        confirmButtonText: "OK",
                    })
                })
        },
        getcategorie() {
            store.dispatch('categorie/getcategorie')
                .then(response => {
                    console.log(response)
                    this.categories = response
                })
                .catch(error => {
                    console.log(error)
                })
        },
        get_user() {

            store.dispatch('auth/_currentUser')
                .then(() => {
                    
                    this.currentUse = store.getters['auth/getCurrentUser'];

                    this.photo = this. this.currentUse.photo
                    this.nom = this.currentUse.first_name
                    this.prenom = this.currentUse.last_name
                    this.entreprise = this.currentUse.entreprise
                    this.email = this.currentUse.email
                    this.whatsapp = this.currentUse.whatsapp
                    this.addresse = this.currentUse.addresse
                    this.url = this.currentUse.url
                    this.pays = this.currentUse.pays.name
                    this.role = this.currentUse.role

                    this.f_nom = this.nom
                    this.f_url = this.url
                    this.f_prenom = this.prenom
                    this.f_email = this.email
                    this.f_entreprise = this.entreprise
                    this.f_whatsapp = this.whatsapp
                    this.f_pays = this.currentUse.pays.iso
                    this.f_addresse = this.addresse

                })
                .catch(error => {
                    console.error("Erreur lors de la récupération de l'utilisateur", error);

                });
        },

        handleFileChange(event) {
            // Le fichier sélectionné est accessible via event.target.files
            const selectedFile = event.target.files[0];

            // Mettez à jour le modèle avec les informations sur le fichier
            this.f_photo = selectedFile;

            // Vous pouvez effectuer d'autres actions avec le fichier ici
        },
     
        changewhatsapp(newvalue) {
            console.log(newvalue)
            this.f_whatsapp = newvalue
        },

        register() {
            this.errors = null

            const formData = new FormData()

            formData.append('first_name', this.f_nom)
            formData.append('last_name', this.f_prenom)
            formData.append('email', this.email)
            formData.append('username', this.f_email)
            formData.append('pays', this.f_pays)
            formData.append('addresse', this.f_addresse)
            formData.append('whatsapp', this.f_whatsapp)

            if (this.f_url != null) {

                formData.append('url', this.f_url)


            }
            if (this.f_photo != null) {

                formData.append('photo', this.f_photo)
            }
            if (this.f_entreprise != null) {

                formData.append('entreprise', this.f_entreprise)
                if (this.role != 'admin') {

                    formData.append('role', "agence")
                }

            }
            else {

                formData.append('role', "simple")

            }
            store.dispatch('auth/update_user', formData).then(response => {
                Swal.fire({
                    title: "Succès",
                    text: response.message,
                    icon: "success",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonColor: "#34c38f",
                    confirmButtonText: "OK",
                }).then(() => {
                    window.location.reload()
                })
            }).catch(error => {
                if (error.response) {
                    // La requête a été effectuée, mais le serveur a répondu avec un statut autre que 2xx
                    console.error('Réponse du serveur avec erreur :', error.response.data)

                    this.errors = error.response.data.errors
                } else if (error.request) {
                    // La requête a été effectuée, mais aucune réponse n'a été reçue
                    console.error('Pas de réponse du serveur :', error.request)
                    this.errors = 'Pas de réponse du serveur.'
                } else {
                    // Une erreur s'est produite lors de la configuration de la requête
                    console.error('Erreur lors de la configuration de la requête :', error.message)
                    this.errors = 'Erreur lors de la configuration de la requête.'
                }
            })



        }


    }

}

</script>






<template>
    <body class="g-sidenav-show  bg-gray-200" id="body">
        <AsideComponent />

        <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
            <!-- Navbar -->
            <HeadComponent />

            <div class="container-fluid px-2 px-md-4">
                <div class="page-header min-height-300 border-radius-xl mt-4"
                    style="background-image: url('img/logo/logo2.jpg');">
                    <span class="mask  bg-gradient-primary  opacity-6"></span>
                </div>
                <div class="card card-body mx-3 mx-md-4 mt-n6">
                    <div class="row gx-4 mb-2">
                        <div class="col-auto">
                            <div class="avatar avatar-xl position-relative">
                                <img :src="photo" alt="profile_image" class="w-100 border-radius-lg shadow-sm">
                            </div>
                        </div>
                        <div class="col-auto my-auto">
                            <div class="h-100">
                                <h5 class="mb-1">
                                    {{ nom }} {{ prenom }}
                                </h5>
                                <p class="mb-0 font-weight-normal text-sm">
                                    {{ addresse }}
                                </p>
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <div class="card card-plain h-100">
                                    <div class="card-header pb-0 p-3">
                                        <h6 class="mb-0">Abonnement</h6>
                                    </div>
                                    <div class="card-body p-3">
                                        <h6 class="text-uppercase text-body text-xs font-weight-bolder">Catégorie</h6>
                                        <ul class="list-group">
                                            <li v-for="cat in categories" :key="cat.id"
                                                class="list-group-item border-0 px-0">
                                                <div class="form-check form-switch ps-0">
                                                    <input class="form-check-input ms-auto" type="checkbox"
                                                        @change="abonner(cat.id)" id="flexSwitchCheckDefault"
                                                        :checked="abonnement.includes(cat.id)">
                                                    <label class="form-check-label text-body ms-3 text-truncate w-80 mb-0"
                                                        for="flexSwitchCheckDefault"> {{ cat.nom }}</label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="card card-plain h-100">
                                    <div class="card-header pb-0 p-3">
                                        <div class="row">
                                            <div class="col-md-8 d-flex align-items-center">
                                                <h6 class="mb-0">Information de profil</h6>
                                            </div>
                                            <div class="col-md-4 text-end">
                                                <a href="javascript:;">
                                                    <i class="fas fa-user-edit text-secondary text-sm"
                                                        data-bs-toggle="tooltip" data-bs-placement="top"
                                                        title="Edit Profile"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body p-3">
                                        <p hidden class="text-sm">
                                            Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two
                                            equally difficult paths, choose the one more painful in the short term (pain
                                            avoidance is creating an illusion of equality).
                                        </p>
                                        <hr class="horizontal gray-light my-4">
                                        <ul class="list-group">
                                            <li class="list-group-item border-0 ps-0 pt-0 text-sm"><strong v-if="entreprise"
                                                    class="text-dark">AGENCE:</strong> &nbsp; {{ entreprise }}</li>
                                            <li class="list-group-item border-0 ps-0 text-sm"><strong
                                                    class="text-dark">Email:</strong> &nbsp; {{ email }}</li>
                                            <li class="list-group-item border-0 ps-0 text-sm"><strong
                                                    class="text-dark">Whatsapp:</strong> &nbsp; {{ whatsapp }}</li>
                                            <li class="list-group-item border-0 ps-0 text-sm"><strong
                                                    class="text-dark">Pays:</strong> &nbsp; {{ pays }}</li>
                                            <li class="list-group-item border-0 ps-0 text-sm"><strong class="text-dark">Site
                                                    web :</strong> &nbsp;<a target="_blank" :href="url"> {{ url }} </a></li>
                                            <li hidden class="list-group-item border-0 ps-0 pb-0">
                                                <strong class="text-dark text-sm">Social:</strong> &nbsp;
                                                <a class="btn btn-facebook btn-simple mb-0 ps-1 pe-2 py-0"
                                                    href="javascript:;">
                                                    <i class="fab fa-facebook fa-lg"></i>
                                                </a>
                                                <a class="btn btn-twitter btn-simple mb-0 ps-1 pe-2 py-0"
                                                    href="javascript:;">
                                                    <i class="fab fa-twitter fa-lg"></i>
                                                </a>
                                                <a class="btn btn-instagram btn-simple mb-0 ps-1 pe-2 py-0"
                                                    href="javascript:;">
                                                    <i class="fab fa-instagram fa-lg"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 ">
                                <div class="card card-plain h-100">
                                    <div class="card-header pb-0 p-3">
                                        <h6 class="mb-0">Mise a jour </h6>
                                    </div>
                                    <div class="card-body p-3">
                                        <ul class="list-group">
                                            <div v-if="errors" class="alert alert-danger alert-dismissible fade show"
                                                role="alert">
                                                <div class="alert-heading">
                                                    <h4 class="alert-title">ERREUR</h4>
                                                </div>
                                                <p>{{ errors }}</p>
                                                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="basic-login">
                                                <form @submit.prevent="register">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div class="checkout-form-list">
                                                                <label>Nom <span class="required">*</span></label>
                                                                <input type="text" v-model="f_nom" placeholder="Votre Nom"
                                                                     />
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="checkout-form-list">
                                                                <label>Prénom(s) <span class="required">*</span></label>
                                                                <input type="text" v-model="f_prenom" placeholder="Prénoms"
                                                                     />
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="checkout-form-list">
                                                                <label>Nom de l'agence immobilière <span
                                                                        class="required">(facultatif)</span></label>
                                                                <input type="text" v-model="f_entreprise"
                                                                    placeholder="Nom de l'agence" />
                                                            </div>
                                                        </div>

                                                        <div class="col-md-6">
                                                            <div class="checkout-form-list">
                                                                <label>Site web de l'agence immobilière <span
                                                                        class="required">(facultatif)</span></label>
                                                                <input style="height: 45px;" type="url" v-model="f_url"
                                                                    placeholder="www.siteweb.com" />
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <div class="checkout-form-list">
                                                                <label>Photo de profil ou logo de l'agence
                                                                    <span>*</span></label>
                                                                <input @change="handleFileChange" style="height: 45px;"
                                                                    type="file" placeholder="Photo" />
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 ">
                                                            <div class="checkout-form-list ">
                                                                <label>Pays <span class="required">*</span></label>
                                                                <select required ref="mySelect" v-model="f_pays"
                                                                    style="width:300px">
                                                                    <option v-for="countrie in country" :key="countrie.iso"
                                                                        :value="countrie.iso">{{ countrie.name }}</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div class="col-md-6">
                                                            <div class="checkout-form-list">
                                                                <label>Addresse <span class="required">*</span></label>
                                                                <input type="text" v-model="f_addresse"
                                                                    placeholder="Cotonou, Akpakpa" required />
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="checkout-form-list">
                                                                <label>Email <span>*</span></label>
                                                                <input v-model="f_email" type="email" disable
                                                                    placeholder="exemple@gmail.com" required />
                                                            </div>
                                                        </div>
                                                        
                                                        <div class="col-md-6">
                                                            <div class="checkout-form-list">
                                                                <label>Numero de téléphone whatsapp <span>*</span></label>
                                                                <input type="tel" v-model="f_whatsapp"
                                                                    >
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="mt-2"></div>
                                                    <button class="btn btn-primary w-100 text-light">ENREGISTRER</button>
                                                    <div class="or-divide"><span>or</span></div>
                                                    <a href="/changepass"
                                                        class="btn btn-secondary  w-100 text-light">CHANGER LE MOT DE
                                                        PASSE</a>

                                                </form>
                                            </div>

                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>
                </div>
                <FootComponent />
            </div>

        </main>
    </body>
</template>