<script>
import AsideComponent from "@/components/Asideadmin"
import HeadComponent from "@/components/Headadmin"
import FootComponent from "@/components/Footadmin"

import store from "@/store"

export default {

    components: {
        HeadComponent,
        AsideComponent,
        FootComponent
    },
    data() {

        return {
            emails: null,
            user: null,
            role: null,
        }
    },
    name: 'CategorieComponent',
    mounted() {
        this. getnews()
        document.title =  'categorie'
        store.dispatch('auth/_currentUser')
            .then(() => {
                var currentuser = store.getters['auth/getCurrentUser'];
                this.user = currentuser.id
                this.role = currentuser.role
            })
            .catch(error => {
                console.log(error)
            })

    },
    methods: {
        getnews() {
            store.dispatch('categorie/get_news')
                .then(response => {
                    this.emails = response
                })
                .catch(error => {
                    console.log(error)
                })
        },
     

      
    }
}

</script>

<template>
    <body class="g-sidenav-show  bg-gray-200" id="body">
        <AsideComponent />

        <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
            <!-- Navbar -->
            <HeadComponent />
            <!-- End Navbar -->
            <div class="container-fluid">
                <div class="page-header justify-center align-items-center">
                    <div class="container ">
                                <div class="card ">
                                    <div class="card-body">

                                        <center>
                                            <table class="table table-responsive align-items-center justify-content-center">
                                                <thead>
                                                    <tr>
                                                        <th>email</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="cat in emails" :key="cat.id">
                                                        <td>{{ cat.email }}</td>
                                                     
                                                  
                                                    

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </center>
                                    </div>
                                </div>
                            
                    </div>
                </div>
            </div>

            <FootComponent />

        </main>
    </body>
</template>