
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import VueTelInput from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'

import { createMetaManager } from 'vue-meta';


const app = createApp(App);
// Utilisez le gestionnaire de métadonnées
const metaManager = createMetaManager();

app.use(metaManager);
app.use(router);
app.use(VueTelInput)
app.mount('#app');
